import React,{Fragment} from "react";
import {Box, Typography} from "@material-ui/core";

const Notice = props => {
  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"center"} style={{marginBottom:'30px'}}>
        <Box>
          <Typography variant={"body1"}>
            Assalamualaikum Warahmatullahi Wabarakatuh,
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} style={{marginBottom:'30px'}}>
        <Box>
          <Typography variant={"body1"} style={{textAlign:'justify'}}>
            Mengikuti kebijakan Pemerintah, Entitas Peradaban EOA Sedang Libur, Hari Raya Tahun Baru Imlek. Sistem Kembali Beroperasi Rabu, Tanggal 2 Februari 2022.
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Box>
          <Typography variant={"body1"}>
            Wassalamualaikum Warahmatullahi Wabarakatuh
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Notice;