import React,{Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, Typography, Paper} from "@material-ui/core";
import {Add} from "@material-ui/icons";
//compenent
import BeginningStockHistories from "./BeginningStockHistories";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  btnAdd: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    fontWeight: "bold",
    borderRadius: 60,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    }
  },
  paperPadding: {
    marginBottom: theme.spacing(2)
  }
}))

const BeginningStock = props => {
  const classes = useStyle();
  const {
    statusCreate
  } = props;

  return (
    <Fragment>
      <div className={classes.root}>
        <div>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h5"} className={classes.tagMenu}>
                Stok Awal
              </Typography>
            </Grid>
            <Grid item>
              {statusCreate ? (
                <Link to='/beginning-stock/create'>
                  <Button variant={"contained"} size={"small"} startIcon={React.cloneElement(<Add/>)} className={classes.btnAdd}>
                    Stock Awal
                  </Button>
                </Link>
              ): (
                <Button variant={"contained"} size={"small"} startIcon={React.cloneElement(<Add/>)} className={classes.btnAdd} disabled>
                  Stock Awal
                </Button>
              )}

            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Paper variant={"outlined"} className={classes.root}>
            <div className={classes.paperPadding}>
              <Typography className={classes.tagMenu} variant={"h5"}>History Stok Awal</Typography>
            </div>
            <div>
              <BeginningStockHistories/>
            </div>
          </Paper>
        </div>
      </div>

    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    statusCreate: state.beginningStock.statusCreate
  }
}

export default connect(mapStateToProps)(BeginningStock);