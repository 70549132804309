import React, {Fragment, useState} from "react";
import {Divider, Grid, IconButton, InputBase, Paper, Typography} from "@material-ui/core";
import CapitalizedText from "../../../../components/layout/CapitalizedText";
import NumberFormat from "react-number-format";
import Skeleton from "@material-ui/lab/Skeleton";
import {Delete} from "@material-ui/icons";

const CartItem = props => {
  const {item, classes, loadingAddCart, cart} = props;
  const [cartTextBox, setCartTextBox] = useState(item.qty);
  const onChange = (event) => {
    event.persist()
    setCartTextBox(event.target.value);
    let dataCount = event.target.value;

    setTimeout(() => {
      if (event.target.value === '' || event.target.value === '0'){
        setCartTextBox(1);
        dataCount = 1;
      }
      if (parseInt(event.target.value) >= parseInt(item.product.stock_on_hand)){
        setCartTextBox(item.product.stock_on_hand);
        dataCount = item.product.stock_on_hand;
      }

      if(dataCount !== ''){
        props.addCartValue(item,dataCount)
      }
      else{
        props.addCartValue(item,1)
      }
    }, 2000);
  }

  return (
    <Fragment>
      <Grid
        container
        // spacing={2}
        justify="space-between"
      >
        {!loadingAddCart || cart !== null ? (
          <>
            <Grid item>
              <Typography variant="h5">
                <CapitalizedText text={item.product.name} /> {item.product.weight} <CapitalizedText text={item.product.unit} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{fontWeight:"bold"}}>
                <NumberFormat value={item.total_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
              </Typography>
            </Grid>
          </>
        ):(
          <>
            <Skeleton variant="rect" height={50}></Skeleton>
            <Skeleton variant="rect" height={50}></Skeleton>
            <Skeleton variant="rect" height={50}></Skeleton>
          </>
        )}
      </Grid>
      <Grid container justify={"flex-end"}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Paper component="form" className={classes.searchRoot}>
            <IconButton aria-label="delete" onClick={() => props.deleted(item.id)}>
              <Delete fontSize="small" />
            </IconButton>
            {item.qty < 2 ? (
              <IconButton className={classes.iconButton} disabled>
                -
              </IconButton>
            ) : (
              <IconButton className={classes.iconButton} onClick={() => props.added(item,-1)}
              >
                -
              </IconButton>
            )}

            <Divider className={classes.divider} orientation="vertical" />
            <InputBase
              inputProps={{min: 0, style: { textAlign: 'center' }}} // the change is here
              InputProps={classes.inputText}
              className={classes.inputComponent}
              onChange={onChange}
              type="number"
              name="qty"
              // disabled
              value={cartTextBox}
            />
            <Divider className={classes.divider} orientation="vertical" />
            {item.qty >= item.product.stock_on_hand ? (
              <IconButton  className={classes.iconButton} disabled>
                +
              </IconButton>
            ):(
              <IconButton  className={classes.iconButton} onClick={() => props.added(item,1)}>
                +
              </IconButton>
            )}
          </Paper>
        </Grid>
      </Grid>
      <br/>
      <hr/>
      <br/>
    </Fragment>
  );
};

export default CartItem;