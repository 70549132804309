import React,{Fragment} from "react";
import {Grid} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CalendarToday} from "@material-ui/icons";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const FormFilter = props => {
  const {
    classes,
    isOpen,
    isOpenEnd,
    setIsOpen,
    setIsOpenEnd,
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,

  } = props;
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TextField
              onClick={() => setIsOpen(true)}
              value={moment(startDate.startDate).format('dddd DD MMMM YYYY')}
              fullWidth
              className={classes.margin}
              label="Tanggal Awal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disablePointerEvents>
                    <CalendarToday />
                  </InputAdornment>
                ),
              }}
            />
            <DatePicker
              fullWidth
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              disableFuture
              // minDate={minDate}
              variant="outlined"
              name="start_date"
              format="dd MMMM yyyy HH:mm"
              value={startDate.startDate}
              onChange={e => handleStartDate(e, 'endDate')}
              TextFieldComponent={() => null}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TextField
              onClick={() => setIsOpenEnd(true)}
              value={moment(endDate.endDate).format('dddd DD MMMM YYYY')}
              fullWidth
              className={classes.margin}
              label="Tanggal Akhir"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disablePointerEvents>
                    <CalendarToday />
                  </InputAdornment>
                ),
              }}
            />
            <DatePicker
              fullWidth
              open={isOpenEnd}
              onOpen={() => setIsOpenEnd(true)}
              onClose={() => setIsOpenEnd(false)}
              disableFuture
              // minDate={minDate}
              variant="outlined"
              name="end_date"
              format="dd MMMM yyyy HH:mm"
              value={endDate.endDate}
              onChange={handleEndDate}
              TextFieldComponent={() => null}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FormFilter;