
import axios from 'axios'
import { setAlert } from './alert'
import { 
    GET_STOCK_HISTORY, GET_STOCK_HISTORY_DETAIL
} from './types'
import * as actionTypes from "./types";
import axiosOrders from '../axios-orders';
import fileDownload from "js-file-download";

export const getStock = (page, startDate, endDate,type) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/stock_branch/filter_v3?page=${page}&start_date=${startDate}&end_date=${endDate}&type=${type?type:''}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_STOCK_HISTORY,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getStockDetail = (id, startDate, endDate) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/stock_branch/${id}/filter?start_date=${startDate}&end_date=${endDate}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_STOCK_HISTORY_DETAIL,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const fetchStockHistoriesStart = () => {
    return {
        type: actionTypes.FETCH_STOCK_HISTORIES_START
    }
}

export const fetchStockHistoriesSuccess = (stockHistories) => {
    return {
        type: actionTypes.FETCH_STOCK_HISTORIES_SUCCESS,
        stockHistories:stockHistories
    }
}

export const fetchStockHistoriesFail = (error) => {
    return {
        type: actionTypes.FETCH_STOCK_HISTORIES_FAIL,
        error: error
    }
}

export const fetchStockHistories = (page, formSearch, page_size) => {
    let param = `&start_date=${formSearch.startDate}&end_date=${formSearch.endDate}&type=${formSearch.type?formSearch.type:''}`;
    return dispatch => {
        dispatch(fetchStockHistoriesStart())
        axiosOrders.get(`user/stock_branch/filter_v3?page=${page}&page_size=${page_size}`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchStockHistoriesSuccess(res.data))
          })
          .catch(err => {
              dispatch(fetchStockHistoriesFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const downloadStockHistoriesStart = () => {
    return {
        type: actionTypes.DOWNLOAD_STOCK_HISTORIES_START
    }
}

export const downloadStockHistoriesSuccess = () => {
    return {
        type: actionTypes.DOWNLOAD_STOCK_HISTORIES_SUCCESS
    }
}

export const downloadStockHistoriesFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_STOCK_HISTORIES_FAIL,
        error: error
    }
}

export const downloadStockHistories = (formSearch) => {
    let param = `&start=${formSearch.startDate}&end=${formSearch.endDate}&product_type=${formSearch.type?formSearch.type:''}`;
    return dispatch => {
        dispatch(downloadStockHistoriesStart())
        axiosOrders.get(`user/stock_branch/export?`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            responseType: 'blob',
        })
          .then(res => {
              fileDownload(res.data, `download-stock-histories.xlsx`)
              dispatch(downloadStockHistoriesSuccess(res.data))
              dispatch(setAlert('Download Finished', "success"))
          })
          .catch(err => {
              dispatch(downloadStockHistoriesFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}