import React,{Fragment} from "react";
import {Paper,Grid,TextField,InputAdornment,FormControl,InputLabel,Select,InputBase,IconButton} from "@material-ui/core";
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import {CalendarToday, Search as SearchIcon} from "@material-ui/icons";
import 'date-fns'
import DateFnsUtils from "@date-io/date-fns";
import {isEmpty} from "../../shared/utility";
import moment from "moment";


const FormSearchPurchaseOrder = props => {
  const {
    classes,
    moment,
    isOpen,
    isOpenEnd,
    setIsOpen,
    setIsOpenEnd,
    startDate,
    endDate,
    handleStartDate,
    handleEndDate,
    handleSearch,
    formSearch,
    rounds
  } = props;

  let optionRounds = '';
  if(!isEmpty(rounds)){
    optionRounds = rounds.map((round) => (
      <option value={round.id}>{round.name}</option>
    ))
  }

  var currentDateString = moment().format("YYYY-MM-DD");

  const [currentDate, setCurrentDate] = React.useState(currentDateString);

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <Grid
          container
          spacing={2}
        >
          <Grid item lg={3} md={3} sm={12} xs={12}>
          <form className={classes.container} noValidate>
            <TextField
              id="dateStart"
              name="dateStart"
              label="Tanggal Awal"
              type="date"
              defaultValue={currentDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={props.handleStartDate}
            />
          </form>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
          <form className={classes.container} noValidate>
              <TextField
                id="dateEnd"
                name="dateEnd"
                label="Tanggal Akhir"
                type="date"
                defaultValue={currentDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={props.handleEndDate}
              />
            </form>
          </Grid>
          <Grid item xl={3} md={3} sm={12} xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink htmlFor="age-native-label-placeholder">Status</InputLabel>
              <Select
                fullWidth
                native
                defaultValue={formSearch.status}
                onChange={props.handleSearch}
                label="Status"
                inputProps={{
                  name: 'status',
                  id: 'age-native-label-placeholder'
                }}
                name="status"
                // onChange={handleChange}
              >
                {/*<option value={''}></option>*/}
                <option value={''}>Semua</option>
                <option value={0}>Pending</option>
                <option value={1}>Menunggu Konfirmasi</option>
                <option value={2}>Diproses</option>
                <option value={3}>Dikirim (Muncul Bukti kirim)</option>
                <option value={4}>Sampai</option>
                <option value={99}>Ditolak</option>
                <option value={98}>Expired</option>
              </Select>
            </FormControl>
          </Grid>

         

        </Grid>
      </Paper>
      <div className={classes.row}>
        <Grid container justify={"flex-end"}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Paper component="form" className={classes.searchRoot}>
              <InputBase
                className={classes.input}
                placeholder="Cari Kode Unik Invoice"
                inputProps={{ 'aria-label': 'Cari Kode Unik Invoice' }}
                defaultValue={formSearch.search}
                name={'search'}
                onChange={handleSearch}
              />
              <IconButton className={classes.iconButton} aria-label="search" disabled>
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default FormSearchPurchaseOrder;