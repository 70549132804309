import React, {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Loading} from '../../../components/UI';

import {
  Grid,
  Typography,
} from '@material-ui/core';
import {useParams} from 'react-router-dom';

import {connect} from "react-redux";
import {isEmpty} from "../../../shared/utility";

import { getBeginningStock } from '../../../actions/beginningStock'
import BeginningStockFormDetailList from "./BeginningStockFormDetailList";


const useStyles = makeStyles(theme => ({
  rootPaper: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  paperPadding: {
    marginBottom: theme.spacing(2)
  },
  btnCancel: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    fontWeight: "bold",
    '&:hover': {
      borderColor: theme.palette.error.light
    }
  },
  btnDraft: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
}));

const BeginningStockFormDetail = props => {
  const classes = useStyles();
  let { id } = useParams();
  const {getBeginningStock, beginning_stock_detail, loading, status} = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      getBeginningStock(id);
    }, 1000)

    return () => clearTimeout(timer)
  },[getBeginningStock,id]);

  let beginningStockFormDetailList = '';
  if(!isEmpty(beginning_stock_detail)){
    beginningStockFormDetailList = <BeginningStockFormDetailList beginning_stock_detail={beginning_stock_detail} classes={classes} id={id} status={status}/>
  }

  return (
    loading ?  <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h5"} className={classes.tagMenu}>
                Stok Awal
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Paper className={classes.root}>
            <div className={classes.paperPadding}>
              <Typography className={classes.tagMenu} variant={"h5"}>History Stok Awal</Typography>
            </div>
            {beginningStockFormDetailList}
          </Paper>
        </div>
      </div>
    </Fragment>)
}

const mapStateToProps = state => {
  return {
    beginning_stock_detail: state.beginningStock.beginning_stock_detail,
    loading: state.beginningStock.loading,
    status: state.beginningStock.status
  }
}

export default connect(mapStateToProps, {getBeginningStock})(BeginningStockFormDetail);