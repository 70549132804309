import React, {Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Avatar} from "@material-ui/core";
import NumberFormat from "react-number-format";

const ExchangeGoldFromData = props => {
  const {product,index,classes} = props;

  // console.log(product);
  return (
    <Fragment>
      <TableRow key={product.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <div className={classes.dispayFlex}>
            <div className={classes.alignCenter}>
              <Avatar alt="Remy Sharp" src={product.image} className={classes.imgMargin}/>
            </div>
            <div className={classes.alignCenter}>
              {product.name}
            </div>
          </div>
        </TableCell>
        <TableCell>
          <NumberFormat value={product.buyback_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
        </TableCell>
        <TableCell>
          {product.weight}
        </TableCell>
        <TableCell>
          {product.qty}
          {/*/>*/}
        </TableCell>
        <TableCell>
          <NumberFormat value={product.subtotal_gram} displayType={'text'} thousandSeparator={true} suffix={' gram'} decimalScale={2}/>
        </TableCell>
        <TableCell>
          <NumberFormat value={product.subtotal_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `}/>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default ExchangeGoldFromData;