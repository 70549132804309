//import {makeStyles} from '@mui/styles';
import { makeStyles } from '@material-ui/core/styles' 


const Main = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  paperContent: {
    padding: theme.spacing(4)
  },
  paperPadding: {
    marginBottom: theme.spacing(2)
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  chipWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  chipSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    }
  },
  boxMarginRow: {
    marginTop: 20,
    marginBottom: 20,
  },
  flexItemCenter: {
    display: "flex",
    alignItems: "center"
  },
  btnCancel: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    fontWeight: "bold",
    '&:hover': {
      borderColor: theme.palette.error.light
    }
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  btnError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  btnPurple: {
    backgroundColor: '#7d40ff',
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: '#7e45f8'
    }
  },
  roundTimer: {
    margin:'auto 5px',
    border: '1px solid #F99800',
    background: 'rgba(249, 152, 0, 0.16)',
    borderRadius: '20%'
  },

}));

export default Main;