import React,{Fragment} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {isEmpty} from "../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import {TableFooter} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import {TablePaginationActions} from "../../../components/UI";
import PurchaseOrderData from "./PurchaseOrderData";

import {useHistory} from 'react-router-dom';

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'no_invoice', label: 'No Invoice', minWidth: 100 },
  { id: 'no_id', label: 'Tanggal', minWidth: 100 },
  { id: 'email', label: 'PIC', minWidth: 100 },
  { id: 'total_item', label: 'Total Item', minWidth: 100 },
  { id: 'ronde', label: 'Ronde', minWidth: 100 },
  // { id: 'total_rp_invoice', label: 'Total Rp. Invoice', minWidth: 100 },
  { id: 'total_rp_produk', label: 'Total Rp. Produk', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100 },
];

const ListPurchaseOrder = props => {
  const {classes,purchaseOrders,propsHandleChangePage} = props;
  const history = useHistory();

  let tableBody = '';

  const handleChangePage = (event, newPage) => {
    propsHandleChangePage(newPage)
  }

  if(!isEmpty(purchaseOrders)){
    const page = purchaseOrders.current_page - 1;
    const rowsPerPage = 10;
    const countRows = purchaseOrders.total_item
    // console.log(page, rowsPerPage, countRows, emptyRows);
    //
    tableBody = (
      <Fragment>
        <TableBody>
          {purchaseOrders.data.map((purchaseOrder, index) => (
            <PurchaseOrderData
              purchaseOrder={purchaseOrder}
              key={purchaseOrder.id}
              index={index}
              classes={classes}
              detail={(id) => history.push(`purchase-order-v2/${id}`)}

              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={10}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }
  return (
    <Fragment>
      {/*<Paper className={classes.root}>*/}
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      {/*</Paper>*/}
    </Fragment>
  );
};

export default ListPurchaseOrder;