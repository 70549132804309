
import axios from 'axios'
import axiosOrders from '../axios-orders'
import { setAlert } from './alert'
import { 
    GET_PRODUCT_DASHBOARD,
    GET_CARD_STATS,
    GET_NET_INCOME,
    GET_GOLD_PRICE,
    GET_PRODUCT_BUYBACK_DASHBOARD,
    GET_TRANSACTION_SALES,
    GET_GRAFIK_STOCK,
    GET_GRAFIK_STOCK2,
    GET_GRAFIK_STOCK3,
    GET_GRAFIK_STOCK4,
    GET_GRAFIK_STOCK5,
    GET_GRAFIK_STOCK6,
    GET_GRAFIK_HPP,
    GET_GRAFIK_COST,
} from './types'
import * as actionTypes from './types';

export const getProduct = (type) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/product_price?type=${type}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PRODUCT_DASHBOARD,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const fetchGrafikStocksStart = () => {
    return {
        type: actionTypes.FETCH_GRAFIK_STOCKS_START
    }
}

export const fetchGrafikStocksSuccess = (grafikStocks) => {
    return {
        type: actionTypes.FETCH_GRAFIK_STOCKS_SUCCESS,
        grafikStocks: grafikStocks
    }
}

export const fetchGrafikStocksFail = (error) => {
    return {
        type: actionTypes.FETCH_GRAFIK_STOCKS_FAIL,
        errors: error
    }
}

export const fetchGrafikStocks = () => {
    return dispatch => {
        dispatch(fetchGrafikStocksStart());
        axiosOrders.get(`user/dashboard_grafik/on_hands_on_process_v2?unit=&type=gold`,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchGrafikStocksSuccess(res.data))
          })
          .catch(err => {
              dispatch(fetchGrafikStocksFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const getProductBuyback = (type) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/product_price_buyback?type=${type}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PRODUCT_BUYBACK_DASHBOARD,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getCardStats = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/card`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_CARD_STATS,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikNetIncome = (start_date, end_date) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/pendapatan?start_date=${start_date}&end_date=${end_date}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_NET_INCOME,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikGoldPrice = (id_product, type_customer, start_date, end_date) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/gold_price_time?id_product=${id_product}&type_customer=${type_customer}&start_date=${start_date}&end_date=${end_date}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GOLD_PRICE,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikTransactionSales = (start_date, end_date, type) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/trx_day?start_date=${start_date}&end_date=${end_date}&type=${type}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_TRANSACTION_SALES,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikStock = (id) => async dispatch => {
    // const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process?id_product=${id}`
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process_v2?id_product=${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_STOCK,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikStock2 = (id) => async dispatch => {
    // const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process?id_product=${id}`
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process_v2?id_product=${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_STOCK2,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikStock3 = (id) => async dispatch => {
    // const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process?id_product=${id}`
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process_v2?id_product=${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_STOCK3,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikStock4 = (id) => async dispatch => {
    // const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process?id_product=${id}`
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process_v2?id_product=${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_STOCK4,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikStock5 = (id) => async dispatch => {
    // const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process?id_product=${id}`
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process_v2?id_product=${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_STOCK5,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikStock6 = (id) => async dispatch => {
    // const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process?id_product=${id}`
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/on_hands_on_process_v2?id_product=${id}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_STOCK6,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikHPP = (id_product, start_date, end_date) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/hpp?id_product=${id_product}&start_date=${start_date}&end_date=${end_date}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_HPP,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getGrafikCost = (start_date, end_date) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/dashboard_grafik/cost?start_date=${start_date}&end_date=${end_date}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_GRAFIK_COST,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}