import React, {Fragment, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Button, Grid, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {isEmpty} from "../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import BeginningStockFormData from "../BeginningStockFormData/BeginningStockFormData";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers";
import * as yup from "yup";
import NumberFormat from "react-number-format";
import {connect} from "react-redux";
import * as action from '../../../../actions';
import {useHistory} from 'react-router-dom';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

const columns = [
  { id: 'no', label: 'No', minWidth: '5%' },
  { id: 'foto_produk', label: 'Foto Produk', minWidth: '19%' },
  { id: 'item_produk', label: 'Item Produk', minWidth: '19%' },
  { id: 'hpp', label: 'Hpp', minWidth: '19%' },
  { id: 'stock_awal', label: 'Stok Awal(On Hand)', minWidth: '19%' },
  { id: 'Subtotal', label: 'Subtotal', minWidth: '19%' },

];

const schema = yup.object().shape({
  qty: yup.string().required("Quantity tidak boleh kosong"),
});

const BeginningStockFormDetailList = props => {
  const {beginning_stock_detail, classes, onUpdateBeginningStock,id,status} = props
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const list = []
  beginning_stock_detail.map((itemData, index) => (
  list[index] = {
    id: itemData.id,
    id_product: itemData.product_id,
    qty: itemData.qty,
    hpp: itemData.hpp ? itemData.hpp : 0,
    sub_total: itemData.sub_total ? itemData.sub_total : 0
  }));

  const [ stockReal, setStockReal ] = useState(list);

  const handleStockRealChange = (e,item, index) => {
    const { value } = e.target
    const list = [...stockReal]
    list[index] = {
      id: item.id,
      id_product : item.product_id,
      [e.target.name] : value,
      hpp: item.hpp,
      sub_total: item.hpp * value
    }
    setStockReal(list)
  }

  let tableBody = '';

  if(!isEmpty(beginning_stock_detail)){
    tableBody = (
      <Fragment>
        <TableBody>
          {beginning_stock_detail.map((item, index) => (
            <BeginningStockFormData
              item={item}
              key={item.id}
              index={index}
              register={register}
              handleStockRealChange={(e) => handleStockRealChange(e,item,index)}
              stock={stockReal}
              errors={errors}
              status={status}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}
          <TableRow>
            <TableCell colSpan={4}/>
            <TableCell>

              {!isEmpty(stockReal) ? <NumberFormat style={{fontWeight: "bold"}} value={stockReal.map(item => parseInt(item.qty)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} suffix={" pcs"}/> : 0}
            </TableCell>
            <TableCell>
              {!isEmpty(stockReal) ? <NumberFormat style={{fontWeight: "bold"}} value={stockReal.map(item => parseInt(item.sub_total)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/> : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Fragment>
    )
  }

  const onSubmit = data => {
    let total_qty = stockReal.map(item => parseInt(item.qty)).reduce((prev, next) => prev + next);
    let total_price = stockReal.map(item => parseInt(item.sub_total)).reduce((prev, next) => prev + next);

    data.total_qty = total_qty;
    data.total_price = total_price;
    data.products = stockReal;

    delete data.qty;
    onUpdateBeginningStock(id,data,history);
  }

  return(
    <Fragment>
      <form onSubmit={handleSubmit(handleClickOpen)}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
        <Grid container className={classes.row}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant={"h5"}>
              Note:  Stok awal adalah stok terkini semua produk yang ada pada EGS.<br/>
              Stok awal hanya digunakan satu kali saat penggunaan awal Sistem POS EGS.<br/>
              Input stok awal (On Hand) sesuai dengan stok yang ada pada gudang saat ini.<br/>
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>

          </Grid>
          {status === "draft" ? (
              <Grid item lg={2} md={2} sm={12} xs={12}>
                <Grid container spacing={2} justify={"space-between"}>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Button className={classes.btnCancel} variant="outlined" fullWidth
                            onClick={() => history.push('/beginning-stock')}>
                      BATAL
                    </Button>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Button className={classes.btnSuccess} variant="contained" type={"submit"} fullWidth>
                      Simpan
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )
            : ''
          }
        </Grid>
      </form>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{fontWeight: "bold"}}>
            {"Simpan Stok Awal"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText alert-dialog-description>
            <Typography align={"center"} color={"inherit"}>
              Stok awal adalah stok terkini semua produk yang ada pada EGS. Stok awal hanya digunakan satu kali saat penggunaan awal Sistem POS EGS. Input stok awal (On Hand) sesuai dengan stok yang ada pada gudang saat ini.<br/>
              Yakin ingin menyimpan Stok Awal?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.btnCancel} variant="outlined" size={"small"} onClick={handleClose}>Batal</Button>
          <Button className={classes.btnDraft} variant="contained" size={"small"} onClick={() => onSubmit({status: "draft"})}>Draft</Button>
          <Button className={classes.btnSuccess} variant="contained" size={"small"} onClick={() => onSubmit({status: "saved"})}>Selesai & Simpan</Button>
          {/*<Grid container spacing={2}>*/}
          {/*  <Grid item lg={8} md={8} sm={12} xs={12}/>*/}
          {/*  <Grid item lg={4} md={4} sm={12} xs={12}>*/}
          {/*    <Grid container spacing={2} justify={"space-between"}>*/}
          {/*      <Grid item lg={6} md={6} sm={6} xs={6}>*/}
          {/*        <Button fullWidth className={classes.btnCancel} variant="outlined" size={"small"} onClick={handleClose}>Batal</Button>*/}
          {/*      </Grid>*/}
          {/*      <Grid item lg={6} md={6} sm={6} xs={6}>*/}
          {/*        <Button fullWidth className={classes.btnDraft} variant="contained" size={"small"} onClick={() => onSubmit({status: "draft"})}>Draft</Button>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*    <Grid container spacing={2} justify={"space-between"}>*/}
          {/*      <Grid item lg={12} md={12} sm={12} xs={12}>*/}
          {/*        <Button fullWidth className={classes.btnSuccess} variant="contained" size={"small"} onClick={() => onSubmit({status: "saved"})}>Selesai & Simpan</Button>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateBeginningStock: (id,storeData,history) => dispatch(action.updateBeginningStock(id, storeData,history))
  }
}

export default connect(null, mapDispatchToProps)(BeginningStockFormDetailList);