import React, {Fragment} from "react";
import {Button, Divider, Grid, IconButton, InputBase, Paper, Typography} from "@material-ui/core";
import moment from "moment";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {Edit, RotateLeft} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

const FormSearchCustomer = props => {
  const {
    classes,
    isOpen,
    setIsOpen,
    startDate,
    handleStartDate,
    minDate,
    searchCustomer,
    reset
  } = props;

  console.log(new Date().getTimezoneOffset())

  return (
    <Fragment>
      {/*{loading}*/}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          <Typography>Tanggal & Waktu Transaksi</Typography>
          <div className={classes.row}>
            <Grid container spacing={2}>
              <Grid lg={11} md={11} sm={11} xs={11}>
                <Typography variant={"h3"} style={{fontWeight: "bold", padding:"5px 0px 0px 8px"}}>
                  {moment(startDate.startDate).format('dddd DD MMMM YYYY, HH:mm:ss')} WIB
                </Typography>
              </Grid>
              <Grid lg={1} md={1} sm={1} xs={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/*<Button > Open picker </Button>*/}
                  <IconButton type="button" className={classes.iconButton} aria-label="search" onClick={() => setIsOpen(true)}>
                    <Edit/>
                  </IconButton>
                  <DateTimePicker
                    fullWidth
                    open={isOpen}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    disableFuture
                    minDate={minDate}
                    ampm={false}
                    variant="outlined"
                    name="start_date"
                    format="dd MMMM yyyy HH:mm"
                    value={startDate.startDate}
                    onChange={e => handleStartDate(e, 'endDate')}
                    TextFieldComponent={() => null}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {/*<Grid lg={1} md={1} sm={1} xs={1}>*/}
              {/*  <IconButton type={"button"} className={classes.iconButton} onClick={() => reset()}>*/}
              {/*    <RotateLeft/>*/}
              {/*  </IconButton>*/}
              {/*</Grid>*/}
            </Grid>

          </div>
        </Grid>
        <Grid item lg={12} md={12} xs={12} sm={12}>
          {/*<Typography>Cari Customer</Typography>*/}
          <div>
            <Paper component="form" className={classes.searchRoot} onClick={() => searchCustomer()}>
              <IconButton type="button" className={classes.iconButton} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <InputBase
                className={classes.input}
                name="nama"
                // value={keyword.values.keyword || ''}
                // onClick={() => searchCustomer()}
                placeholder="Cari Customer"
                inputProps={{ 'aria-label': 'Cari Customer' }}
              />

            </Paper>
          </div>
        </Grid>

        <Grid container justify={"flex-start"}>
          <Grid item>
            <div style={{paddingLeft: '10px', paddingTop:'3px'}}>
              <Button variant={"contained"} size={"medium"} className={classes.btnError} endIcon={React.cloneElement(<RotateLeft/>)} onClick={() => reset()}>
                Reset
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FormSearchCustomer;