import React, {Fragment, useEffect} from "react";
import * as actions from '../.././../actions';
import {connect} from "react-redux";
import {Grid, Typography} from "@material-ui/core";
import {Main} from '../Styles/'
import CreatePurchaseOrderV2Form from "./CreatePurchaseOrderV2Form";
import {isEmpty} from "../../../shared/utility";
import {Loading} from "../../../components/UI";
import CountDownPO from "./CountDownPO";

const CreatePurhcaseOrderV2 = props => {
  const classes = Main();
  const {onFetchProducts,loading, products,round} =props
  useEffect(() => {
    onFetchProducts()
  },[onFetchProducts]);

  let createPurchaseOrderV2Form = '';
  let countDownPO = '';
  if(!isEmpty(products) && !(isEmpty(round))){
    createPurchaseOrderV2Form = (<CreatePurchaseOrderV2Form products={products} round={round} classes={classes}/>);
    countDownPO = (<CountDownPO classes={classes} round={round}/>);
  }

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid
            container
            spacing={2}
            justify={"space-between"}
          >
            <Grid item>
              <Typography variant="h5"><b>Purchase Order EOA Gold</b></Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          {countDownPO}
        </div>
        <div className={classes.row}>
          {createPurchaseOrderV2Form}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.purchaseOrder.loading,
    products: state.purchaseOrder.products,
    round: state.purchaseOrder.round
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchProducts: () =>dispatch(actions.fetchPurchaseOrderProducts())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CreatePurhcaseOrderV2);