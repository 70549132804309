import React, {Fragment, useEffect} from "react";
import {Main} from '../style';
import {Button, Grid} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import {useParams} from 'react-router-dom';
import * as actions from '../../../actions';
import {connect} from "react-redux";
import {Loading} from "../../../components/UI";
import {Customer, ExchangeGoldFrom, ExchangeGoldTo} from "./components";
import {CustomRouterLink, isEmpty} from "../../../shared/utility";

const DetailSwapGramation = props => {
  const {id} = useParams();
  const {
    onGetSwapHistory,
    swapHistory,
    loading
  } = props;

  useEffect(() => {
    onGetSwapHistory(id)
  },[onGetSwapHistory, id])

  // console.log(swapHistory);

  const classes = Main();
  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div className={classes.tagMenu}>
                Detail Tukar Gramasi
              </div>
            </Grid>
            <Grid item>
              <Button className={classes.btnBack} variant={"contained"} size={"medium"} component={CustomRouterLink} to={'/swap-gramation'} startIcon={React.cloneElement(<ArrowBack/>)}>
                Back
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Paper className={classes.paperPadding}>
            <div className={classes.paperContent}>
              <div className={classes.customerRow}>
                {
                  !isEmpty(swapHistory) ?
                    <Customer classes={classes} swapHistory={swapHistory}/> :
                    ''
                }
              </div>
              <hr/>
              <div className={classes.row}>
                {
                  !isEmpty(swapHistory) ?
                    <ExchangeGoldFrom classes={classes} swapHistory={swapHistory}/> :
                    ''
                }
              </div>
              <div className={classes.row}>
                {
                  !isEmpty(swapHistory) ?
                    <ExchangeGoldTo classes={classes} swapHistory={swapHistory}/> :
                    ''
                }
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    swapHistory: state.swapGramation.swapHistory,
    loading: state.swapGramation.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetSwapHistory: (id) => dispatch(actions.getSwapGramation(id))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(DetailSwapGramation);