import React ,{forwardRef, Fragment, useEffect, useState} from 'react'
import {
    Grid,
    LinearProgress,
    Select,
    OutlinedInput,
    MenuItem,
    Box,
    Button,
    ListItem,
    List,
    ListItemAvatar,
    Avatar,
    Tooltip,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardMedia,
    Typography,
    Input,
    InputLabel,
    FormControl,
    FormHelperText,
    Switch,
    TableContainer,
    TableHead,
    TableBody,
    Table, 
    TableRow,
    TableCell,

  } from "@material-ui/core";


import Modal from '../../components/UI/Modal/ScrollDialog/ScrollDialog';

// components

import CustomCard from "../../components/Card/Card";

import RincianPesanan from './RincianPesanan'; 
//import CountDownPO from '../../components/CountDownPO/CountDownPO';
import StatusPurchase from '../../components/StatusPurchase/StatusPurchase';
import {Delete as DeleteIcon, Search} from '@material-ui/icons';
//css
import {Main} from './style';

//import FileUpload from "react-mui-fileuploader";
import { DropzoneArea } from "material-ui-dropzone";
//import {FilePreviewer,FilePreviewerThumbnail} from 'react-file-previewer';
import { useSnackbar } from "../../shared/useSnackbar";

//services
import registrationService from '../../services/registration.service';
import { useParams } from 'react-router-dom';

import { useHistory } from "react-router-dom";
import DataRegistration from './DataRegistration';


function createName(p,w, g) {
  return p.toString() + ' ' + w.toString() + ' ' + g.toString();
}

function ccyFormat(num) {
  
  const rupiah = (number)=>{
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR"
    }).format(number);
  };

  return rupiah(num);
}

function priceRow(qty, unit) {
  return qty * unit;
}

var rincianData= {
  totalItem:0,
  totalOrder:0,
  totalBerat:0,
  totalHarga:0
}

function CountQty(item){
  return item.map(data => data.quantity).reduce((key, val) => val + key);
}

function CountGram(item){
  return item.map(data => Number(data.weight)).reduce((key, val) => val + key);
}

function CountPrice(item){
  return item.map(data => data.sell_price*data.quantity).reduce((key, val) => val + key);
}


export default function DetailPurhcaseOrder(props) {
  const classes = Main();
  let { id } = useParams();
  var iRowNo = 1; 
  let history = useHistory(); 
  const { createSnackbar } = useSnackbar();
  const showSnackbar = (msg,theme) => {
    createSnackbar({
      message: msg,
      data: {
        // extra props to your custom component
      },
    
      dismissable: false,
      theme:theme,
      sticky: false
    });
  };

  const [openUploadKirim, setOpenUploadKirim] = React.useState(false);
  const handleClose = () => setOpenUploadKirim(false);

  const [openUploadSampai, setOpenUploadSampai] = React.useState(false);
  const handleCloseSampai = () => setOpenUploadSampai(false);

  const [purchaseOrder,setPurchaseOrder] = useState({});
  const [fileUpload,setFileUpload] = useState([]);
  
  const [rincianNya,setRincian] = useState(rincianData);
  
  const [purchaseStatusProcess, setPurchaseStatusProcess] = React.useState(false);
  const [purchaseStatusCancel, setPurchaseStatusCancel] = React.useState(false);

  const [detailRegistration, setDetailRegistration] = React.useState(false);
  const detailRegistrationProsess = () => setDetailRegistration(false);


  const handleCloseProsess = () => setPurchaseStatusProcess(false);
  const handleCloseCancel = () => setPurchaseStatusCancel(false);

  const handleChangeDropZone = (newFiles) => {
    console.log(newFiles);
    //showSnackbar(newFiles);
    if(newFiles.length>0){
      var temporaryURL = window.URL.createObjectURL(newFiles[0]);
      showSnackbar(temporaryURL);
    }
    setFileUpload(newFiles);
  }

  const handleKirim =async () => {
    setOpenUploadKirim(false);


    await registrationService.changestatuspo({invoice_id:id,status_po:'3'}).then(
      (response)=>{
          //alert(response);
          showSnackbar('Sukses Produk Dikirim','success');
          handleCloseProsess(true);
          setOpenUploadKirim(false);
          setOpenUploadSampai(false);

          history.push('/approval-po');
           
      }).catch(error => showSnackbar(`Error On Produk Dikirim: ${JSON.stringify(error.response.data, null, "\t")}`,"error"));
     

      setOpenUploadKirim(false);
      setOpenUploadSampai(false);
      
 
  }
  

  const getAllPruchase = async (params) => { 

    //alert(id);
    return await registrationService.getdetail({invoice_id:id}).then(
      (response)=>{
          const allBranch = response; 
            
          setPurchaseOrder(allBranch);

          let dataDetail = allBranch.detail;
          console.log(dataDetail);
          //showSnackbar( `CountQty ${CountQty(dataDetail)}`);
          //showSnackbar( `CountGram ${CountGram(dataDetail)}`);
          //showSnackbar( `CountPrice ${CountPrice(dataDetail)}`);
          
          rincianData.totalBerat =CountGram(dataDetail);
          rincianData.totalHarga =CountPrice(dataDetail);
          rincianData.totalItem =CountQty(dataDetail);
          rincianData.totalOrder =dataDetail.length;
          setRincian(rincianData);
         
      }).catch(error => console.error(`Error: $(error)`));
  };

  const onProcess = async (data) => {
    console.log('invoice_id:',data);
   await registrationService.changestatuspo({invoice_id:data,status_po:'2'}).then(
      (response)=>{
          //alert(response);
          showSnackbar('Sukses approve Data Registrasi','success');
          handleCloseProsess(true);
          window.location.reload(false);
          //onAlert('Sukses mendaftarkan Data Registrasi','success')  
      }).catch(error => showSnackbar(`Error On Approve Registration: ${JSON.stringify(error.response.data, null, "\t")}`,"error"));
      handleCloseProsess(true);
  }

  const onReject =  async (data) => {
    registrationService.changestatuspo({invoice_id:data,status_po:'99'}).then(
      (response)=>{
          //alert(response);
          showSnackbar('Sukses reject Data Registrasi','success');
          handleCloseProsess(true);
          window.location.reload(false);
      }).catch(error => showSnackbar(`Error On Reject Registration: ${JSON.stringify(error.response.data, null, "\t")}`,"error"));
      handleCloseProsess(true);
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  useEffect(() => {
    // Update the document title using the browser API
    getAllPruchase({});
    
  },[]);



  return (
    <>  
      <Modal 
        open={detailRegistration}
        title="Detail Purchase Order Registration"
        onClose={detailRegistrationProsess}
        >
          <DataRegistration
            disabled="false"
            datas={(purchaseOrder.registered_detail?purchaseOrder.registered_detail:[])}
            //id= {(purchaseOrder.invoice?purchaseOrder.invoice.invoice_id:'')}
          />

              <Box
                        m={1}
                      //margin
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        
                      >
                       
                        <Button variant="contained" color="primary" 
                        sx={{ height: 40 }}
                        onClick={() => setDetailRegistration(false)}
                        >
                          Close
                        </Button>
                      </Box>

      </Modal>

         <Modal 
        open={purchaseStatusCancel}
        title="Konfirmasi Purchase Order Ditolak?"
        onClose={handleCloseCancel}
        >
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Purchase order untuk registrasi AOG/MOG baru akan ditolak karena alasan tertentu. Infromasikan kepada AOG/MOG yang mendaftarkan mengenai purchase prder yang akan di tolak.
            <br/> Yakin ingin menolak purchase order sekarang?
          </Typography>

          <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="secondary" color="primary"  sx={{ height: 40 }}
                          onClick={() => handleCloseCancel(true)}
                        >
                        BATAL
                      </Button> &nbsp;
                      <Button variant="contained" color="#4caf50"  sx={{ height: 40 }}
                        
                          onClick={() => onReject(purchaseOrder.invoice.invoice_id)}
                        >
                        PROSES
                      </Button>
                    </Box>

      </Modal>

       <Modal 
        open={purchaseStatusProcess}
        title="Konfirmasi Purchase Order Diproses?"
        onClose={handleCloseProsess}
        >
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
          Purchase order untuk registrasi AOG/MOG baru akan diproses sesuai dengan rincian pesanan. Pastikan semua informasi purchase order tidak ada kesalahan
          </Typography>

          <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="secondary" color="primary"  sx={{ height: 40 }}
                          onClick={() => handleCloseProsess(true)}
                        >
                        BATAL
                      </Button> &nbsp;
                      <Button variant="contained" color="#4caf50"  sx={{ height: 40 }}
                          onClick={() => onProcess(purchaseOrder.invoice.invoice_id)}

                        >
                        PROSES
                      </Button>
                    </Box>

      </Modal>

      <Modal 
        open={openUploadKirim}
        title="Konfirmasi Produk Dikirim"
        onClose={handleClose}
        >
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Purchase order untuk registrasi MOG/AOG baru akan dikirim ke alamat AOG/MOG yang mendaftarkan.Pastikan produk akan dikirim sesuai dengan rincian pesanan <br/>
            Yakin ingin melakukan pengiriman sekarang?
          </Typography>

          <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="secondary" color="primary"  sx={{ height: 40 }}
                          onClick={() => setOpenUploadKirim(false)}
                        >
                        Batal
                      </Button> &nbsp;
                      <Button variant="contained" color="#4caf50"  sx={{ height: 40 }}
                          onClick={handleKirim}
                        >
                        Kirim
                      </Button>
                    </Box>

      </Modal>
      <Modal 
        open={openUploadSampai}
        title="Kirim Upload Bukti Produk Sampai?"
        onClose={handleClose}
        >
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
          Pastikan foto bukti produk yang telah dikirim sudah benar dan sesuai. Upload bukti untuk mengkonfirmasi ke admin EGS bahwa produk telah sampai.
          </Typography>

          <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="secondary" color="primary"  sx={{ height: 40 }}
                          onClick={() => setOpenUploadSampai(true)}
                        >
                        Batal
                      </Button> &nbsp;
                      <Button variant="contained" color="#4caf50"  sx={{ height: 40 }}
                          onClick={handleKirim}
                        >
                        Kirim
                      </Button>
                    </Box>

      </Modal>
     <Fragment>
    <div className={classes.root}>     
        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div className={classes.tagMenu}>
                Detail Purhcase Order
              </div>
            </Grid>
            
            {(purchaseOrder.invoice? (purchaseOrder.invoice.status_po<1?
              <Grid item>
               {/* <CountDownPO classes={classes} /> */}
              </Grid>
              :''):'')}
            
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item xl={8} md={8} sm={12} xs={12}>
                <CustomCard title = "Upload Bukti Bayar">
               
               
                       

                        <br/>

                         {(purchaseOrder.invoice? (purchaseOrder.invoice.status_po>=1?  
                        <Grid item xl={6} md={6} sm={6} xs={12}> 
                        
                          <Card sx={{ maxWidth: 180 }}>
                          <CardHeader/>
                          <CardMedia
                              component="img"
                              height="180"
                              width="180"
                              image={(purchaseOrder.invoice? (purchaseOrder.invoice.proof_payment_image?purchaseOrder.invoice.proof_payment_image:''):'') }
                              alt="Paella dish"
                              onClick={() => { openInNewTab((purchaseOrder.invoice? (purchaseOrder.invoice.proof_payment_image?purchaseOrder.invoice.proof_payment_image:'#'):'#') ) }}
                            />
                          <CardContent>

                          </CardContent>
                        </Card>
                        </Grid>

                         

                        :''):'')}
                        
                        
                


                  </CustomCard>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
                <RincianPesanan classes={classes} purchaseOrder={rincianNya} />

            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
            <CustomCard title = "Detail Invoice">
                  <Grid container spacing={2}>
                    {purchaseOrder.invoice?
                  <Table>
                    <TableRow>
                      <TableCell>
                          No. Invoice
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.invoice?purchaseOrder.invoice.invoice_id:""}
                      </TableCell>
                      <TableCell>
                          EGS Tujuan
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder?purchaseOrder.invoice.branch:""}
                      </TableCell>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell>
                          Tanggal
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.invoice.created_at}
                      </TableCell>
                      <TableCell>
                          Status
                      </TableCell>
                      <TableCell>
                          :  <StatusPurchase classes={classes} status_po={purchaseOrder.invoice.status_po_str} /> 
                      </TableCell>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell>
                          No. Telepon
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.invoice.whatsapp_number}
                      </TableCell>
                      <TableCell>
                          Penerima
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.invoice.receiver_name}
                      </TableCell>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        
                      </TableCell>
                      <TableCell>
                          
                      </TableCell>
                      <TableCell>
                          Alamat Penerima
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.invoice.receiver_address}
                      </TableCell>
                      
                    </TableRow>
                  </Table>
                  :""}
                  </Grid>
            </CustomCard>
            <CustomCard title = "Data Registrasi">
                  <Grid container spacing={2}>
                  {purchaseOrder.registered?
                  <Table>
                    <TableRow>
                      <TableCell>
                          Nama
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.registered?purchaseOrder.registered.name:""}
                      </TableCell>
                      <TableCell>
                          Email
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.registered?purchaseOrder.registered.email:""}
                      </TableCell>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell>
                          Jenis Kelamin
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.registered.gender}
                      </TableCell>
                      <TableCell>
                          No. Whatsapp
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.registered.whatsapp_number}
                      </TableCell>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell>
                          No. KTP
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.registered.ktp_number}
                      </TableCell>
                      <TableCell>
                          Nama Bank
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.registered.bank_name}
                      </TableCell>
                      
                    </TableRow>
                    <TableRow>
                      <TableCell>
                           ID AOG/MOG
                      </TableCell>
                      <TableCell>
                      :  {purchaseOrder.registered.aogmog_id}  
                      </TableCell>
                      <TableCell>
                         No. Rekening
                      </TableCell>
                      <TableCell>
                          :  {purchaseOrder.registered.account_number}
                      </TableCell>
                      
                    </TableRow>
                  </Table>
                  :""}
                  </Grid>
            </CustomCard>
            <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      
                    >
                      <Button variant={"contained"} size={"medium"} className={classes.buttonWarning} 
                        onClick={() => setDetailRegistration(true)}
                      >
                      Lihat Detail Registrasi
                  </Button>
                     
                    </Box>

            <CustomCard title = "List Order">
              <Grid container spacing={2}>
              <TableContainer >
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell align="center">Item Product</TableCell>
                        <TableCell align="center">Harga Satuan</TableCell>
                        <TableCell align="right">Jumlah Order</TableCell>
                        <TableCell align="right">Sub Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { purchaseOrder.detail ? purchaseOrder.detail.map((row) => (

                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="right">{iRowNo++}</TableCell>
                          <TableCell component="th" scope="row">
                            {createName(row.product_name,row.weight,row.unit)}
                          </TableCell>
                          <TableCell align="right">{row.quantity}</TableCell>
                          <TableCell align="right">{ccyFormat(row.sell_price)}</TableCell>
                          <TableCell align="right">{ccyFormat(priceRow(row.quantity, row.sell_price))}</TableCell>
                        
                        </TableRow>
                      )):''}

                      
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <br/>      
              {(purchaseOrder.invoice? (purchaseOrder.invoice.status_po===1?                    
               <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="primary"  sx={{ height: 40 }}
                          onClick={() => setPurchaseStatusProcess(true)}
                        >
                        PROSSES
                      </Button> &nbsp;
                      <Button variant="outlined" color="error"  sx={{ height: 40 }}
                          onClick={() => setPurchaseStatusCancel(true)}
                        >
                        TOLAK
                      </Button>
                </Box>
                :''):'')}
            </CustomCard>         
            {(purchaseOrder.invoice? (purchaseOrder.invoice.status_po===2?
                 <Box
                 m={1}
               //margin
                 display="flex"
                 justifyContent="flex-end"
                 alignItems="flex-end"
                 
               >
               
                 <Button variant="contained" color="primary" 
                 disabled={(purchaseOrder.invoice? (purchaseOrder.invoice.status_po===2?false:true):true)} 
                 sx={{ height: 40 }}
                     onClick={() => setOpenUploadKirim(true)}
                   >
                   Kirim Emas
                 </Button>
               </Box>
              :''):'')}

            </Grid>
          </Grid>
        </div>
    </div>
    </Fragment>
    </>
  );
}
