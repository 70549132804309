import React,{Fragment} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {isEmpty} from "../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import {TableFooter} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import {TablePaginationActions} from "../../../../components/UI";
import DataSwabGramation from "./DataSwabGramation/DataSwabGramation";

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'no_invoice', label: 'No Invoice', minWidth: 100 },
  { id: 'tanggal_tukar', label: 'Tanggal Tukar', minWidth: 100 },
  { id: 'customer', label: 'Customer', minWidth: 200 },
  { id: 'tipe', label: 'Tipe', minWidth: 100 },
  { id: 'no_id', label: 'No ID', minWidth: 100 },
  { id: 'total_gram_ditukar', label: 'Total Gram Ditukar', minWidth: 100 },
  { id: 'PIC', label: 'PIC', minWidth: 100 },
  { id: 'detail', label: 'Detail', minWidth: 100 },
];

const ListSwapGramation = props => {
  const {
    classes,
    swapHistories,
    propsHandleChangePage
  } = props;

  const handleChangePage = (event, newPage) => {
    propsHandleChangePage(newPage)
  }

  let tableBody = '';
  if(!isEmpty(swapHistories)){
    const page = swapHistories.current_page - 1;
    const rowsPerPage = 10;
    const countRows = swapHistories.total_item

    tableBody = (
      <Fragment>
        <TableBody>
          {swapHistories.data.map((swabHistory, index) => (
            <DataSwabGramation
              swabHistory={swabHistory}
              key={swabHistory.id}
              index={index}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={9}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <div className={classes.tagMenu}>
          History Tukar Gramasi
        </div>
        <div className={classes.row}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {tableBody}
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </Fragment>
  );
};

export default ListSwapGramation;