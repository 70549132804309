import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Chip } from "@material-ui/core";

const Chips = ({ status }) => {
  const classes = useStyles();

  const notClaim = (
    <Chip
      label="Belum diklaim"
      className={classes.chipWarning}
      size={"medium"}
    />
  );

  const process = (
    <Chip
      label="Diproses klaim"
      className={classes.chipSecondary}
      size={"medium"}
    />
  );

  const transfered = (
    <Chip label="Sudah Transfer" className={classes.chipPrimary} size={"medium"} />
  );

  const finish = (
    <Chip
      label="Selesai Klaim"
      className={classes.chipSuccess}
      size={"medium"}
    />
  );

  const ditolak = (
    <Chip label="Ditolak" className={classes.chipError} size={"medium"} />
  );
  const expired = <Chip label="Expired" size={"medium"} />;

  if (status === 0) {
    return notClaim;
  } else if (status === 1) {
    return process;
  } else if (status === 2) {
    return transfered;
  } else if (status === 3) {
    return finish;
  } else if (status === 98) {
    return ditolak;
  } else return expired;
};

export default Chips;

const useStyles = makeStyles((theme) => ({
  chipWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  chipSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
}));
