import { getApi, postApi, putApi } from '../shared/api';

const getProfile = (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/branch/profile`;

  return getApi(endpoint, params).then((response) => {
    return response;
  });
};

const putProfile = (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/branch/profile`;

  return putApi(endpoint, params).then((response) => {
    return response;
  });
};

const changePicture = (params) => {
  const endpoint = `${process.env.REACT_APP_AUTH_BASE_URL}/auth/pos-cabang/user/update/picture`;
  return postApi(endpoint, params).then((response) => {
    return response;
  });
};

//declare
const userService = {
  getProfile,
  putProfile,
  changePicture,
};
export default userService;
