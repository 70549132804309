import React, {Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const TableBodyComponents = props => {
  console.log(props);
  const {member} = props;
  return (
    <Fragment>
      <TableRow key={member.id_agent}>
        <TableCell>
          {member.name}
        </TableCell>
        <TableCell>
          {member.last_member_type}
        </TableCell>
        <TableCell>
          {member.id_member}
        </TableCell>
        <TableCell>
          {member.email}
        </TableCell>
        <TableCell>
          {member.no_wa}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default TableBodyComponents;