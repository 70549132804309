import { GET_PRODUCT_PO, GET_PRODUCT, GET_PRODUCT_START, CLEAR_PRODUCT } from '../actions/types'

const initialState = {
    products: null,
    productPO: null,
    loading: true,
    loadingProducts: false,
    error: {}
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_PRODUCT_PO:
            return {
                ...state,
                productPO: payload,
                loading: false
            }
        case GET_PRODUCT_START:
            return {
                ...state,
                loadingProducts: true
            }
        case GET_PRODUCT:
            return {
                ...state,
                products: payload,
                loadingProducts: false
            }
        case CLEAR_PRODUCT:
            return {
                ...state,
                products: null,
            }
        default:
            return state
    }
}

export default reducer;