import React, { Fragment } from "react";
import {
  TableFooter,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody,
  Chip,
} from "@material-ui/core";
import { TablePaginationActions } from "../../../../components/UI";
import DetailIcon from "@material-ui/icons/Search";
import NumberFormat from "react-number-format";

import { useHistory } from "react-router-dom";

const columns = [
  { id: "no", label: "No", minWidth: 100 },
  { id: "no_invoice", label: "No Invoice", minWidth: 100 },
  { id: "name", label: "Nama", minWidth: 100 },
  { id: "total", label: "Total Harga", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 100 },
];

const ListPurchaseOrder = (props) => {
  const { classes, purchaseOrders, page, rowPerPage, setPage } = props;
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const menunggu_konfirmasi = (
    <Chip
      label="Menunggu Konfirmasi"
      className={classes.chipWarning}
      size={"medium"}
    />
  );
  const sampai = (
    <Chip label="Selesai" className={classes.chipSuccess} size={"medium"} />
  );
  const dikirim = (
    <Chip label="Dikirim" className={classes.chipPrimary} size={"medium"} />
  );
  const pending = (
    <Chip label="Pending" className={classes.chipWarning} size={"medium"} />
  );
  const diproses = (
    <Chip label="Diproses" className={classes.chipSecondary} size={"medium"} />
  );
  const ditolak = (
    <Chip label="Ditolak" className={classes.chipError} size={"medium"} />
  );
  const expired = <Chip label="Expired" size={"medium"} />;
  const new_invoice = (
    <Chip label="New Invoice" className={classes.chipWarning} size={"medium"} />
  );

  return (
    <Fragment>
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ minWidth: "340px" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOrders?.data?.map((purchaseOrder, index) => (
              <TableRow key={purchaseOrder.invoice_id}>
                <TableCell>{index + 1 + (page - 1) * rowPerPage}</TableCell>
                <TableCell>{purchaseOrder.invoice_id}</TableCell>
                <TableCell>{purchaseOrder.name}</TableCell>
                <TableCell>
                  <NumberFormat
                    value={purchaseOrder.payment}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
                <TableCell>
                  {purchaseOrder.status_str === "Menunggu Konfirmasi"
                    ? menunggu_konfirmasi
                    : purchaseOrder.status_str === "Selesai"
                    ? sampai
                    : purchaseOrder.status_str === "Dikirim"
                    ? dikirim
                    : purchaseOrder.status_str === "Pending"
                    ? pending
                    : purchaseOrder.status_str === "Diproses"
                    ? diproses
                    : purchaseOrder.status_str === "Ditolak"
                    ? ditolak
                    : purchaseOrder.status_str === "Expired"
                    ? expired
                    : purchaseOrder.status_str === "New Invoice"
                    ? new_invoice
                    : ""}
                </TableCell>
                <TableCell>
                  <Tooltip title="Detail Purchase Order">
                    <IconButton
                      aria-label="detail"
                      onClick={() =>
                        history.push(
                          `/approval-po-upgrade/${purchaseOrder.invoice_id}`
                        )
                      }
                    >
                      <DetailIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[rowPerPage]}
                count={purchaseOrders?.total}
                rowsPerPage={rowPerPage}
                page={page - 1}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default ListPurchaseOrder;
