import React, {Fragment, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {isEmpty} from "../../../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import FormProductData from "./FormProductData/FormProductData";
import NumberFormat from "react-number-format";

const columns = [
  { id: 'no', label: 'No', minWidth: '3%' },
  // { id: 'foto_produk', label: 'Foto Produk', minWidth: '16%' },
  { id: 'item_produk', label: 'Item Produk', minWidth: '16%' },
    { id: 'stock', label: 'Harga Buyback', minWidth: '16%' },
  { id: 'harga', label: 'Weight', minWidth: '16%' },
  { id: 'kuantitas', label: 'Qty', minWidth: '16%' },
  { id: 'Subtotal', label: 'Subtotal Item Gram', minWidth: '16%' },
  { id: 'SubtotalBuyBack', label: 'Subtotal Harga Buyback', minWidth: '16%' },
];

const FormProductList = props => {
  const {
    products,
    classes,
    listExchangeGoldFrom,
    setCartExchangeGoldFrom,
    cartExchangeGoldFrom
  } = props;

  // const list = [];
  products.map((itemData, index) => (
    listExchangeGoldFrom[index] = {
      id_product: itemData.product.id,
      qty: 0,
      weight: itemData.product ? itemData.product.weight : 0,
      hpp_price: itemData.product.latest_price ? itemData.product.latest_price.sell_price : 0,
      buy_price: itemData.product.latest_price ? itemData.product.latest_price.buy_price : 0,
      buyback_price: itemData.product.latest_price_buyback ? itemData.product.latest_price_buyback.buyback_price : 0,
      sub_total_gram: itemData.product ? itemData.product.weight * 0 : 0,
      subtotal_price: itemData.product.latest_price_buyback ? itemData.product.latest_price_buyback.buyback_price * 0 : 0
    }));
  // console.log(listExchangeGoldFrom)

  // setCartExchangeGoldFrom(listExchangeGoldFrom)
  const [ cartQty, setCartQty] = useState(listExchangeGoldFrom);
  setCartExchangeGoldFrom(cartQty);
  // console.log(cartQty);

  const handleCartQtyChange = (e,product, index) => {
    e.persist();
    let dataCount = e.target.value === '' ? 0 : parseInt(e.target.value);
    const list = [...cartExchangeGoldFrom]
    if (dataCount === '' || dataCount < 0){
      dataCount = 0;
    }
    // if (dataCount >= parseInt(product.product.stock_on_hand)){
    //   dataCount = product.product.stock_on_hand;
    // }
    list[index] = {
      id_product : product.product.id,
      [e.target.name] : dataCount,
      weight: product.product ? product.product.weight : 0,
      hpp_price: product.product.latest_price ? product.product.latest_price.sell_price : 0,
      buy_price: product.product.latest_price ? product.product.latest_price.buy_price : 0,
      buyback_price: product.product.latest_price_buyback ? product.product.latest_price_buyback.buyback_price : 0,
      sub_total_gram: product.product ? product.product.weight * dataCount : 0,
      subtotal_price: product.product.latest_price_buyback ? product.product.latest_price_buyback.buyback_price * dataCount : 0
    }
    setCartQty(list)
    setCartExchangeGoldFrom(list)
  }

  let tableBody = '';

  if(!isEmpty(products)){
    tableBody = (
      <Fragment>
        <TableBody>
          {products.map((product, index) => (
            <FormProductData
              product={product}
              key={product.id}
              index={index}
              classes={classes}
              // register={register}
              handleCartQtyChange={(e) => handleCartQtyChange(e,product,index)}
              cartExchangeGoldFrom={cartExchangeGoldFrom}
              cartQty={cartQty}
              // errors={errors}
            />
          ))}
          <TableRow>
            <TableCell colSpan={5}/>
            <TableCell>
              {!isEmpty(cartExchangeGoldFrom) ? <NumberFormat style={{fontWeight: "bold"}} value={cartExchangeGoldFrom.map(item => parseFloat(item.sub_total_gram)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} suffix={" gram"} decimalScale={2}/> : 0}
            </TableCell>
            <TableCell>
              {!isEmpty(cartExchangeGoldFrom) ? <NumberFormat style={{fontWeight: "bold"}} value={cartExchangeGoldFrom.map(item => parseInt(item.subtotal_price)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/> : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Fragment>
    )
  }

  // const sendCartQty = data => {
  //   props.getCartQty(data)
  // }

  return (
    <Fragment>
      {/*{setCart(listExchangeGoldFrom)}*/}
      {/*{sendCartQty(cartQty)}*/}
      <div className={classes.row}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

export default FormProductList;