import React,{Fragment} from "react";
import {Avatar, Box, TableCell, TableRow, TextField} from "@material-ui/core";
import NumberFormat from "react-number-format";

const CreateproductV2FormData = props => {
  const {product,index,handleOrderQtyChange,orderQty} = props;
  return (
    <Fragment>
      <TableRow key={product.product.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <Box display={'flex'} flexDirection="row" alignItems="center" justifyContent={"flex-start"}>
            <Box  p={1}>
              <Avatar alt="Remy Sharp" src={product.product.image} />
            </Box>
            <Box  p={1}>
              {product.product.name+' '+product.product.weight+' '+product.product.unit}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <NumberFormat value={product.latest_price.sell_price} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/>
        </TableCell>
        <TableCell>
          <NumberFormat value={product.kuota_sbs} displayType={'text'} thousandSeparator={true}/>
        </TableCell>
        <TableCell>
          <NumberFormat value={product.kuota_cabang} displayType={'text'} thousandSeparator={true}/>
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            name="qty"
            type="number"
            variant="outlined"
            size={"small"}
            // inputRef={register}
            // helperText={
            //   errors.qty && errors.qty.message
            // }
            // error={errors.qty && true}
            // disabled={product.kuota_sbs < 1}
            onChange={e => handleOrderQtyChange(e,product,index)}
            placeholder={0}
            value={orderQty[index].qty || ''}
          />
        </TableCell>
        <TableCell>
          {orderQty[index] ? <NumberFormat value={(product.kuota_sbs <= product.sisa_kuota ? product.kuota_sbs : product.sisa_kuota ) - orderQty[index].qty} displayType={'text'} thousandSeparator={true}/> : 0}
        </TableCell>
        <TableCell>
          {orderQty[index] ? <NumberFormat value={orderQty[index].qty * product.latest_price.sell_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `} /> : 0}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default CreateproductV2FormData;