import React, { useState, useEffect, Fragment } from 'react'
import {
    Grid,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Button,
    IconButton,
    SwipeableDrawer,
    Hidden,
    Tooltip
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/styles'
import NumberFormat from 'react-number-format'
import {
    DeleteSweep
} from '@material-ui/icons'
import PerfectScrollbar from '@opuscapita/react-perfect-scrollbar'
// import RefreshIcon from '@material-ui/icons/RefreshRounded';

// Components to Props
import PaymentMethodOptions from './PaymentMethodOptions'

// Redux
import { connect } from 'react-redux'
import { getCart, deleteCartItem, deleteCartAllItem, addToCart } from '../../../actions/cart'
import {useHistory} from "react-router-dom";
import CartItem from "./CartItem";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            borderRadius: theme.spacing(4)
        },
        width: '100%'
    },
    cardNotch: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: '7px',
        borderRadius: '10px',
        border: 'none',
        width: '50%',
        margin: 'auto',
        backgroundColor: '#EEEEEE'
    },
    contentItems: {
        padding: theme.spacing(1),
        width: '100%',
        height: '200px',
        [theme.breakpoints.up('md')]: {
            height: '533px',
        },
    },
    contentItemsPayment: {
        padding: theme.spacing(1),
    },
    btnPayment: {
        backgroundColor: '#F99800',
        '&:hover': {
            backgroundColor: "#F99800",
            color: '#FFFFFF'
         },
        // maxWidth: '150px',
        width: '100%',
        fontSize: '12px',
        color: '#FFFFFF'
    },
    // btnDeleteAll: {
    //     fontSize: '12px'
    // },
    itemName: {
        padding: '11px',
    },
    btnDeleteAll: {
        color: theme.palette.error.main
    },
    inputComponent: {
        height: '30px',
        width:  '100%',
        border: '1px solid #D3D4D0',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 0 0 rgba(170,170,170,0.01)',
    },
    inputText: {
        color: 'rgba(0,0,0,0.87)',
        fontSize: '16px',
        letterSpacing: '0.5px',
        lineHeight: '28px',
        textAlign: 'center',
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        width: '100%'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.main,
            color: '#FFFFFF'
        },
        color: '#FFFFFF',
        height: '30px',
        borderRadius: "0%"
    },
    divider: {
        height: 28,
        margin: 4,
    }
}))

const Cart = (props) => {
    const classes = useStyles()
    const { 
        getCart, 
        cart : { 
            carts, 
            cart,
            // delCart,
            // delAllCart,
            loading, 
            counting,
            loadingAddCart,
            // loadingDeleteItemCart,
            // loadingDeleteAllItemCart,
        }, 
        deleteCartItem, 
        deleteCartAllItem,
        addToCart,
        date,
        searchCustomerClear,
    } = props
    const [ modalPaymentOpen, setModalPaymentOpen ] = useState(false)

    const history = useHistory()
    
    const handleDrawerPaymentOpen = () => {
        setModalPaymentOpen(true)
    }

    const handleDrawerPaymentClose = () => {
        setModalPaymentOpen(false)
    }

    const onDeleteItem = (e) => {
        deleteCartItem(e)
    }

    const onDeleteAllItem = () => {
        deleteCartAllItem()
    }

    const addCart = (item, qty) => {
        let qty_product = item.qty + qty;
        return addToCart(item.product.id, searchCustomerClear.status, qty_product, date, history)
    }

    const addCartValue = (item, qty) => {
        return addToCart(item.product.id, searchCustomerClear.status, qty, date, history)
    }

    useEffect(() => {
        getCart()
    }, [getCart, counting, date, searchCustomerClear])
    
    return (
        <Fragment>
            <Card className={classes.root}>
                <Hidden only={['md','lg','xl']}>
                    <hr className={classes.cardNotch} />
                </Hidden>
                <CardHeader
                    title="Rincian Pesanan"
                    action={
                        <>
                            {/*<IconButton onClick={getCart}>*/}
                            {/*    <Tooltip title="Refresh">*/}
                            {/*        <RefreshIcon/>*/}
                            {/*    </Tooltip>*/}
                            {/*</IconButton>*/}
                            <IconButton onClick={onDeleteAllItem}>
                                <Tooltip title={"Kosongkan Semua"}>
                                    <DeleteSweep className={classes.btnDeleteAll}/>
                                </Tooltip>
                            </IconButton>
                        </>
                    }
                />
                <CardContent>
                    <PerfectScrollbar>
                        <div className={classes.contentItems}>
                            {loading || carts === null ? (
                                <>
                                <Skeleton></Skeleton>
                                <Skeleton></Skeleton>
                                <Skeleton></Skeleton>
                                </>
                            ):(
                                <>
                                {carts.cart.map((item) => (
                                  <CartItem
                                    item={item}
                                    classes={classes}
                                    loadingAddCart={loadingAddCart}
                                    cart={cart}
                                    deleted={(items) => onDeleteItem(items)}
                                    added={(items, qty) => addCart(items, qty)}
                                    addCartValue={(items, qty) => addCartValue(items, qty)}
                                  />
                                ))}
                                </>
                            )}
                        </div>
                    </PerfectScrollbar>
                    <Divider />
                    <div className={classes.contentItemsPayment}>
                        <Grid
                            container
                            spacing={2}
                            justify="space-between"
                        >
                            <Grid item>
                                <Typography variant="body">Total Pembayaran</Typography>
                            </Grid>
                            <Grid item>
                                {loading || carts === null ? (
                                    <Skeleton></Skeleton>
                                ):(
                                    <Typography variant="body">
                                        <NumberFormat value={carts.total_payment} displayType={'text'} thousandSeparator={true} prefix={`RP `} />
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.contentItemsPayment}>
                        {loading || carts === null ? (
                            <Skeleton></Skeleton>
                        ):(
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    {carts.cart.length > 0 ? (
                                        <Button fullWidth variant="contained" onClick={handleDrawerPaymentOpen} className={classes.btnPayment}>
                                            Bayar
                                        </Button>
                                    ):(
                                        <Button disabled fullWidth variant="contained" onClick={handleDrawerPaymentOpen} className={classes.btnPayment}>
                                            Bayar
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {/*<Grid*/}
                        {/*    container*/}
                        {/*    spacing={2}*/}
                        {/*>*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <Button*/}
                        {/*            fullWidth*/}
                        {/*            variant="outlined"*/}
                        {/*            className={classes.btnDeleteAll}*/}
                        {/*            startIcon={<Delete />}*/}
                        {/*            onClick={onDeleteAllItem}*/}
                        {/*        >*/}
                        {/*            Kosongkan*/}
                        {/*        </Button>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </div>
                </CardContent>
                <SwipeableDrawer
                    anchor='bottom'
                    open={modalPaymentOpen}
                    onClose={handleDrawerPaymentClose}
                    onOpen={handleDrawerPaymentOpen}
                    disableSwipeToOpen
                >
                    <PaymentMethodOptions handleDrawerPaymentClose={handleDrawerPaymentClose} date={date} />
                </SwipeableDrawer>
            </Card>
        </Fragment>
    )
}

const mapStateToProps = state => ({
	cart: state.cart
})

export default connect(mapStateToProps, { getCart, deleteCartItem, deleteCartAllItem, addToCart })(Cart)