import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardContent, FormControl, InputLabel, Select, Button,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import * as actions from '../../actions';

// Components
import ListProduct from './ListProduct'

import { connect } from 'react-redux'
import { useEffect } from 'react'
import {isEmpty} from "../../shared/utility";
import {Loading} from "../../components/UI";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CalendarToday} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btnAddCustomer: {
        backgroundColor: '#0277BD',
        '&:hover' : {
            backgroundColor: '#2092D6'
        },
        color: '#FFFFFF',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    searchRoot: {
		padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
        marginLeft: theme.spacing(1),
            flex: 1,
        },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
    },
  paperContent: {
    padding: theme.spacing(4)
  },
  paperRules: {
    padding: theme.spacing(2),
    backgroundColor: '#FFF9F0'
  },
  paperPadding: {
    marginBottom: theme.spacing(2)
  },
  buttonWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
    textTransform: 'none'
  }
}))

const StockHistory = props => {
    const classes = useStyles()
    const {
      onFetchStockHistories,
      stockHistories,
      loading,
      onDownloadStockHistories
    } = props;

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  console.log('history',rowsPerPage);
    const [ startDate, setStartDate ] = useState({
      startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    });
    const handleStartDate = (input) => {
      setStartDate(startDate => ({
        ...startDate,
        startDate: input
      }));
      setFormSearch(() => ({
        ...formSearch,
        startDate: moment(input).format('YYYY-MM-DD')
      }));
    }

  const [ endDate, setEndDate ] = useState({
    endDate: new Date(),
  });

  const handleEndDate = (input) => {
    setEndDate(endDate => ({
      ...endDate,
      endDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      endDate: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  const [formSearch, setFormSearch] = useState({
    type: '',
    startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
  });

    useEffect(() => {
      const timer = setTimeout(() => {
        onFetchStockHistories(1, formSearch,rowsPerPage)
      }, 1000)

      return () => clearTimeout(timer)
    }, [onFetchStockHistories, formSearch,rowsPerPage])
  //
  const handleChangePage = (page) => {
    onFetchStockHistories(page, formSearch,rowsPerPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event);
    onFetchStockHistories(1, formSearch,event)
  };

  const handleSearch = event => {
    const target = event.target.name;
    event.persist();
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }));
  };

  const download = () => {
    onDownloadStockHistories(formSearch);
  }

    return(loading ? <Loading/> :
        <div className={classes.root}>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item>  
                        <Typography variant="h4">Stock History</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
              <Paper style={{padding: '10px'}}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TextField
                          onClick={() => setIsOpen(true)}
                          value={moment(startDate.startDate).format('dddd DD MMMM YYYY')}
                          fullWidth
                          className={classes.margin}
                          label="Tanggal Awal"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" disablePointerEvents>
                                <CalendarToday />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <DatePicker
                          fullWidth
                          open={isOpen}
                          onOpen={() => setIsOpen(true)}
                          onClose={() => setIsOpen(false)}
                          disableFuture
                          // minDate={minDate}
                          variant="outlined"
                          name="start_date"
                          format="dd MMMM yyyy HH:mm"
                          value={startDate.startDate}
                          onChange={e => handleStartDate(e, 'startDate')}
                          TextFieldComponent={() => null}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TextField
                          onClick={() => setIsOpenEnd(true)}
                          value={moment(endDate.endDate).format('dddd DD MMMM YYYY')}
                          fullWidth
                          className={classes.margin}
                          label="Tanggal Akhir"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end" disablePointerEvents>
                                <CalendarToday />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <DatePicker
                          fullWidth
                          open={isOpenEnd}
                          onOpen={() => setIsOpenEnd(true)}
                          onClose={() => setIsOpenEnd(false)}
                          disableFuture
                          // minDate={minDate}
                          variant="outlined"
                          name="end_date"
                          format="dd MMMM yyyy HH:mm"
                          value={endDate.endDate}
                          onChange={e => handleEndDate(e, 'endDate')}
                          TextFieldComponent={() => null}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  <Grid item xl={3} md={3} sm={12} xs={12}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel shrink htmlFor="age-native-label-placeholder">Tipe Produk</InputLabel>

                      <Select
                        fullWidth
                        native
                        defaultValue={formSearch.type}
                        onChange={handleSearch}
                        label="Tipe Produk"
                        inputProps={{
                          name: 'type',
                          id: 'age-native-label-placeholder'
                        }}
                        name="type"
                        // onChange={handleChange}
                      >
                        <option value={''}>Semua</option>
                        <option value={'gold'}>Gold</option>
                        <option value={'other'}>Other</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </div>
            <div className={classes.row}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <div>
                        Jumlah Produk
                      </div>
                      <div>
                        {!isEmpty(stockHistories) && stockHistories.total_item}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <div>
                        Penambahan Stok
                      </div>
                      <div>
                        {!isEmpty(stockHistories.total) && stockHistories.total.penambahan}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <div>
                        Pengurangan Stok
                      </div>
                      <div>
                        {!isEmpty(stockHistories.total) && stockHistories.total.pengurangan}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                >
                  <Card>
                    <CardContent>
                      <div>
                        Stok Akhir
                      </div>
                      <div>
                        {!isEmpty(stockHistories.total) && stockHistories.total.stock_akhir}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div className={classes.row}>
              <div className={classes.row}>
                <Paper className={classes.paperContent}>

                  <div className={classes.paperPadding}>
                    <Grid container justify={"space-between"}>
                      <Grid item>
                        <Typography className={classes.tagMenu} variant={"h5"}>Stock History</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={"contained"}
                          size={"medium"}
                          className={classes.buttonWarning}
                          onClick={() => download()}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  {!isEmpty(stockHistories) && (
                    // <Skeleton variant="rect" height={100}></Skeleton>
                    <ListProduct stockHistory={stockHistories} rowsPerPage={rowsPerPage} handleChangePageProps={(page) => handleChangePage(page)} handleChangeRowsPerPageProps={(rows) => handleChangeRowsPerPage(rows)}/>
                  )}
                </Paper>
              </div>
                {/*<Grid*/}
                {/*    container*/}
                {/*    spacing={2}*/}
                {/*>*/}
                {/*    <Grid */}
                {/*        item*/}
                {/*        lg={12}*/}
                {/*        md={12}*/}
                {/*        sm={12}*/}
                {/*        xs={12}*/}
                {/*    >*/}
                {/*        <Card>*/}
                {/*            <CardContent>*/}
                {/*                {!isEmpty(stockHistories) && (*/}
                {/*                  // <Skeleton variant="rect" height={100}></Skeleton>*/}
                {/*                  <ListProduct stockHistory={stockHistories} rowsPerPage={rowsPerPage} handleChangePageProps={(page) => handleChangePage(page)} handleChangeRowsPerPageProps={(rows) => handleChangeRowsPerPage(rows)}/>*/}
                {/*                )}*/}
                {/*            </CardContent>*/}
                {/*        </Card>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
  return {
    stockHistories: state.stockHistory.stockHistories,
    loading: state.stockHistory.loadingV2,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchStockHistories: (page, formState,page_size) => dispatch(actions.fetchStockHistories(page, formState,page_size)),
    onDownloadStockHistories: (formState) => dispatch(actions.downloadStockHistories(formState)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockHistory)