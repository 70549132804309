import React, {Fragment} from "react";
import Countdown from 'react-countdown';
import {Box, Grid, Paper, Typography} from "@material-ui/core";
import {Redirect} from 'react-router';
import * as actions from '../../../../actions';
import {connect} from "react-redux";
import moment from "moment";

const CountDownPO = props => {
  const {classes,onAlert, round} = props;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Fragment>
          {onAlert('Sesi Ronde Telah Berakhir','error')}
          <Redirect to={'/purchase-order-v2'}/>
        </Fragment>
      );
    } else {
      // Render a countdown
      return (
        <Fragment>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent={"flex-start"}>
            <Box style={{marginRight:15}}>
              Hitung mundur pembelian dibuka
            </Box>

            <Box p={1} className={classes.roundTimer}>
              {hours.toLocaleString('id', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </Box>
            :
            <Box p={1} className={classes.roundTimer}>
              {minutes.toLocaleString('id', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </Box>
            :
            <Box p={1} className={classes.roundTimer}>
              {seconds.toLocaleString('id', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </Box>
          </Box>
        </Fragment>
      );
    }
  };

  let day = moment(round.close).toDate();
  let dayOpen = moment(round.open).toDate();

  let now = new Date();
  if (now < dayOpen){
    return (
      <Fragment>
        {onAlert('Sesi Ronde Belum Dimulai','error')}
        <Redirect to={'/purchase-order-v2'}/>
      </Fragment>
    );
  }
  console.log(round);
  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <Grid container spacing={2} justify={"space-between"}>
          <Grid item>
            <Typography variant={"h5"} >
              {round.name}
            </Typography>
            <Typography variant={"h6"} className={classes.roundText}>
              Pukul {moment.tz(round.open, 'Asia/Jakarta').format('HH:mm')} s.d {moment.tz(round.close, 'Asia/Jakarta').format('HH:mm z')}
            </Typography>
          </Grid>
          <Grid item>
            <Countdown
              date={day}
              renderer={renderer}
            />
          </Grid>
        </Grid>
      </Paper>

    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onAlert: (msg, alertType) => dispatch(actions.setAlert(msg,alertType))
  }
}

export default connect(null, mapDispatchToProps)(CountDownPO);