import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchBeginningStockStart = () => {
  return {
    type: actionTypes.FETCH_BEGINNING_STOCK_START
  }
}

export const fetchBeginningStockSuccess = (beginning_stock, statusCreate) => {
  return {
    type: actionTypes.FETCH_BEGINNING_STOCK_SUCCESS,
    beginning_stock: {
      beginning_stock,
    },
    statusCreate: statusCreate
  }
}

export const fetchBeginningStockFail = (error) => {
  return {
    type: actionTypes.FETCH_BEGINNING_STOCK_FAIL,
    error: error
  }
}

export const fetchBeginningStock = (page) => {
  return dispatch => {
    dispatch(fetchBeginningStockStart())
    axios.get(`user/first_balance/history?page=${page}&page_size=10`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchBeginningStockSuccess(res.data.data, res.data.status_create))
      })
      .catch(err => {
        dispatch(fetchBeginningStockFail(err.response.data.message))
        dispatch(setAlert("something went wrong", "error"))
      })
  }
}

export const storeBeginningStockStart = () => {
  return {
    type: actionTypes.STORE_BEGINNING_STOCK_START
  }
}

export const storeBeginningStockSuccess = () => {
  return {
    type: actionTypes.STORE_BEGINNING_STOCK_SUCCESS,
  }
}

export const storeBeginningStockFail = (error) => {
  return {
    type: actionTypes.STORE_BEGINNING_STOCK_FAIL,
    error: error
  }
}

export const storeBeginningStock = (storeData,history) => {
  return dispatch => {
    dispatch(storeBeginningStockStart())
    axios.post(`user/first_balance/history`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(storeBeginningStockSuccess(res.data.message))
        dispatch(setAlert(res.data.message, "success"))
        history.push('/beginning-stock')
      })
      .catch(err => {
        dispatch(storeBeginningStockFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const getBeginningStockStart = () => {
  return {
    type: actionTypes.GET_BEGINNING_STOCK_START
  }
}

export const getBeginningStockSuccess = (beginning_stock_detail, status) => {
  return {
    type: actionTypes.GET_BEGINNING_STOCK_SUCCESS,
    beginning_stock_detail: beginning_stock_detail,
    status: status
  }
}

export const getBeginningStockFail = (error) => {
  return {
    type: actionTypes.GET_BEGINNING_STOCK_FAIL,
    error: error
  }
}

export const getBeginningStock = (id) => {
  return dispatch => {
    dispatch(getBeginningStockStart())
    axios.get(`user/first_balance/history/${id}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(getBeginningStockSuccess(res.data.data, res.data.status))
      })
      .catch(err => {
        dispatch(getBeginningStockFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const updateBeginningStockStart = () => {
  return {
    type: actionTypes.UPDATE_BEGINNING_STOCK_START
  }
}

export const updateBeginningStockSuccess = () => {
  return {
    type: actionTypes.UPDATE_BEGINNING_STOCK_SUCCESS,
  }
}

export const updateBeginningStockFail = (error) => {
  return {
    type: actionTypes.UPDATE_BEGINNING_STOCK_FAIL,
    error: error
  }
}

export const updateBeginningStock = (id,updateData,history) => {
  return dispatch => {
    dispatch(updateBeginningStockStart())
    axios.put(`user/first_balance/history/${id}`, updateData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(updateBeginningStockSuccess(res.data.message))
        dispatch(setAlert(res.data.message, "success"))
        history.push('/beginning-stock')
      })
      .catch(err => {
        dispatch(updateBeginningStockFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}