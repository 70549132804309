/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { AccountImage } from './components';
import Skeleton from '@material-ui/lab/Skeleton';

import { connect } from 'react-redux';
import { editProfile } from '../../actions/profile';
import userService from '../../services/user.service';
import bankService from '../../services/bank.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Profile = (props) => {
  const {
    profile: { loading },
  } = props;
  const classes = useStyles();
  const { register, handleSubmit, control } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [detailProfile, setDetailProfile] = useState();
  const [dataBank, setDataBank] = useState([]);

  const handleChangeBanner = async (event) => {
    const formData = new FormData();
    formData.append('image', event[0]);

    try {
      await userService.changePicture(formData);
    } catch (error) {
      console.error(error);
    }
  };

  const getDetailProfile = async () => {
    setIsLoading(true);
    try {
      const response = await userService.getProfile();
      setDetailProfile(response);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const getMasterBank = async () => {
    try {
      const response = await bankService.bankMaster({per_page: 9999});
      setDataBank(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async (e) => {
    setLoadingUpdate(true);
    try {
      await userService.putProfile(e);
      setLoadingUpdate(false);
    } catch (e) {
      setLoadingUpdate(false);
      console.error(e);
    }
  };

  useEffect(() => {
    getDetailProfile();
    getMasterBank();
  }, []);

  return isLoading || loading ? (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress color='inherit' />
    </Backdrop>
  ) : (
    <Fragment>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <AccountImage
                detailProfile={detailProfile?.profile}
                handleChangeBanner={handleChangeBanner}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Card>
                {!isLoading ? (
                  <CardContent>
                    <TextField
                      fullWidth
                      name='name'
                      label='Nama Akun'
                      variant='outlined'
                      defaultValue={detailProfile?.profile?.user_name || ''}
                      inputRef={register}
                    />
                  </CardContent>
                ) : (
                  <Skeleton variant='rect'></Skeleton>
                )}
              </Card>

              <Card>
                <CardHeader title='Akun Bank EGS' />
                <CardContent>
                  <Controller
                    name='id_bank'
                    control={control}
                    defaultValue={detailProfile?.bank?.bank_id}
                    render={({ value, onChange }) => (
                      <FormControl
                        className={classes.formControl}
                        fullWidth
                        variant='outlined'
                      >
                        <InputLabel>Nama Bank</InputLabel>
                        <Select
                          fullWidth
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          {dataBank.map((item) => (
                            <MenuItem id={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <TextField
                    margin='normal'
                    fullWidth
                    variant='outlined'
                    label='Nomor Rekening'
                    name='account_number'
                    defaultValue={
                      detailProfile?.bank?.branch_bank_account_number || ''
                    }
                    inputRef={register}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    variant='outlined'
                    label='Atas Nama'
                    name='account_name'
                    defaultValue={
                      detailProfile?.bank?.branch_bank_account_name || ''
                    }
                    inputRef={register}
                  />
                </CardContent>
              </Card>

              <Card>
                <CardHeader title='Alamat EGS' />
                <CardContent>
                  <TextField
                    margin='normal'
                    fullWidth
                    variant='outlined'
                    label='Nomor Telepon'
                    name='branch_msisdn'
                    defaultValue={detailProfile?.profile?.branch_msisdn || ''}
                    inputRef={register}
                  />
                  <TextField
                    margin='normal'
                    fullWidth
                    variant='outlined'
                    label='Alamat'
                    name='branch_address'
                    defaultValue={detailProfile?.profile?.branch_address || ''}
                    inputRef={register}
                  />
                </CardContent>
                <CardActions>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    fullWidth
                    disabled={loadingUpdate}
                  >
                    {loadingUpdate ? <CircularProgress size={24} color="secondary" /> : 'Simpan'}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { editProfile })(Profile);
