import { 
    GET_CART, 
    ADD_TO_CART, 
    DELETE_CART_ITEM, 
    DELETE_CART_ALL_ITEM, 
    ADD_TO_CART_START,
    GET_CART_START,
    DELETE_CART_ITEM_START,
    DELETE_CART_ALL_ITEM_START,
    FIRST_ADD_TO_CART_START,
    FIRST_ADD_TO_CART_SUCCESS,
    FIRST_ADD_TO_CART_FAIL
} from '../actions/types'
import {updateObject} from "../shared/utility";

const initialState = {
    cart: null,
    carts: null,
    delCart: null,
    delAllCart: null,
    loading: false,
    loadingAddCart: false,
    loadingDeleteItemCart: false,
    loadingDeleteAllItemCart: false,
    error: {},
    counting : 0
}

const firstAddToCartStart = (state, action) => {
    return updateObject(state, {
        loadingAddCart: false,
    })
}

const firstAddToCartSuccess = (state, action) => {
    return updateObject(state, {
        loadingAddCart: false,
        counting : state.counting += 1
    })
}

const firstAddToCartFail = (state, action) => {
    return updateObject(state, { error: action.error, loadingAddCart: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_CART_START:
            return {
                ...state,
                loading: true,
            }
        case GET_CART:
            return {
                ...state,
                carts: payload,
                loading: false,
            }
        case ADD_TO_CART_START:
            return {
                ...state,
                loadingAddCart: true,
            }
        case ADD_TO_CART:
            return {
                ...state,
                cart: payload,
                loadingAddCart: false,
                counting : initialState.counting += 1
            }
        case DELETE_CART_ITEM_START:
            return {
                ...state,
                loadingDeleteItemCart: true,
            }
        case DELETE_CART_ITEM:
            return {
                ...state,
                delCart: payload,
                loadingDeleteItemCart: false,
                counting : initialState.counting -= 1
            }
        case DELETE_CART_ALL_ITEM_START:
            return {
                ...state,
                loadingDeleteAllItemCart: true,
            }
        case DELETE_CART_ALL_ITEM:
            return {
                ...state,
                delAllCart: payload,
                loadingDeleteAllItemCart: false,
                counting : initialState.counting = 0
            }
        case FIRST_ADD_TO_CART_START: return firstAddToCartStart(state, action)
        case FIRST_ADD_TO_CART_SUCCESS: return firstAddToCartSuccess(state, action)
        case FIRST_ADD_TO_CART_FAIL: return firstAddToCartFail(state, action)
        default:
            return state
    }
}

export default reducer;