import React, { Fragment } from 'react';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import ListOrder from '../ListOrder/ListOrder';
import moment from 'moment';

const columns = [
  { id: 'no', label: 'No', minWidth: 100 },
  { id: 'product_name', label: 'Nama Product', minWidth: 100 },
  { id: 'quantity', label: 'Quantity', minWidth: 100 },
  { id: 'price', label: 'Harga Satuan', minWidth: 100 },
  { id: 'stock', label: 'Stok', minWidth: 100 },
  { id: 'total', label: 'Total', minWidth: 100 },
];

const ListOrders = ({ classes, purchaseOrder }) => {
  const menunggu_konfirmasi = (
    <Chip
      label='Menunggu Konfirmasi'
      className={classes.chipWarning}
      size={'medium'}
    />
  );
  const sampai = (
    <Chip label='Sampai' className={classes.chipSuccess} size={'medium'} />
  );
  const dikirim = (
    <Chip label='Dikirim' className={classes.chipPrimary} size={'medium'} />
  );
  const pending = (
    <Chip label='Pending' className={classes.chipWarning} size={'medium'} />
  );
  const diproses = (
    <Chip label='Diproses' className={classes.chipSecondary} size={'medium'} />
  );
  const ditolak = (
    <Chip label='Ditolak' className={classes.chipError} size={'medium'} />
  );
  const expired = <Chip label='Expired' size={'medium'} />;
  const new_invoice = (
    <Chip label='New Invoice' className={classes.chipWarning} size={'medium'} />
  );

  return (
    <Fragment>
      <div>
        <Box mb={2}>
          <Typography style={{ fontWeight: 'bold' }} variant='h5'>
            Detail Invoice
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>No Invoice</Box>
                <Box width={'75%'}>
                  :{` `}
                  {purchaseOrder?.invoice.invoice_id}
                  <CopyToClipboard text={purchaseOrder?.invoice.invoice_id}>
                    <Tooltip title='copy to clipboard' placement={'right'}>
                      <IconButton
                        className={classes.zeroPadding}
                        color={'primary'}
                      >
                        <FileCopyIcon fontSize={'small'} />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </Box>
              </Box>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>Tanggal</Box>
                <Box width={'75%'}>
                  :{` `}
                  {moment(purchaseOrder?.invoice.created_at).format(
                    'dddd, DD MMMM YYYY, HH:mm:ss'
                  ) + ' WIB'}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>Status</Box>
                <Box width={'75%'}>
                  :{` `}
                  {purchaseOrder?.invoice?.status === 1
                    ? menunggu_konfirmasi
                    : purchaseOrder?.invoice?.status === 4
                    ? sampai
                    : purchaseOrder?.invoice?.status === 3
                    ? dikirim
                    : purchaseOrder?.invoice?.status === 0
                    ? pending
                    : purchaseOrder?.invoice?.status === 2
                    ? diproses
                    : purchaseOrder?.invoice?.status === 99
                    ? ditolak
                    : purchaseOrder?.invoice?.status === 98
                    ? expired
                    : purchaseOrder?.invoice?.status === 'New Invoice'
                    ? new_invoice
                    : ''}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Typography style={{ fontWeight: 'bold' }} variant='h5'>
            Pengirim
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>Nama</Box>
                <Box width={'75%'}>
                  {`: ${purchaseOrder?.invoice.sender_name}`}
                </Box>
              </Box>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>No. Telepon</Box>
                <Box width={'75%'}>
                  {`: ${purchaseOrder?.invoice.sender_msisdn}`}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>Alamat</Box>
                <Box width={'75%'}>
                  {`: ${
                    purchaseOrder?.invoice.sender_address ||
                    `${purchaseOrder?.invoice.sender_residence_address}, ${purchaseOrder?.invoice.sender_residence_village}, ${purchaseOrder?.invoice.sender_residence_district}, ${purchaseOrder?.invoice.sender_residence_regency}, ${purchaseOrder?.invoice.sender_residence_province} - ${purchaseOrder?.invoice.sender_residence_post_code}`
                  }`}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Typography style={{ fontWeight: 'bold' }} variant='h5'>
            Penerima
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>Nama</Box>
                <Box
                  width={'75%'}
                >{`: ${purchaseOrder?.invoice.shipment_name}`}</Box>
              </Box>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>No. Telepon</Box>
                <Box
                  width={'75%'}
                >{`: ${purchaseOrder?.invoice.shipment_msisdn}`}</Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection='row' m={1}>
                <Box width={'25%'}>Alamat</Box>
                <Box width={'75%'}>
                  {`: ${purchaseOrder?.invoice.shipment_address}, ${purchaseOrder?.invoice.shipment_village}, ${purchaseOrder?.invoice.shipment_district}, ${purchaseOrder?.invoice.shipment_regency}, ${purchaseOrder?.invoice.shipment_province} - ${purchaseOrder?.invoice.shipment_post_code}`}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className={classes.row}>
        <div className={classes.tagMenu}>Paket Upgrade</div>
      </div>

      <div className={classes.row}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label='sticky table'
            style={{ minWidth: '340px' }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseOrder?.detail.map((data, index) => (
                <ListOrder
                  data={data}
                  key={data.id}
                  index={index}
                  classes={classes}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

export default ListOrders;
