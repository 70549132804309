import React, { Fragment, useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Loading } from '../../../../../components/UI';
import CustomersData from './CustomersData/CustomersData';
import { isEmpty } from '../../../../../shared/utility';
import { connect } from 'react-redux';

const schema = yup.object().shape({
  keyword: yup.string().required(),
  type: yup.string().required(),
});

const ModalSearchCustomer = (props) => {
  const {
    classes,
    loadingCustomer,
    customers,
    onFetchCustomer,
    getSearchCustomerAndClear,
    closedModalDialog,
    setSelectedUser
  } = props;

  const [keyword, setKeyword] = useState({
    keyword: '',
    type: 'name',
  });

  const handleChangeSearch = (e) => {
    e.persist();
    setKeyword((keyword) => ({
      ...keyword,
      [e.target.name]: e.target.value,
    }));
    // setPage(0)
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchCustomer(keyword.type, keyword.keyword, 5, 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [onFetchCustomer, keyword]);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    onFetchCustomer(data.type, data.keyword, 5, 1);
  };

  const handleChangePage = (page) => {
    onFetchCustomer(keyword.type, keyword.keyword, 5, page);
  };

  const handleSelectChange = (customer) => {
    getSearchCustomerAndClear('id', customer.id);
    closedModalDialog();
  };

  let customersData = !isEmpty(customers) && (
    <CustomersData
      classes={classes}
      customers={customers}
      handleChangePageProps={(page) => handleChangePage(page)}
      handleSelectChangeProps={(customer) => handleSelectChange(customer)}
      setSelectedUser={setSelectedUser}
    />
  );

  return loadingCustomer ? (
    <Loading />
  ) : (
    <Fragment>
      {/*{loading}*/}
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography>Cari Customer</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.row}>
              <Paper component='form' className={classes.searchRoot}>
                <IconButton
                  type='submit'
                  className={classes.iconButton}
                  aria-label='search'
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation='vertical' />
                <Grid container spacing={2}>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <TextField
                      autoFocus
                      label='keyword'
                      // variant="outlined"
                      fullWidth
                      name='keyword'
                      defaultValue={keyword.keyword || ''}
                      onChange={(e) => handleChangeSearch(e)}
                      inputRef={register}
                      error={errors.keyword ? true : false}
                      helperText={errors.keyword && errors.keyword.message}
                    />
                    {/*<Divider className={classes.divider} orientation="vertical" />*/}
                  </Grid>
                  <Grid item xl={6} md={6} sm={12} xs={12}>
                    <FormControl
                      error={errors.type ? true : false}
                      variant='outlined'
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel htmlFor='outlined-age-native-simple'>
                        Tipe Pencarian
                      </InputLabel>
                      <Select
                        native
                        defaultValue={keyword.type || ''}
                        onChange={(e) => handleChangeSearch(e)}
                        label='Tipe Pencarian'
                        inputProps={{
                          name: 'type',
                          // id: 'outlined-age-native-simple'
                        }}
                        name='type'
                        inputRef={register}
                      >
                        <option key='name' value='name'>
                          Nama
                        </option>
                        <option key='id_agent' value='id_agent'>
                          ID Agent
                        </option>
                      </Select>
                      <FormHelperText>
                        {errors.type && errors.type.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </form>
        </Grid>

        {customersData}
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingCustomer: state.customer.loadingCustomerV2,
    customers: state.customer.customers_v2,
  };
};

export default connect(mapStateToProps)(ModalSearchCustomer);
