import React,{Fragment} from "react";
import {isEmpty} from "../../../../../shared/utility";
import {Grid, Typography} from "@material-ui/core";

const UserData = props => {
  const {
    searchCustomerClear,
    classes
  } = props;

  let userData = (
    (!isEmpty(searchCustomerClear)) ?
      (
        <Fragment>
          {searchCustomerClear.map((item) => (
              <>
                {/*<Grid item lg={5} md={5} xs={12} sm={12}>*/}
                  <div className={classes.root}>
                    <Typography style={{marginBottom: '8px'}}> Data Customer</Typography>
                    <Grid container spacing={2} style={{marginBottom: '8px'}}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Grid container justify={"space-between"}>
                          <Grid item>
                            <Typography>Nama</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>:</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        {item.name}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginBottom: '8px'}}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Grid container justify={"space-between"}>
                          <Grid item>
                            <Typography>Tipe Customer</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>:</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        {item.name_status}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginBottom: '8px'}}>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Grid container justify={"space-between"}>
                          <Grid item>
                            <Typography>No ID</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>:</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        {item.id_agent}
                      </Grid>
                    </Grid>
                  </div>
                {/*</Grid>*/}
              </>
            )
          )}
        </Fragment>
      )
      : ''
  );


  return (
    <Fragment>
      {userData}
    </Fragment>
  );
};

export default UserData;