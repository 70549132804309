import React, { Fragment, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Grid,
  Button,
  TextField, IconButton, Divider, useTheme, useMediaQuery, FormHelperText, FormControl
} from "@material-ui/core";
import NumberFormat from "react-number-format";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from 'react-router-dom'

import * as actions from '../../../actions';
import { connect } from "react-redux";

const schema = yup.object().shape({
  input_price: yup.number().required(),
  shipping_cost: yup.number().required(),
});

const CartDetail = props => {
  const { cartQty, searchCustomerClear, date, classes, onStorePaymentCart, loading } = props;
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const [formState, setFormState] = useState({
    input_price: 0,
    shipping_cost: 0
  });

  const handleChange = event => {
    // event.presist()
    setFormState(formState => ({
      ...formState,
      [event.target.name]: (isNaN(event.target.value) ? 0 : event.target.value)
    }));
  };

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  // console.log(isNaN(formState.shipping_cost) ? 0 : formState.shipping_cost )

  let jumlah_produk = cartQty.map(item => parseInt(item.qty)).reduce((prev, next) => prev + next);
  let total_harga_produk = cartQty.map(item => parseInt(item.sub_total)).reduce((prev, next) => prev + next);
  const total_harus_bayar = total_harga_produk + (isNaN(formState.shipping_cost) ? 0 : parseInt(formState.shipping_cost));
  const kembalian = (isNaN(formState.input_price) ? 0 : parseInt(formState.input_price)) - (total_harus_bayar);

  const onSubmit = () => {
    let data = {}
    data.id_customer = searchCustomerClear.id;
    data.input_price = formState.input_price;
    data.total_price = total_harga_produk;
    data.shipping_cost = formState.shipping_cost;
    data.products = cartQty.filter(({ qty }) => qty > 0);
    data.date_time = date;
    // console.log(data)
    onStorePaymentCart(data, history)
  }

  // console.log(formState)

  return (
    <Fragment>
      <div className={classes.row}>
        <Paper className={classes.root}>
          <div className={classes.paperPadding}>
            <Typography className={classes.tagMenu} variant={"h5"}>Harga Total</Typography>
          </div>

          <div>
            <form onSubmit={handleSubmit(handleClickOpen)}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell width={"35%"}>
                        Jumlah Produk
                      </TableCell>
                      <TableCell width={"65%"}>
                        <NumberFormat style={{ fontWeight: "bold" }} value={jumlah_produk} displayType={'text'} thousandSeparator={true} suffix={" pcs"} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Total Harga
                      </TableCell>
                      <TableCell>
                        <NumberFormat style={{ fontWeight: "bold" }} value={total_harga_produk} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Ongkos Kirim
                      </TableCell>
                      <TableCell>
                        <FormControl
                          error={errors.shipping_cost ? true : false}
                          variant="outlined" className={classes.formControl} fullWidth>
                          <Paper className={classes.searchRoot}>
                            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                              <Typography variant="subtitle2">Rp</Typography>
                            </IconButton>
                            <Divider className={classes.divider} orientation="vertical" />
                            <NumberFormat
                              style={{ padding: '0px 4px' }}
                              {...props}
                              // allowLeadingZeros={false}
                              name="shipping_cost"
                              customInput={TextField}
                              type="text"
                              thousandSeparator
                              getInputRef={register}
                              defaultValue={formState.shipping_cost || ''}
                              placeholder={0}
                              onValueChange={({ value: v }) => handleChange({ target: { name: 'shipping_cost', value: parseInt(v) } })}
                            />
                            <TextField
                              type={"hidden"}
                              name="shipping_cost"
                              inputRef={register}
                              value={formState.input_price}
                            />
                          </Paper>
                          <FormHelperText>{errors.shipping_cost && errors.shipping_cost.message}</FormHelperText>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Total Harus Bayar
                      </TableCell>
                      <TableCell>
                        <NumberFormat style={{ fontWeight: "bold" }} value={total_harus_bayar} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Tunai
                      </TableCell>
                      <TableCell>
                        <FormControl
                          error={errors.input_price ? true : false}
                          variant="outlined" className={classes.formControl} fullWidth
                        >
                          <Paper className={classes.searchRoot}>
                            <IconButton type="submit" className={classes.iconButton} aria-label="search">
                              <Typography variant="subtitle2">Rp</Typography>
                            </IconButton>
                            <Divider className={classes.divider} orientation="vertical" />
                            <NumberFormat
                              style={{ padding: '0px 4px' }}
                              {...props}
                              // allowLeadingZeros={false}
                              // isNumericString={true}
                              defaultValue={formState.input_price || ''}
                              name="input_price"
                              customInput={TextField}
                              type="text"
                              thousandSeparator
                              getInputRef={register}
                              placeholder={0}
                              onValueChange={({ value: v }) => handleChange({ target: { name: 'input_price', value: parseInt(v) } })}
                            />
                          </Paper>
                          <TextField
                            type={"hidden"}
                            name="input_price"
                            inputRef={register}
                            value={formState.input_price}
                          />
                          <FormHelperText>{errors.input_price && errors.input_price.message}</FormHelperText>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    {/*<TableRow>*/}
                    {/*  <TableCell>*/}
                    {/*    Uang Kurang*/}
                    {/*  </TableCell>*/}
                    {/*  <TableCell>*/}
                    {/*    <NumberFormat style={{fontWeight: "bold"}} value={kembalian < 0 ? Math.abs(kembalian) : 0} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/>*/}
                    {/*  </TableCell>*/}
                    {/*</TableRow>*/}
                    <TableRow>
                      <TableCell>
                        Uang Kembali
                      </TableCell>
                      <TableCell>
                        <NumberFormat style={{ fontWeight: "bold" }} value={kembalian > 0 ? kembalian : 0} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container className={classes.row}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {jumlah_produk > 0 && formState.shipping_cost >= 0 && kembalian >= 0 ? (
                    <Button type="submit" variant={"contained"} size={"small"} className={classes.buttonWarning} fullWidth>
                      Bayar
                    </Button>
                  ) : (
                    <Button type="submit" variant={"contained"} size={"small"} className={classes.buttonWarning} fullWidth disabled>
                      Bayar
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
          {/*{formProductList}*/}
        </Paper>
      </div>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontWeight: "bold" }}>
            {"Lanjutkan Pembayaran"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText alert-dialog-description>
            <Typography align={"center"} color={"inherit"}>
              Yakin Untuk Melanjutkan Pembelian?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.btnCancel} variant="outlined" size={"small"} onClick={handleClose}>Batal</Button>
          {loading ? (
            <Button className={classes.btnSuccess} variant="contained" size={"small"} disabled>Lanjutkan Pembayaran</Button>
          ) : (
            <Button className={classes.btnSuccess} variant="contained" size={"small"} onClick={() => onSubmit({})}>Lanjutkan Pembayaran</Button>
          )}


        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.payment.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onStorePaymentCart: (storeData, history) => dispatch(actions.storePaymentCart(storeData, history))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartDetail);