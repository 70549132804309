/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  container: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
}));

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: '100%',
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: '100%',
  height: '100%',
  aspectRatio: '4/3',
};

const Dropzone = (props) => {
  const classes = styles();
  const { files, setFiles, fileType, multiple, disabled } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple: multiple,
    disabled: disabled,
  });

  const deleteFiles = (index) => {
    setFiles((prev) => {
      const tempPrev = [...prev];
      tempPrev.splice(index, 1);
      return tempPrev;
    });
  };

  useEffect(() => {
    files.forEach((file) => {
      URL.revokeObjectURL(file.preview);
    });
  }, [files]);

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <section className={classes.container}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </section>
      </div>
      {files.map((file, index) => (
        <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.imageList}>
            <CardActionArea>
              <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                  <img src={file.preview} style={img} />
                </div>
              </div>
            </CardActionArea>
            <CardContent style={{ padding: '5px' }}>
              <Typography variant='body2'>{file.name}</Typography>
            </CardContent>
            <CardActions>
              <Button
                variant='contained'
                color='secondary'
                startIcon={<DeleteIcon />}
                onClick={() => deleteFiles(index)}
                fullWidth
              >
                Delete
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default Dropzone;
