import React,{Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Avatar, TextField} from "@material-ui/core";
import NumberFormat from "react-number-format";

const BeginningStockFormData = props => {
  const {product,index,register,stock,errors} = props;

  return (
    <Fragment>
      <TableRow key={product.product.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <Avatar alt="Remy Sharp" src={product.product.image} />

        </TableCell>
        <TableCell>
          {product.product.name+' '+product.product.weight+' '+product.product.unit}
        </TableCell>
        <TableCell>
          {product.product.latest_price ? <NumberFormat value={product.product.latest_price.sell_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />: 0}
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            name="qty"
            type="number"
            variant="outlined"
            size={"small"}
            inputRef={register}
            helperText={
              errors.qty && errors.qty.message
            }
            error={errors.qty && true}
            onChange={e => props.handleStockRealChange(e,product,index)}
            placeholder={stock[index].qty}
          />
        </TableCell>
        <TableCell>
          {stock[index] && product.product.latest_price ? <NumberFormat value={stock[index].qty * product.product.latest_price.sell_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `} /> : 0}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default BeginningStockFormData;