import React, {Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {IconButton, Tooltip} from "@material-ui/core";
import DetailIcon from "@material-ui/icons/Search";
import {CustomRouterLink} from "../../../../../shared/utility";

const DataSwabGramation = props => {
  // console.log(props);
  const {swabHistory,index} = props;
  return (
    <Fragment>
      <TableRow key={swabHistory.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {swabHistory.no_invoice}
        </TableCell>
        <TableCell>
          {swabHistory.created_at}
        </TableCell>
        <TableCell>
          {swabHistory.customer ? swabHistory.customer.name : ''}
        </TableCell>
        <TableCell>
          {swabHistory.customer ?
            (
              swabHistory.customer.status === '2' ?
                'MOG' :
                'AOG'
            ) :
            ''
          }

        </TableCell>
        <TableCell>
          {swabHistory.customer ? swabHistory.customer.id_agent : ''}
        </TableCell>
        <TableCell>
          {swabHistory.total_gram+' gram'}
        </TableCell>
        <TableCell>
          {swabHistory.user ? swabHistory.user.name : ''}
        </TableCell>
        <TableCell>
          <Tooltip title="Detail Purchase Order">
            {/*<RouterLink to={`/report/selling/detail/${trx.id}`}>*/}
            <IconButton aria-label="detail" component={CustomRouterLink} to={`/swap-gramation/${swabHistory.id}`}>
              <DetailIcon />
            </IconButton>
            {/*</RouterLink>*/}
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default DataSwabGramation;