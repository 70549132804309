import React, { useState,Fragment } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { 
  Grid, 
  Typography,
	Hidden,
	Badge,
	SwipeableDrawer,
	Backdrop,
	CircularProgress
} from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import CartIcon from '@material-ui/icons/AddShoppingCart'

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

// Components
import Product from './Product'
import Cart from './Cart'
// import Category from './Category'
import MobileView from './MobileView'
import SearchCustomer from './SearchCustomer'

// Redux
import { connect } from 'react-redux'
import { getSearchCustomerAndClear } from '../../actions/customer'
import { onClearProducts } from '../../actions/product'
import { useEffect } from 'react'
import moment from 'moment';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(4),
	},
	bgColor: {
		backgroundColor: '#BCE0FD',
		height: '312px',
		position: 'absolute',
		// zIndex: 0
	},
	cardMobile: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(2),
		width: '100%'
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	row: {
		height: 'auto',
		// display: 'flex',
		width: '100%',
		alignItems: 'center',
		marginTop: theme.spacing(1)
	},
	catSearch: {
		borderRadius: '4px',
		alignItems: 'center',
		padding: theme.spacing(1),
		display: 'flex',
		flexBasis: 420,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		width: '100%'
	},
	date_trx: {
		width: '200px'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	rootTab: {
		backgroundColor: theme.palette.background.paper,
	}
}));

const Cashier = props => {
	const classes = useStyles();
	const { 
		getSearchCustomerAndClear, 
		onClearProducts,
		customer : { 
			searchCustomerClear, 
			loadingCustomerClear 
		} 
	} = props
	
	const [ modalOpen, setModalOpen ] = useState(false)

	const handleModalOpen = () => {
		setModalOpen(true)
	}

	const handleModalClose = () => {
		setModalOpen(false)
	}

	const [ valueSearch, setValueSearch ] = useState({
		type: 'id_agent',
		name: ''
	})

	const submitDefault = moment().format('YYYY-MM-DD HH:mm:ss')
	const minDate = moment().subtract(2, 'd').format('YYYY-MM-DD HH:mm:ss')
	const [ startDate, setStartDate ] = useState({
        submit: {
            submit: submitDefault
        },
        view: {
            view: new Date()
        }
        
	});

    const handleStartDate = (date) => {
        const changeDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        setStartDate(startDate => ({
            ...startDate,
                submit: {
                    submit: changeDate
            },
                view: {
                    view: date
            }
        }));
	};

	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};
	
	useEffect(() => {
		getSearchCustomerAndClear('name', '')
		onClearProducts()
	}, [ getSearchCustomerAndClear, startDate, onClearProducts])

  return loadingCustomerClear || searchCustomerClear === null ? 
	<Backdrop className={classes.backdrop} open>
		<CircularProgress color="inherit" />
	</Backdrop> 
	:
	<Fragment>
			<Hidden only={['sm','xs']}>
				<div className={classes.root}>
					<div className={classes.bgColor}></div>
					<Grid
						container
						spacing={2}
						justify="space-between"
					>
						<Grid item>  
								<Typography variant="h4">Transaksi Penjualan</Typography>
						</Grid>
					</Grid>

			<Grid container spacing={2}>
				<Grid item lg={8} md={8} sm={12} xs={12}>
					<Grid item lg={12} md={12} sm={12} xs={12}>
					<SearchCustomer
						startDate={startDate}
						handleStartDate={handleStartDate}
						valueSearch={valueSearch}
						searchCustomerClear={searchCustomerClear}
						setValueSearch={setValueSearch}
						minDate={minDate}
					/>
					</Grid>
					<Grid container spacing={2}>
					<Grid item lg={12} md={12} sm={12} xs={12}>
					{!loadingCustomerClear && searchCustomerClear.length > 0 && (
						<>
								<AppBar position="static" color="default">
									<Tabs
										value={value}
										onChange={handleChange}
										indicatorColor="primary"
										textColor="primary"
										variant="fullWidth"
										aria-label="full width tabs example"
									>
										<Tab label="EOA GOLD" {...a11yProps(0)} />
										<Tab label="Merchandise" {...a11yProps(1)} />
									</Tabs>
								</AppBar>
								<SwipeableViews
									axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
									index={value}
									onChangeIndex={handleChangeIndex}
								>
									<TabPanel value={value} index={0} dir={theme.direction}>
										<Product searchCustomerClear={searchCustomerClear[0]} date={startDate.submit.submit} product_type={"gold"}/>
									</TabPanel>
									<TabPanel value={value} index={1} dir={theme.direction}>
										<Product searchCustomerClear={searchCustomerClear[0]} date={startDate.submit.submit} product_type={"other"}/>
									</TabPanel>
								</SwipeableViews>


						</>
					)}
					</Grid>
					</Grid>
				</Grid>
				{/*<Grid item lg={8} md={8} sm={12} xs={12}>*/}
				{!loadingCustomerClear && searchCustomerClear.length > 0 && (
					<>
					<Hidden only={['xs', 'sm']}>
						<Grid
							item
							lg={4}
							md={4}
							sm={12}
							xs={12}
						>
							<Cart date={startDate.submit.submit} searchCustomerClear={searchCustomerClear[0]} />
						</Grid>
					</Hidden>
					</>
				)}
				{/*</Grid>*/}
			</Grid>
				
			{!loadingCustomerClear && searchCustomerClear.length > 0 && (
				<>
					<div className={classes.row}>
						{/* {searchCustomer.map((product) => ( */}
							<div>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										lg={8}
										md={8}
										sm={12}
										xs={12}
									>
										{/*<Product searchCustomerClear={searchCustomerClear[0]} date={startDate.submit.submit} />*/}
									</Grid>
									{/*<Hidden only={['xs', 'sm']}>*/}
									{/*	<Grid*/}
									{/*		item*/}
									{/*		lg={4}*/}
									{/*		md={4}*/}
									{/*		sm={12}*/}
									{/*		xs={12}*/}
									{/*	>*/}
									{/*		<Cart date={startDate.submit.submit} searchCustomerClear={searchCustomerClear[0]} />*/}
									{/*	</Grid>*/}
									{/*</Hidden>*/}
								</Grid>
								<Hidden only={['md','lg','xl']}>
									<Fab color="primary" aria-label="add" className={classes.fab} onClick={handleModalOpen}>
										<Badge badgeContent={17} color="secondary">
												<CartIcon />
										</Badge>
									</Fab>
									<SwipeableDrawer
										anchor='bottom'
										open={modalOpen}
										onClose={handleModalClose}
										onOpen={handleModalOpen}
										disableSwipeToOpen
									>
										<Cart date={startDate.submit.submit} searchCustomerClear={searchCustomerClear[0]} />
									</SwipeableDrawer>
								</Hidden>
							</div>
						{/* ))} */}
					</div>
				</>
			)}
				</div>
			</Hidden>

			<Hidden only={['md','lg','xl']}>
				<MobileView searchCustomerClear={searchCustomerClear} loadingCustomerClear={loadingCustomerClear} />
			</Hidden>
	</Fragment>
};

const mapStateToProps = state => ({
customer: state.customer
})

export default connect(mapStateToProps, { getSearchCustomerAndClear, onClearProducts })(Cashier);
