import React, { Fragment, useEffect, useState } from 'react';
import { Main } from '../style';
import { Button, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import {
  FormSearchCustomer,
  ModalSearchCustomer,
  UserData,
} from './components';
import moment from 'moment';
import Modal from '../../../components/UI/Modal/ScrollDialog/ScrollDialog';
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import { Loading } from '../../../components/UI';
import ExchangeGoldFrom from './components/ExchangeGoldFrom/ExchangeGoldFrom';
import ExchangeGoldTo from './components/ExchangeGoldTo/ExchangeGoldTo';
import { CustomRouterLink, isEmpty } from '../../../shared/utility';
import { useHistory } from 'react-router-dom';

const CreateSwapGramation = (props) => {
  const history = useHistory();
  const classes = Main();
  const {
    onFetchCustomer,
    loadingCustomer,
    customers,
    getSearchCustomerAndClear,
    searchCustomerClear,
    loadingCustomerClear,
    onStoreSwapGramation,
    onClearProducts,
    loadingSwapGramation,
  } = props;

  const [formSearch, setFormSearch] = useState({
    start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  });
  const [startDate, setStartDate] = useState({
    startDate: new Date(),
  });
  const [isOpen, setIsOpen] = useState(false);
  const [Form, setForm] = useState('');
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });
  const [selectedUser, setSelectedUser] = useState(false);

  useEffect(() => {
    getSearchCustomerAndClear('name', '');
    onClearProducts();
  }, [formSearch, getSearchCustomerAndClear, onClearProducts]);

  const handleStartDate = (input) => {
    setStartDate((startDate) => ({
      ...startDate,
      startDate: input,
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(input).format('YYYY-MM-DD HH:mm:ss'),
    }));
  };

  const reset = () => {
    setStartDate((startDate) => ({
      ...startDate,
      startDate: new Date(),
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    }));
  };

  const minDate = moment().subtract(14, 'd').format('YYYY-MM-DD HH:mm:ss');

  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  };

  const searchCustomer = (data) => {
    setModalState({
      open: true,
      title: 'Pencarian Customer',
      maxWidth: 'sm',
    });
    setForm(
      <ModalSearchCustomer
        classes={classes}
        onFetchCustomer={onFetchCustomer}
        getSearchCustomerAndClear={getSearchCustomerAndClear}
        closedModalDialog={() => closedModalDialog()}
        setSelectedUser={setSelectedUser}
      />
    );
  };

  let loading = '';
  if (loadingCustomer || loadingCustomerClear) {
    loading = <Loading />;
  }

  let listExchangeGoldFrom = [];
  const [cartExchangeGoldFrom, setCartExchangeGoldFrom] =
    useState(listExchangeGoldFrom);
  const setListExchangeGoldFrom = (data) => {
    setCartExchangeGoldFrom(data);
  };

  let listExchangeGoldTo = [];
  let cartTo = [];
  let max_weight = 0;
  const [cartExchangeGoldTo, setCartExchangeGoldTo] =
    useState(listExchangeGoldTo);
  if (cartExchangeGoldFrom.length > 0) {
    let test = cartExchangeGoldFrom.filter(({ qty }) => qty > 0);
    if (test.length > 0) {
      max_weight = Math.max.apply(
        Math,
        test.map(function (o) {
          return o.weight;
        })
      );
      cartTo = cartExchangeGoldFrom.filter(({ weight }) => weight > max_weight);
    }
  }

  let sub_total_gram_from = !isEmpty(cartExchangeGoldFrom)
    ? cartExchangeGoldFrom
        .map((item) => parseFloat(item.sub_total_gram))
        .reduce((prev, next) => prev + next)
    : 0;
  let sub_total_gram_to = !isEmpty(cartExchangeGoldTo)
    ? cartExchangeGoldTo
        .map((item) => parseFloat(item.sub_total_gram))
        .reduce((prev, next) => prev + next)
    : 0;
  let button =
    sub_total_gram_from > 0 &&
    sub_total_gram_from === sub_total_gram_to &&
    !loadingSwapGramation ? (
      <Button
        variant={'contained'}
        size={'large'}
        className={classes.btnSuccess}
        onClick={() => onClick({})}
      >
        Tukar
      </Button>
    ) : (
      <Button
        variant={'contained'}
        size={'large'}
        className={classes.btnSuccess}
        disabled
      >
        {' '}
        Tukar{' '}
      </Button>
    );

  const onClick = (data) => {
    data.customer_id = searchCustomerClear[0].id;
    data.total_gram = sub_total_gram_to;
    data.from = cartExchangeGoldFrom.filter(({ qty }) => qty > 0);
    data.to = cartExchangeGoldTo.filter(({ qty }) => qty > 0);
    data.date_time = formSearch.start_date;
    onStoreSwapGramation(data, history);
  };

  return (
    <Fragment>
      {loading}
      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
      >
        {Form}
      </Modal>

      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <div className={classes.tagMenu}>Form Tukar Gramasi</div>
            </Grid>
            <Grid item>
              <Button
                variant={'contained'}
                size={'medium'}
                className={classes.btnBack}
                startIcon={React.cloneElement(<ArrowBack />)}
                component={CustomRouterLink}
                to={'/swap-gramation'}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <FormSearchCustomer
                classes={classes}
                setIsOpen={setIsOpen}
                startDate={startDate}
                isOpen={isOpen}
                handleStartDate={(input) => handleStartDate(input)}
                minDate={minDate}
                searchCustomer={(data) => searchCustomer(data)}
                loadingCustomer={loadingCustomer}
                customers={customers}
                reset={() => reset()}
              />
            </Grid>
            {selectedUser && (
              <Grid item xl={4} md={4} sm={12} xs={12}>
                <UserData
                  classes={classes}
                  searchCustomerClear={searchCustomerClear}
                />
              </Grid>
            )}
          </Grid>
        </div>

        {selectedUser && (
          <>
            <div className={classes.row}>
              {!isEmpty(searchCustomerClear) && (
                <ExchangeGoldFrom
                  classes={classes}
                  startDate={startDate}
                  searchCustomerClear={searchCustomerClear}
                  product_type={'gold'}
                  product_unit={'gram'}
                  cartExchangeGoldFrom={cartExchangeGoldFrom}
                  setCartExchangeGoldFrom={setCartExchangeGoldFrom}
                  getListExchangeGoldFrom={(data) =>
                    setListExchangeGoldFrom(data)
                  }
                  listExchangeGoldFrom={listExchangeGoldFrom}
                />
              )}
            </div>

            <div className={classes.row}>
              {!isEmpty(searchCustomerClear) && (
                <ExchangeGoldTo
                  classes={classes}
                  cartTo={cartTo}
                  searchCustomerClear={searchCustomerClear}
                  startDate={startDate}
                  product_type={'gold'}
                  product_unit={'gram'}
                  max_weight={max_weight}
                  cartExchangeGoldTo={cartExchangeGoldTo}
                  setCartExchangeGoldTo={setCartExchangeGoldTo}
                  listExchangeGoldTo={listExchangeGoldTo}
                />
              )}
            </div>

            <div className={classes.row}>
              {!isEmpty(searchCustomerClear) && button}
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingCustomer: state.customer.loadingCustomerV2,
    customers: state.customer.customers_v2,
    searchCustomerClear: state.customer.searchCustomerClear,
    loadingCustomerClear: state.customer.loadingCustomerClear,
    loadingSwapGramation: state.swapGramation.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCustomer: (type, kata_kunci, limit, page) =>
      dispatch(actions.getCustomerCashier(type, kata_kunci, limit, page)),
    getSearchCustomerAndClear: (params, kata_kunci) =>
      dispatch(actions.getSearchCustomerAndClear(params, kata_kunci)),
    onStoreSwapGramation: (storeData, history) =>
      dispatch(actions.storeSwapGramation(storeData, history)),
    onClearProducts: () => dispatch(actions.onClearProducts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSwapGramation);
