import React,{Fragment} from "react";
import {Box, Paper, Table, TableRow, TableCell, Typography, IconButton} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {FileCopy as FileCopyIcon} from '@material-ui/icons';
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import Tooltip from "@material-ui/core/Tooltip";

var rincianData= {
  totalItem:0,
  totalOrder:0,
  totalBerat:0,
  totalHarga:0
}

function CountQty(item){
  return item.map(data => data.quantity).reduce((key, val) => val + key);
}

function CountGram(item){
  return item.map(data => Number(data.weight)).reduce((key, val) => val + key);
}

function CountPrice(item){
  return item.map(data => data.sell_price*data.quantity).reduce((key, val) => val + key);
}
const RincianPesanan = props => {
  const {classes, purchaseOrder} = props;
  // console.log(purchaseOrder)
  return (
    <Fragment>
      <Paper>
        <div className={classes.paperContent}>
          <div className={classes.tagMenu}>
            Rincian Pesanan
          </div>

          <div className={classes.row}>
            <Table>
              <TableRow>
                <TableCell width={'40%'} className={classes.rincianTable}>
                  Total Item
                </TableCell>
                <TableCell width={'60%'} className={classes.rincianTable}>
                  : <NumberFormat value={purchaseOrder ? purchaseOrder.totalOrder : 0} displayType={'text'} suffix={' varian'} thousandSeparator={true}/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.rincianTable}>
                  Total Order
                </TableCell>
                <TableCell className={classes.rincianTable}>
                  : <NumberFormat value={purchaseOrder.totalItem} displayType={'text'} suffix={' pcs'} thousandSeparator={true}/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.rincianTable}>
                  Total Berat
                </TableCell>
                <TableCell className={classes.rincianTable}>
                  : {purchaseOrder.totalBerat}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.rincianTable}>
                  Total Harga
                </TableCell>
                <TableCell className={classes.rincianTable}>
                  : <NumberFormat value={purchaseOrder.totalHarga} displayType={'text'} prefix={'Rp '} thousandSeparator={true}/>
                </TableCell>
              </TableRow>
              {/*<TableRow>*/}
              {/*  <TableCell className={classes.rincianTable}>*/}
              {/*    Kode Unik*/}
              {/*  </TableCell>*/}
              {/*  <TableCell className={classes.rincianTable}>*/}
              {/*    : <NumberFormat value={purchaseOrder.unique_number} displayType={'text'} prefix={'Rp +'} thousandSeparator={true}/>*/}
              {/*  </TableCell>*/}
              {/*</TableRow>*/}
              {/*<TableRow>*/}
              {/*  <TableCell className={classes.rincianTable}>*/}
              {/*    Total Bayar*/}
              {/*  </TableCell>*/}
              {/*  <TableCell className={classes.rincianTable}>*/}
              {/*    : <NumberFormat value={purchaseOrder.total_payment} displayType={'text'} prefix={'Rp '} thousandSeparator={true}/>*/}
              {/*  </TableCell>*/}
              {/*</TableRow>*/}
            </Table>
          </div>
          {/* <div className={classes.row}>
            <Typography variant={"h6"} className={classes.textCenter}>
              Selesaikan pembayaran Anda <br/>
              Transfer sesuai nominal dibawah
            </Typography>
          </div>
          <div className={classes.row}>

            <Typography variant={"h4"} className={classes.textCenter}>
              <NumberFormat value={purchaseOrder.totalHarga} displayType={'text'} prefix={'Rp '} thousandSeparator={true}/>
              <CopyToClipboard text={purchaseOrder.totalHarga}>
                <Tooltip title="copy to clipboard" placement={"right"}>
                  <IconButton>
                    <FileCopyIcon fontSize={"small"}/>
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Typography>

          </div>
          <div className={classes.row}>
            <div className={classes.row}>
              <Typography variant={"h6"} className={classes.textCenter}>
                Ke Nomor Rekening
              </Typography>
            </div>
          </div>
          <div className={classes.row}>
            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent={"space-between"}>
              <Box p={1}>
                <img src="/images/logo/bsi.png" alt={'bsi'} width={'100%'} height={'auto'}/>
              </Box>
              <Box>
                <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent={"flex-start"}>
                  <Box p={1}>
                    <Typography variant={"h2"} className={classes.rek}>
                      7137260799
                    </Typography>
                  </Box>
                  <Box style={{padding: '5px 0px'}}>
                    <CopyToClipboard text={'7137260799'}>
                      <Tooltip title="copy to clipboard">
                        <IconButton>
                          <FileCopyIcon fontSize={"small"}/>
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent={"space-between"}>
              <Box p={1}>
                <Typography variant={"h6"}>
                  an. PT EMAS OPTIMASI ABADI
                </Typography>
              </Box>
            </Box>
          </div> */}
        </div>
      </Paper>
    </Fragment>
  );
};

export default RincianPesanan