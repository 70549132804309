import {
    GET_TRANSACTION_BUYBACK,
    GET_SEARCH_TRANSACTION_BUYBACK,
    GET_DETAIL_TRANSACTION_BUYBACK,
    DOWNLOAD_REPORT_TRANSACTION_BUYBACK_START,
    DOWNLOAD_REPORT_TRANSACTION_BUYBACK_SUCCESS,
    DOWNLOAD_REPORT_TRANSACTION_BUYBACK_FAILED,
    GET_DETAIL_TRANSACTION_BUYBACK_START,
    GET_SEARCH_TRANSACTION_BUYBACK_START
} from '../actions/types'
import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
    transactions: null,
    transaction: null,
    downloadTransaction: null,
    loading: true,
    loadingDownload: false,
    loadingDetailTransaction: false,
    loadingSearchTransaction: false,
    error: {},
    transactionsV2: {},
    errorV2: {},
    loadingV2: false,
}

const fetchTransactionsBuybackStart = (state, action) => {
    return updateObject(state, { loadingV2: true })
}

const fetchTransactionsBuybackSuccess = (state, action) => {
    return updateObject(state, {
        transactionsV2: action.transactions,
        loadingV2: false
    })
}

const fetchTransactionsBuybackFail = (state, action) => {
    return updateObject(state, { errorV2: action.error, loadingV2: false })
}

const downloadTransactionsBuybackStart = (state, action) => {
    return updateObject(state, { errorV2: {}, loadingV2: true })
}

const downloadTransactionsBuybackSuccess = (state, action) => {
    return updateObject(state, {
        // purchaseOrdersV2: action.purchase_orders,
        loadingV2: false
    })
}

const downloadTransactionsBuybackFail = (state, action) => {
    return updateObject(state, { errorV2: action.error, loadingV2: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_TRANSACTION_BUYBACK:
            return {
                ...state,
                transactions: payload,
                loading: false
            }
        case GET_SEARCH_TRANSACTION_BUYBACK_START:
            return {
                ...state,
                loadingSearchTransaction: true,
            }
        case GET_SEARCH_TRANSACTION_BUYBACK:
            return {
                ...state,
                transactions: payload,
                loadingSearchTransaction: false,
            }
        case GET_DETAIL_TRANSACTION_BUYBACK_START:
            return {
                ...state,
                loadingDetailTransaction: true
            }
        case GET_DETAIL_TRANSACTION_BUYBACK:
            return {
                ...state,
                transaction: payload,
                loadingDetailTransaction: false
            }
        case DOWNLOAD_REPORT_TRANSACTION_BUYBACK_START:
            return {
                ...state,
                loadingDownload: true
            }
        case DOWNLOAD_REPORT_TRANSACTION_BUYBACK_SUCCESS:
            return {
                ...state,
                downloadTransaction: payload,
                loadingDownload: false
            }
        case DOWNLOAD_REPORT_TRANSACTION_BUYBACK_FAILED:
            return {
                ...state,
                downloadTransaction: payload,
                loadingDownload: false
            }
        case actionTypes.FETCH_TRANSACTIONS_BUYBACK_START: return fetchTransactionsBuybackStart(state, action)
        case actionTypes.FETCH_TRANSACTIONS_BUYBACK_SUCCESS: return fetchTransactionsBuybackSuccess(state, action)
        case actionTypes.FETCH_TRANSACTIONS_BUYBACK_FAIL: return fetchTransactionsBuybackFail(state, action)
        case actionTypes.DOWNLOAD_TRANSACTIONS_BUYBACK_START: return downloadTransactionsBuybackStart(state, action)
        case actionTypes.DOWNLOAD_TRANSACTIONS_BUYBACK_SUCCESS: return downloadTransactionsBuybackSuccess(state, action)
        case actionTypes.DOWNLOAD_TRANSACTIONS_BUYBACK_FAIL: return downloadTransactionsBuybackFail(state, action)
        default:
            return state
    }
}

export default reducer;