import React, { Fragment } from "react";
import { Grid, TextField } from "@material-ui/core";

// components
import TextBox from "../../components/TextBox/TextBox";
import CustomCard from "../../components/Card/Card";

import { makeStyles } from "@material-ui/core/styles";

import { useFormik } from "formik";
import * as yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  textInput: {
    width: "100%",
  },
  card: {
    marginTop: theme.spacing(2),
    overflow: "visible",
  },
  searchRoot: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "auto",
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  select: {
    minHeight: 40,
  },
  typography: {
    fontFamily: "Nunito",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const phoneRegExp =
  /(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/;

const validationSchema = yup.object({
  full_name: yup.string().required("Nama tidak boleh kosong"),
  register_type: yup
    .string()
    .nullable()
    .required("Jenis Pendaftaran tidak boleh kosong"),
  birth_place: yup.string().required("Tempat Lahir tidak boleh kosong"),
  //birth_date: "1996-01-01",
  gender: yup.string().required("Jenis Kelamin tidak boleh kosong"),
  ktp_number: yup.string().required("Nomor KTP tidak boleh kosong"),
  aogmog_id: yup.string().required("Nomor ID AOG/MOG tidak boleh kosong"),
  alamatemail: yup
    .string()
    .email("Format Email salah")
    .required("Email tidak boleh kosong"),
  whatsapp_number: yup
    .string()
    .required("Nomor Whatsapp tidak boleh kosong")
    .matches(phoneRegExp, "Format salah"),
  bank_account_number: yup
    .string()
    .required("Nomor Rekening tidak boleh kosong"),
  bank_name: yup.string().required("Nama Bank tidak boleh kosong"),
  ktp_address: yup.string().required("Alamat KTP tidak boleh kosong"),

  ktp_post_code: yup.string().required("Kode Pos KTP tidak boleh kosong"),
  //shipping_address: "Jl. Bumi no. 99",
  //shipping_id_village: "1",
  //shipping_post_code: "55555",
  //residence_address: "Jl. Bumi no. 99",
  //residence_id_village: "1",
  //residence_post_code: "55555",
  //id_branch: "47",
  //id_pereferal: "EOAA901117",
  last_education: yup
    .string()
    .required("Pendidikan Terakhir tidak boleh kosong"),
  marital_status: yup.string().required("Status Pernikahan tidak boleh kosong"),
  //is_having_npwp: "Tidak",
  profession: yup.string().required("Pekerjaan tidak boleh kosong"),
  //npwp_number: "999.999.999.999-111.0",
  //home_ownership_status: "Milik Sendiri",
  //is_attending_private_class: "Sudah",
  //private_class_id: "999",
  //is_union_member: "Tidak",
  //ba_number: "999",
  religion: yup.string().required("Agama tidak boleh kosong"),
});

export default function DataRegistration(props) {
  console.log("PROPS", props.datas);
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      register_type: "",
      full_name: "",
      birth_place: "",
      birth_date: "1990-01-01",
      gender: "",
      ktp_number: "",
      aogmog_id: "",
      alamatemail: "",
      whatsapp_number: "",
      bank_account_number: "",
      bank_name: "",
      ktp_address: "",
      ktp_id_village: "",
      ktp_post_code: "",
      shipping_address: "",
      shipping_id_village: "",
      shipping_post_code: "",
      residence_address: "",
      residence_id_village: "",
      residence_post_code: "",
      id_branch: "",
      id_pereferal: "",
      last_education: "",
      marital_status: "",
      is_having_npwp: "",
      profession: "",
      npwp_number: "",
      home_ownership_status: "",
      is_attending_private_class: "",
      private_class_id: "",
      is_union_member: "",
      ba_number: "000",
      religion: "",
      pereferal_name_aog: "",
      pereferal_name_mog: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      //
    },
  });

  return (
    <>
      <Fragment>
        <div className={classes.root}>
          <form onSubmit={formik.handleSubmit}>
            <CustomCard
              classes={classes.card}
              title="Jenis Pendaftaran dan EGS Pembina"
            >
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="register_type"
                  name="register_type"
                  value={props.datas.register_type}
                  label="Jenis Pendaftaran"
                />
                <TextBox
                  disabled
                  id="id_branch"
                  name="id_branch"
                  value={props.datas.branch}
                  label="EGS Pembina"
                />
              </Grid>
            </CustomCard>
            <CustomCard classes={classes.card} title="Biodata Pendaftar">
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="full_name"
                  name="full_name"
                  value={props.datas.full_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.full_name && Boolean(formik.errors.full_name)
                  }
                  helperText={
                    formik.touched.full_name && formik.errors.full_name
                  }
                  label="Nama Lengkap"
                />
                <TextBox
                  disabled
                  id="gender"
                  name="gender"
                  value={props.datas.gender}
                  label="Jenis Kelamin"
                />
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="ktp_number"
                  name="ktp_number"
                  value={props.datas.ktp_number}
                  onChange={formik.handleChange}
                  error={formik.errors.ktp_number}
                  helperText={
                    formik.touched.ktp_number && formik.errors.ktp_number
                  }
                  label="No. KTP"
                />

                <TextBox
                  disabled
                  id="aogmog_id"
                  name="aogmog_id"
                  value={props.datas.aogmog_id}
                  label="ID MOG/AOG"
                />
              </Grid>

              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="birth_place"
                  name="birth_place"
                  value={props.datas.birth_place}
                  onChange={formik.handleChange}
                  error={formik.errors.birth_place}
                  helperText={
                    formik.touched.birth_place && formik.errors.birth_place
                  }
                  label="Tempat Lahir"
                />
                <TextBox
                  disabled
                  id="birth_date"
                  name="birth_date"
                  value={props.datas.birth_date}
                  onChange={formik.handleChange}
                  type="date"
                  error={formik.errors.birth_date}
                  helperText={
                    formik.touched.birth_date && formik.errors.birth_date
                  }
                  label="Tanggal Lahir"
                />
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="religion"
                  name="religion"
                  value={props.datas.religion}
                  label="Agama"
                />

                <TextBox
                  disabled
                  id="last_education"
                  name="last_education"
                  value={props.datas.last_education}
                  label="Pendidikan Terakhir"
                />
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="profession"
                  name="profession"
                  value={props.datas.profession}
                  onChange={formik.handleChange}
                  error={formik.errors.profession}
                  helperText={
                    formik.touched.profession && formik.errors.profession
                  }
                  label="Pekerjaan"
                />
                <TextBox
                  disabled
                  id="marital_status"
                  name="marital_status"
                  value={props.datas.marital_status}
                  label="Status Pernikahan"
                />
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="is_having_npwp"
                  name="is_having_npwp"
                  value={props.datas.is_having_npwp}
                  label="Status Kepemilikan NPWP"
                />

                {props.datas.is_having_npwp === "Ya" ? (
                  <TextBox
                    disabled
                    id="npwp_number"
                    name="npwp_number"
                    value={props.datas.npwp_number}
                    error={formik.errors.npwp_number}
                    helperText={
                      formik.touched.npwp_number && formik.errors.npwp_number
                    }
                    onChange={formik.handleChange}
                    label="Nomor NPWP"
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="home_ownership_status"
                  name="home_ownership_status"
                  value={props.datas.home_ownership_status}
                  label="Status Kepemilikan Rumah"
                />
              </Grid>
            </CustomCard>
            <CustomCard classes={classes.card} title="Kontak dan lainnya">
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="alamatemail"
                  name="alamatemail"
                  label="Alamat Email"
                  value={props.datas.email}
                />
                <TextBox
                  disabled
                  id="whatsapp_number"
                  name="whatsapp_number"
                  label="Nomor WhatsApp"
                  value={props.datas.whatsapp_number}
                  onChange={formik.handleChange}
                  error={formik.errors.whatsapp_number}
                  helperText={
                    formik.touched.whatsapp_number &&
                    formik.errors.whatsapp_number
                  }
                />
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="bank_name"
                  name="bank_name"
                  label="Nama Bank"
                  value={props.datas.bank_name}
                  onChange={formik.handleChange}
                  error={formik.errors.bank_name}
                  helperText={
                    formik.touched.bank_name && formik.errors.bank_name
                  }
                />
                <TextBox
                  disabled
                  id="bank_account_number"
                  name="bank_account_number"
                  label="Nomor Rekening"
                  value={props.datas.bank_account_number}
                  onChange={formik.handleChange}
                  error={formik.errors.bank_account_number}
                  helperText={
                    formik.touched.bank_account_number &&
                    formik.errors.bank_account_number
                  }
                />
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="is_attending_private_class"
                  name="is_attending_private_class"
                  value={props.datas.is_attending_private_class}
                  label="Mengikuti Private Class"
                />

                {props.datas.is_attending_private_class === "sudah" ? (
                  <TextBox
                    disabled
                    id="private_class_id"
                    name="private_class_id"
                    label="Nomor Alumni"
                    value={props.datas.private_class_id}
                    onChange={formik.handleChange}
                    error={formik.errors.private_class_id}
                    helperText={
                      formik.touched.private_class_id &&
                      formik.errors.private_class_id
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="is_union_member"
                  name="is_union_member"
                  value={props.datas.is_union_member}
                  label="Anggota koperasi EOA Club"
                />

                {props.datas.is_union_member === "Ya" ? (
                  <TextBox
                    disabled
                    id="ba_number"
                    name="ba_number"
                    value={props.datas.ba_number}
                    error={formik.errors.ba_number}
                    helperText={
                      formik.touched.ba_number && formik.errors.ba_number
                    }
                    onChange={formik.handleChange}
                    label="Nomor Anggota"
                  />
                ) : (
                  ""
                )}
              </Grid>
            </CustomCard>
            <CustomCard classes={classes.card} title="Alamat KTP">
              <TextField
                disabled
                fullWidth
                label="Alamat Lengkap (Alamat saat ini)"
                value={props.datas.ktp_address}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Provinsi"
                    margin="normal"
                    value={props.datas.ktp_province}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kota/Kabupaten"
                    margin="normal"
                    value={props.datas.ktp_regency}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kecamatan"
                    value={props.datas.ktp_district}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kelurahan"
                    value={props.datas.ktp_village}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kode Pos"
                    value={props.datas.ktp_post_code}
                  />
                </Grid>
              </Grid>
            </CustomCard>

            <CustomCard classes={classes.card} title="Alamat Domisili">
              <TextField
                disabled
                fullWidth
                label="Alamat Lengkap (Alamat saat ini)"
                value={props.datas.residence_address}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Provinsi"
                    margin="normal"
                    value={props.datas.residence_province}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kota/Kabupaten"
                    margin="normal"
                    value={props.datas.residence_regency}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kecamatan"
                    value={props.datas.residence_district}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kelurahan"
                    value={props.datas.residence_village}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kode Pos"
                    value={props.datas.residence_post_code}
                  />
                </Grid>
              </Grid>
            </CustomCard>

            <CustomCard classes={classes.card} title="Alamat Pengiriman">
              <TextField
                disabled
                fullWidth
                label="Alamat Lengkap (Alamat saat ini)"
                value={props.datas.shipment_address}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Provinsi"
                    margin="normal"
                    value={props.datas.shipment_province}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kota/Kabupaten"
                    margin="normal"
                    value={props.datas.shipment_regency}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kecamatan"
                    value={props.datas.shipment_district}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kelurahan"
                    value={props.datas.shipment_village}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Kode Pos"
                    value={props.datas.shipment_post_code}
                  />
                </Grid>
              </Grid>
            </CustomCard>

            <CustomCard classes={classes.card} title="Pereferal">
              <Grid container spacing={2}>
                <TextBox
                  disabled
                  id="id_pereferal"
                  name="id_pereferal"
                  value={props.datas.pereferral_id}
                  label="AOG/MOG Pereferal"
                />
              </Grid>
              <TextBox
                disabled
                id="pereferal_name_mog"
                name="pereferal_name_mog"
                label="Name Pereferal"
                value={props.datas.pereferral_name}
              />
              <Grid container spacing={2}></Grid>
            </CustomCard>

            <br />
          </form>
        </div>
      </Fragment>
    </>
  );
}
