import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DetailIcon from '@material-ui/icons/Search'
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom'

import {
	Tooltip,
	IconButton, TableFooter,
} from '@material-ui/core';
import NumberFormat from 'react-number-format'
import {TablePaginationActions} from "../../../components/UI";

const columns = [
  { id: 'tanggal', label: 'Tanggal', minWidth: 100 },
  { id: 'no_invoice', label: 'No Invoice', minWidth: 100 },
  { id: 'customer', label: 'Customer', minWidth: 200 },
	{ id: 'Quantity', label: 'Quantity', minWidth: 100 },
  { id: 'total_harga', label: 'Buyback', minWidth: 100 },
  { id: 'action', label: 'Aksi', minWidth: 100 },
  
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const ListTransaction = (props) => {
	const classes = useStyles();
	// const { transactions, loadingSearchTransaction, page, setPage } = props

	const { transactions, handleChangePageProps } = props
	const page = transactions.data.current_page - 1;
	const rowsPerPage = transactions.data.per_page;
	const handleChangePage = (event, newPage) => {
		handleChangePageProps(newPage)
	};
    
	return (
		<Fragment>
			<Paper className={classes.root}>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{transactions.data.data.map((trx) => (
								<TableRow key={trx.id}>
									<TableCell>
										{moment(trx.created_at).format('DD MMMM YYYY HH:mm')}
										{/* {trx.created_at} */}
									</TableCell>
									<TableCell>
										{trx.inv_name}
									</TableCell>
									<TableCell>
										{trx.customer.name}
									</TableCell>
									<TableCell>
										{trx.qty}
									</TableCell>
									<TableCell>
										<NumberFormat value={trx.total} displayType={'text'} thousandSeparator={true} prefix={`RP `} />
									</TableCell>
									<TableCell>
										<Tooltip title="Detail Invoice">
											<RouterLink to={`/report/buyback/detail/${trx.id}`}>
												<IconButton aria-label="detail" color="primary">
													<DetailIcon />
												</IconButton>
											</RouterLink>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TablePagination
								rowsPerPageOptions={[rowsPerPage]}
								colSpan={8}
								count={transactions.data.total}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableFooter>
					</Table>
				</TableContainer>
			</Paper>
		</Fragment>
		)
}

export default ListTransaction