/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import FormSearchPurchaseOrder from '../../components/FormSearchPurchaseOrder/FormSearchPurchaseOrder';
import ListPurchaseOrder from '../../components/ListPuchaseOrder/ListPurchaseOrder';

import { Main } from './style';

import moment from 'moment';

import registrationService, {
  downloadListPOAOGFormat1,
} from '../../services/registration.service';
import { useSnackbar } from '../../shared/useSnackbar';
import fileDownload from 'js-file-download';
import { cleanParams } from '../../helpers/params';

export default function ApprovalPO(props) {
  const { createSnackbar } = useSnackbar();

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      message: msg,
      dismissable: false,
      theme: theme,
      sticky: false,
    });
  };

  const classes = Main();
  const [formSearch, setFormSearch] = useState({
    status: '',
    search: '',
    start_date: moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
    end_date: moment(new Date()).format('YYYY-MM-DD') + ' 23:55:00',
    round_id: '',
  });
  const [startDate, setStartDate] = useState(
    moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00'
  );

  const [endDate, setEndDate] = useState(
    moment(new Date()).format('YYYY-MM-DD') + ' 23:55:00'
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);
  const [statusPO, setStatusPO] = useState('');
  const [dataPurchase, getDataPurchase] = useState([]);
  const [pagenoPurchase, getPageNoPurchase] = useState(1);
  const [countPage, getCountPage] = useState(10);

  const handleSearchStart = (event) => {
    const target = event.target.name;
    const input = event.target.value;

    if (target === 'dateStart') {
      setStartDate(moment(input).format('YYYY-MM-DD') + ' 00:00:00');
    }
    if (target === 'dateEnd') {
      setEndDate(moment(input).format('YYYY-MM-DD') + ' 23:55:00');
    }

    getCountPage(10);
  };

  const handleSearchEnd = (event) => {
    const target = event.target.name;
    const input = moment(event.target.value).format('YYYY-MM-DD') + ' 23:55:00';

    if (target === 'dateEnd') {
      setEndDate(input);
    }

    getCountPage(10);
  };

  const handleSearch = (event) => {
    const input = event.target.value;

    setStatusPO(input);
    getCountPage(10);
  };

  const getAllPruchase = async (params) => {
    const configs = {
      page: pagenoPurchase,
      per_page: 10,
      start_date: params.startDate,
      end_date: params.endDate,
    };

    if (params.status_po > 0) {
      configs.status_po = params.status_po;
    }

    return await registrationService
      .getlist(configs)
      .then((response) => {
        const allBranch = response;
        getPageNoPurchase(response.current_page);
        getCountPage(response.total);

        console.log(response.data);
        getDataPurchase(allBranch);
      })
      .catch((error) => console.error(`Error: $(error)`));
  };

  useEffect(() => {
    getAllPruchase({
      startDate: startDate,
      endDate: endDate,
      status_po: statusPO,
      event: 'useEffect',
    });
  }, [startDate, endDate, statusPO]);

  const handleChangePage = (newPage) => {
    getPageNoPurchase(newPage);
    getAllPruchase({
      startDate: startDate,
      endDate: endDate,
      status_po: statusPO,
      event: 'handleChangePage',
    });
  };

  const handleDownloadFormat1 = async () => {
    try {
      const params = cleanParams({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        status: statusPO,
      });
      const data = await downloadListPOAOGFormat1(params);
      fileDownload(
        data,
        `Laporan-Registrasi-Upgrade-EGS-${moment(startDate).format(
          'YYYY-MM-DD'
        )} - ${moment(endDate).format('YYYY-MM-DD')}.xlsx`
      );
    } catch (e) {
      console.error(e.response.data.msg_str);
      showSnackbar(e.response.msg_str);
    }
  };

  const downloadOldClick = () => {};

  return (
    <div className={classes.root}>
      <div className={classes.row}></div>

      <div className={classes.row}>
        <FormSearchPurchaseOrder
          classes={classes}
          moment={moment}
          setIsOpen={setIsOpen}
          startDate={startDate}
          isOpen={isOpen}
          handleStartDate={handleSearchStart}
          setIsOpenEnd={setIsOpenEnd}
          endDate={endDate}
          isOpenEnd={isOpenEnd}
          handleEndDate={handleSearchEnd}
          handleSearch={handleSearch}
          formSearch={formSearch}
          rounds={props.rounds}
        />
      </div>

      <div className={classes.row}>
        <ListPurchaseOrder
          classes={classes}
          purchaseOrders={dataPurchase}
          propsHandleChangePage={(newPage) => handleChangePage(newPage)}
          downloadClick={() => handleDownloadFormat1()}
          downloadOldClick={() => downloadOldClick()}
        />
      </div>
    </div>
  );
}
