import React,{Fragment} from "react";
import {Grid} from "@material-ui/core";

const Customer = props => {
  const {
    swapHistory,
    classes
  } = props;
  return (
    <Fragment>
      <Grid container justify={"space-between"}>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} sm={6} xs={6} className={classes.nameField}>
              No Invoice
            </Grid>
            <Grid item xl={6} md={6} sm={6} xs={6}>
              : {swapHistory.no_invoice}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} sm={6} xs={6} className={classes.nameField}>
              Tanggal Tukar
            </Grid>
            <Grid item xl={6} md={6} sm={6} xs={6}>
              : {swapHistory.created_at}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} sm={6} xs={6} className={classes.nameField}>
              Total Gram Ditukar
            </Grid>
            <Grid item xl={6} md={6} sm={6} xs={6}>
              : {swapHistory.total_gram+' Gram'}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} sm={6} xs={6} className={classes.nameField}>
              Pic
            </Grid>
            <Grid item xl={6} md={6} sm={6} xs={6}>
              : {swapHistory.user? swapHistory.user.name : ''}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={4} md={4} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} sm={6} xs={6} className={classes.nameField}>
              Nama
            </Grid>
            <Grid item xl={6} md={6} sm={6} xs={6}>
              : {swapHistory.customer ? swapHistory.customer.name : ''}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} sm={6} xs={6} className={classes.nameField}>
              Tipe Customer
            </Grid>
            <Grid item xl={6} md={6} sm={6} xs={6}>
              : {
              swapHistory.customer
                ?
                (
                  swapHistory.customer.status === '2'
                    ? 'MOG'
                    : 'AOG'
                )
                : ''}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={6} md={6} sm={6} xs={6} className={classes.nameField}>
              No ID
            </Grid>
            <Grid item xl={6} md={6} sm={6} xs={6}>
              : {swapHistory.customer ? swapHistory.customer.id_agent : ''}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Customer;