import React, {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Loading} from '../../../components/UI';

import {
  Grid,
  Typography,
} from '@material-ui/core';
import {connect} from "react-redux";
import {isEmpty} from "../../../shared/utility";

import { getProduct } from '../../../actions/product'
import BeginningStockFormList from "./BeginningStockFormList";


const useStyles = makeStyles(theme => ({
  rootPaper: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  paperPadding: {
    marginBottom: theme.spacing(2)
  },
  btnCancel: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    fontWeight: "bold",
    '&:hover': {
      borderColor: theme.palette.error.light
    }
  },
  btnDraft: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
}));

const BeginningStockForm = props => {
  const classes = useStyles();
  const {getProduct, products, loading} = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      getProduct(0, "", 'gold');
    }, 1000)

    return () => clearTimeout(timer)
  },[getProduct]);

  let beginningStockFormList = '';
  if(!isEmpty(products)){
    beginningStockFormList = <BeginningStockFormList products={products} classes={classes} />
  }

  return (
    loading ?  <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div>
          <Grid container spacing={2} justify={"space-between"}>
            <Grid item>
              <Typography variant={"h5"} className={classes.tagMenu}>
                Stok Awal
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Paper className={classes.root}>
            <div className={classes.paperPadding}>
              <Typography className={classes.tagMenu} variant={"h5"}>History Stok Awal</Typography>
            </div>
            {beginningStockFormList}
          </Paper>
        </div>
      </div>
    </Fragment>)
}

const mapStateToProps = state => {
  return {
    products: state.product.products,
    loading: state.product.loadingProducts
  }
}

export default connect(mapStateToProps, {getProduct})(BeginningStockForm);