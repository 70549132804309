import { SET_ALERT, REMOVE_ALERT } from '../actions/types'

const initialState = []

const reducer = function (state = initialState, actions) {

    const { type, payload } = actions

    switch (type) {
        case SET_ALERT:
            return [...state, payload]
        case REMOVE_ALERT:
            return state.filter(alert => alert.id !== payload)
        default:
            return state
    }
}

export default reducer;