import React, {Fragment, useState} from "react";
import {Paper} from "@material-ui/core";
import {Dropzone} from '../../../../components/UI';
import imageCompression from 'browser-image-compression';

import * as actions from '../../../../actions';
import {connect} from "react-redux";
import {isEmpty} from "../../../../shared/utility";
import {Grid} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {Delete as DeleteIcon, Search} from '@material-ui/icons';
import Box from "@material-ui/core/Box";

const FormUploadReceipt = props => {
  const {
    classes,
    purchaseOrder,
    onUploadImage,
    urlImages,
    urlDocument,
    onDeleteImageRedux,
    paymentProofFileConfirmation,
    paymentProofFileShow,
    onUploadDocumentPO,
    onDeleteDocumentRedux,
    onAlert
  } = props;

  const [image, setImage] = useState('');
  // const [base64, setB64] = useState();

  const handleChangeBanner = event => {
    setImage(event)
    const imageFile = event[0];
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 512,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        let fileUpload = new File(
          [compressedFile],
          compressedFile.name,
          {
            type: compressedFile.type,
            path: compressedFile.name
          })
        return onUploadImage(fileUpload);
      })
      .catch(function (error) {
        // onAlert(error.message, 'error')
      });

  }

  const handleChangeDocument = event => {
    console.log(event[0].size);
    if(event[0].size < 2000000){
      onUploadDocumentPO(event[0]);
    }else{
      onAlert('File dokumen melebihi ukuran maksimal 2 mb', 'error')
    }

  }

  let imageUploadSukes = '';

  if (!isEmpty(urlImages)) {
    // console.log(assetImageUrl);
    imageUploadSukes = (
      <Fragment>
        {urlImages.map((image, index) => (
          <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.imageList}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={image.link}
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              <CardContent>
                <Typography variant="h5">
                  {image.caption}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonDelete}
                  startIcon={<DeleteIcon />}
                  onClick={() => onDeleteImageRedux(index)}
                  fullWidth
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Fragment>
    )
  }

  let documentUploadSukes = '';

  if (!isEmpty(urlDocument)) {
    // console.log(assetImageUrl);
    documentUploadSukes = (
      <Fragment>
        {urlDocument.map((image, index) => (
          <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.imageList}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={require('../../../../assets/images/ico-pdf.png')}
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              <CardContent>
                <Typography variant="h5">
                  {image.caption}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonDelete}
                  startIcon={<DeleteIcon />}
                  onClick={() => onDeleteDocumentRedux(index)}
                  fullWidth
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Fragment>
    )
  }
  // ico-pdf.png

  let paymentProofFile = '';

  if (!isEmpty(purchaseOrder.file_bukti_bayar)) {
    paymentProofFile = (
      <Fragment>
        {purchaseOrder.file_bukti_bayar.map((image, index) => (
          <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.imageList}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={
                    (image.link.substr((image.link.length - 4), 4) === '.png') ||
                    (image.link.substr((image.link.length - 4), 4) === '.jpg') ||
                    (image.link.substr((image.link.length - 5), 5) === '.jpeg')
                      ? image.link
                      : require('../../../../assets/images/ico-pdf.png')
                  }
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              <CardContent>
                <Typography variant="h5">
                  {image.caption}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonWarning}
                  startIcon={<Search />}
                  onClick={
                    (image.link.substr((image.link.length - 4), 4) === '.png') ||
                    (image.link.substr((image.link.length - 4), 4) === '.jpg') ||
                    (image.link.substr((image.link.length - 5), 5) === '.jpeg')
                    // image.link.substr((image.link.length - 4), 4) === '.png'
                      ? () => paymentProofFileShow(image)
                      : () => window.open(image.link, '_blank')
                  }
                  fullWidth
                >
                  Lihat
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Fragment>
    )
  }

  let photoUploadForm = (
    <Fragment>
      <div className={classes.row}>
        Upload Foto
      </div>
      <Dropzone multiple={false} fileType={'image/png,image/jpg,image/jpeg'} value={image} handleChangeBanner={handleChangeBanner} disabled={purchaseOrder.status_name !== "Pending" ? true : false} />
      <Grid container spacing={2} className={classes.row}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Fragment>
            <Grid container spacing={2}>
              {imageUploadSukes}
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </Fragment>
  );

  let documentUploadForm = (
    <Fragment>
      <div className={classes.row}>
        Upload Document
      </div>
      <Dropzone multiple={false} fileType={'application/pdf'} value={image} handleChangeBanner={handleChangeDocument} disabled={purchaseOrder.status_name !== "Pending" ? true : false} />
      <Grid container spacing={2} className={classes.row}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Fragment>
            <Grid container spacing={2}>
              {documentUploadSukes}
            </Grid>
          </Fragment>
        </Grid>
      </Grid>
    </Fragment>
  );

  let btnSubmit = (
    (
      <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} disabled fullWidth>
        Kirim
      </Button>
    )
);

  if (!isEmpty(urlImages)) {
    documentUploadForm = '';
    btnSubmit  = (
      <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} onClick={() => paymentProofFileConfirmation(urlImages,{status: 'bayar'})} fullWidth>
        Kirim
      </Button>
    )
  }

  if(!isEmpty(urlDocument)){
    photoUploadForm = '';
    btnSubmit  = (
      <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} onClick={() => paymentProofFileConfirmation(urlDocument,{status: 'bayar'})} fullWidth>
        Kirim
      </Button>
    )
  }


  return (
    <Fragment>
      <Paper>
      <div className={classes.paperContent}>
        {(purchaseOrder.status_name === 'Pending') ?
          (
            <Fragment>
              <div className={classes.tagMenu}>
                Upload Bukti Bayar
              </div>
              <div className={classes.row}>
                <form>
                  <div>
                    {photoUploadForm}
                  </div>
                  <div>
                    {documentUploadForm}
                  </div>
                  <Box display="flex">
                    <Box>
                      Note:
                    </Box>
                    <Box flexGrow={1} style={{paddingLeft:3}}>
                      Segera upload Foto/Dokumen bukti bayar jika sudah melakukan transfer pembayaran. Format PNG/JPG/JPEG/PDF maksimal 2mb.
                    </Box>
                    <Box>
                      {btnSubmit}
                    </Box>
                  </Box>
                </form>
              </div>
            </Fragment>
          )
          :
          (
            <Fragment>
              <div className={classes.tagMenu}>
                Foto Bukti Bayar
              </div>
              <div className={classes.row}>
                <Grid container spacing={2} className={classes.row}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Fragment>
                      <Grid container spacing={2}>
                        {paymentProofFile}
                      </Grid>
                    </Fragment>
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          )
        }

      </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.purchaseOrder.loadingUploadImage,
    urlImages: state.purchaseOrder.urlImage,
    urlDocument: state.purchaseOrder.urlDocument
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadImage: (file) => dispatch(actions.storePurchaseOrderFile(file)),
    onDeleteImageRedux: (index) => dispatch(actions.deleteImagePORedux(index)),
    onStorePurchaseOrderReceipt: (id, storeData, history) => dispatch(actions.storePurchaseOrderReceipt(id, storeData, history)),
    onUploadDocumentPO: (storeData) => dispatch(actions.uploadDocumentPurchaseOrder(storeData)),
    onDeleteDocumentRedux: (index) => dispatch(actions.deleteDocumentPORedux(index)),
    onAlert: (msg, status) => dispatch(actions.setAlert(msg, status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUploadReceipt);