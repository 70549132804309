/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Box,
} from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import DetailIcon from '@material-ui/icons/Search';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

import commissionService from '../../services/commission.service';
import { useSnackbar } from '../../shared/useSnackbar';
import { ChipsCommission } from '../../components';

const columns = [
  { id: 'no_transaction', label: 'No Transaksi', minWidth: 100 },
  { id: 'date_transaction', label: 'Tanggal Transaksi', minWidth: 100 },
  { id: 'type_transaction', label: 'Jenis Transaksi', minWidth: 100 },
  { id: 'name', label: 'Nama', minWidth: 100 },
  { id: 'commision', label: 'Komisi', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100 },
  { id: 'finish_date', label: 'Tanggal Claim/Selesai', minWidth: 100 },
];

const CommissionUpgrade = () => {
  const classes = useStyles();
  const history = useHistory();
  const { createSnackbar } = useSnackbar();

  const [dataTable, setDataTable] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  const [startDate, setStartDate] = useState({
    startDate: new Date(),
  });
  const [endDate, setEndDate] = useState({
    endDate: new Date(),
  });
  const [formSearch, setFormSearch] = useState({
    status: '',
    search: '',
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).add(1, 'days').format('YYYY-MM-DD'),
  });

  const handleStartDate = (input) => {
    setStartDate((startDate) => ({
      ...startDate,
      startDate: input,
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(input).format('YYYY-MM-DD'),
    }));
  };

  const handleEndDate = (input) => {
    setEndDate((endDate) => ({
      ...endDate,
      endDate: input,
    }));
    setFormSearch(() => ({
      ...formSearch,
      end_date: moment(input).format('YYYY-MM-DD'),
    }));
  };

  const handleSearch = (event) => {
    const target = event.target.name;
    event.persist();
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value,
    }));
  };

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      message: msg,
      dismissable: false,
      theme: theme,
      sticky: false,
    });
  };

  const getLishCommission = async () => {
    try {
      const data = await commissionService.list({
        start_date: formSearch.start_date,
        end_date: formSearch.end_date,
        nama: formSearch.search,
      });
      setDataTable(data);
    } catch (e) {
      console.error(e.response.data.message);
      showSnackbar(e.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getLishCommission();
  }, [formSearch.search, formSearch.start_date, formSearch.end_date]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Grid container spacing={2} justifyContent='space-between'>
          <Grid item>
            <Typography variant='h4'>
              <b>Komisi Upgrade</b>
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.row}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TextField
                onClick={() => setIsOpen(true)}
                value={moment(startDate.startDate).format('dddd DD MMMM YYYY')}
                fullWidth
                className={classes.margin}
                label='Tanggal Awal'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' disablePointerEvents>
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
              />
              <DatePicker
                fullWidth
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                disableFuture
                variant='outlined'
                name='start_date'
                format='dd MMMM yyyy HH:mm'
                value={startDate.startDate}
                onChange={(e) => handleStartDate(e)}
                TextFieldComponent={() => null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={4} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <TextField
                onClick={() => setIsOpenEnd(true)}
                value={moment(endDate.endDate).format('dddd DD MMMM YYYY')}
                fullWidth
                className={classes.margin}
                label='Tanggal Akhir'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end' disablePointerEvents>
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
              />
              <DatePicker
                fullWidth
                open={isOpenEnd}
                onOpen={() => setIsOpenEnd(true)}
                onClose={() => setIsOpenEnd(false)}
                disableFuture
                variant='outlined'
                name='end_date'
                format='dd MMMM yyyy HH:mm'
                value={endDate.endDate}
                onChange={handleEndDate}
                TextFieldComponent={() => null}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              label='Nama'
              name='search'
              onChange={handleSearch}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.row}>
        <div className={classes.row}>
          <Paper className={classes.paperContent}>
            <Box marginBottom={2}>
              <Typography variant='h5'>History Upgrade</Typography>
            </Box>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                aria-label='sticky table'
                style={{ minWidth: '340px' }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable?.data.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.invoice_id}</TableCell>
                      <TableCell>
                        {moment(item.created_at).format('dddd, DD MMMM YYYY')}
                      </TableCell>
                      <TableCell>{item.payment_type}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.payment}</TableCell>
                      <TableCell>
                        <ChipsCommission status={item.status} />
                      </TableCell>
                      <TableCell>
                        <Tooltip title='Detail Purchase Order'>
                          <IconButton
                            aria-label='detail'
                            onClick={() =>
                              history.push(`/commission-upgrade/${item.id}`)
                            }
                          >
                            <DetailIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{item.claimed_at}</TableCell>
                    </TableRow>
                  ))}
                  {dataTable?.data.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Typography align='center'>Tidak ada data   </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default CommissionUpgrade;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paperContent: {
    padding: theme.spacing(3),
  },
  cardDashboard: {
    border: '1px solid #5A5A5A',
    height: '100px',
    padding: theme.spacing(1),
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textDebit: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
}));
