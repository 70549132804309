import { getApi, putApi } from '../shared/api';

const getUpgrade = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/mog/upgrade/list`;

  return getApi(endpoint, params).then((response) => {
    return response;
  });
};

const getUpgradeDetail = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/mog/upgrade/detail`;

  return getApi(endpoint, params).then((response) => {
    return response;
  });
};

const updateStatusUpgrade = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/mog/upgrade/status`;

  return putApi(endpoint, params).then((response) => {
    return response;
  });
};

const upgradeService = {
  getUpgrade,
  getUpgradeDetail,
  updateStatusUpgrade,
};
export default upgradeService;
