import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {    
    Grid, 
    TextField,   
    InputLabel,
    FormControl,
    FormHelperText,
} from '@material-ui/core'
import { Formik, Field, FastField, Form } from 'formik';

class TextBox extends Component {
    constructor(props) {
        super(props);
    }

    

    render() {
        return (
            <Grid 
            item
            lg={this.props.lg?this.props.lg:6}
            md={this.props.md?this.props.md:6}
            sm={this.props.sm?this.props.sm:6}
            xs={this.props.xs?this.props.xs:12}
        >
            <FormControl
                                    error={this.props.iserror ? true : false}
                                    className={this.props.formControl} fullWidth
                                >
            
            <TextField 
                {...this.props}
                fullWidth
                label={this.props.label}
                className={this.props.textInput}
                name={this.props.name}
                id={this.props.id}
                defaultValue={this.props.value}
                variant= { this.props.variant || 'standard' }
                InputProps={{
                    readOnly: this.props.isReadOnly,
                  }}
                onBlur={this.props.onChange}
                type={ this.props.type || 'text' }
                helperText={this.props.helperText}
                error={this.props.error}
                inputRef={this.props.inputref}
            />
            <FormHelperText>{this.props.helperText && this.props.error.message}</FormHelperText>
            </FormControl>
        </Grid>
        );
    }
}

TextBox.propTypes = {

};

export default TextBox;



                            