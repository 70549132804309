import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Login, ResetPasswordLayout } from './layouts';

import {
  Dashboard as DashboardView,
  CashierBuyback as CashierBuybackView,
  Customer as CustomerView,
  CreateCustomer as CreateCustomerView,
  EditCustomer as EditCustomerView,
  Transaction as TransactionView,
  DetailTransaction as DetailTransactionView,
  StockOpname as StockOpnameView,
  Profile as ProfileView,
  TransactionBuyback as TransactionBuybackView,
  DetailTransactionBuyback as DetailTransactionBuybackView,
  StockHistory as StockHistoryView,
  OtherPurchaseOrder as OtherPurchaseOrderView,
  CreateOtherPurchaseOrder as CreateOtherPurchaseOrderView,
  DetailStock as DetailStockView,
  FormChangePassword as FormChangePasswordView,
  ResetPassword as ResetPasswordView,
  LandingPageResetPassword as LandingPageResetPasswordView,
  Member as MemberView,
  BeginningStock as BeginningStockView,
  BeginningStockForm as BeginningStockFormView,
  BeginningStockFormDetail as BeginningStockFormDetailView,
  CashierV2 as CashierV2View,
  PurchaseOrderV2 as PurchaseOrderV2View,
  CreatePurchaseOrderV2 as CreatePurchaseOrderV2View,
  DetailPurchaseOrder as DetailPurchaseOrderView,
  SwapGramation as SwapGramationView,
  CreateSwapGramation as CreateSwapGramationView,
  DetailSwapGramation as DetailSwapGramationView,
  ApprovalPOAOG as ApprovalPOAOGView,
  ApprovalPOAOGDetail as ApprovalPOAOGDetailView,
  ApprovalPO as ApprovalPOView,
  DetailPurhcaseOrder as DetailPurhcaseOrderView,
  ApprovalPOUpgrade as ApprovalPOUpgradeView,
  ApprovalPOUpgradeDetail as ApprovalPOUpgradeDetailView,
  CommissionUpgrade as CommissionUpgradeView,
  CommissionUpgradeDetail as CommissionUpgradeDetailView,
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from='/' to='/dashboard' />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path='/dashboard'
      />

      <RouteWithLayout
        component={ProfileView}
        exact
        layout={MainLayout}
        path='/profile'
      />

      <RouteWithLayout
        component={CashierV2View}
        exact
        layout={MainLayout}
        path='/cashier-v2'
      />

      <RouteWithLayout
        component={CashierBuybackView}
        exact
        layout={MainLayout}
        path='/cashier-buyback'
      />

      <RouteWithLayout
        component={MemberView}
        exact
        layout={MainLayout}
        path='/member'
      />

      <RouteWithLayout
        component={CustomerView}
        exact
        layout={MainLayout}
        path='/customer'
      />

      <RouteWithLayout
        component={CreateCustomerView}
        exact
        layout={MainLayout}
        path='/customer/create'
      />

      <RouteWithLayout
        component={EditCustomerView}
        exact
        layout={MainLayout}
        path='/customer/edit/:id'
      />

      <RouteWithLayout
        component={CreatePurchaseOrderV2View}
        layout={MainLayout}
        path='/purchase-order-v2/create'
      />

      <RouteWithLayout
        component={DetailPurchaseOrderView}
        layout={MainLayout}
        path='/purchase-order-v2/:id'
      />

      <RouteWithLayout
        component={PurchaseOrderV2View}
        layout={MainLayout}
        path='/purchase-order-v2'
      />

      <RouteWithLayout
        component={TransactionView}
        exact
        layout={MainLayout}
        path='/report/selling'
      />

      <RouteWithLayout
        component={DetailTransactionView}
        exact
        layout={MainLayout}
        path='/report/selling/detail/:id'
      />

      <RouteWithLayout
        component={TransactionBuybackView}
        exact
        layout={MainLayout}
        path='/report/buyback'
      />

      <RouteWithLayout
        component={DetailTransactionBuybackView}
        exact
        layout={MainLayout}
        path='/report/buyback/detail/:id'
      />

      <RouteWithLayout
        component={BeginningStockView}
        exact
        layout={MainLayout}
        path='/beginning-stock'
      />

      <RouteWithLayout
        component={BeginningStockFormView}
        exact
        layout={MainLayout}
        path='/beginning-stock/create'
      />

      <RouteWithLayout
        component={BeginningStockFormDetailView}
        exact
        layout={MainLayout}
        path='/beginning-stock/:id'
      />

      <RouteWithLayout
        component={StockOpnameView}
        exact
        layout={MainLayout}
        path='/stock-opname'
      />

      <RouteWithLayout
        component={StockHistoryView}
        exact
        layout={MainLayout}
        path='/stock-history'
      />

      <RouteWithLayout
        component={DetailStockView}
        exact
        layout={MainLayout}
        path='/stock-history/detail/:id'
      />

      <RouteWithLayout
        component={SwapGramationView}
        exact
        layout={MainLayout}
        path='/swap-gramation'
      />

      <RouteWithLayout
        component={CreateSwapGramationView}
        exact
        layout={MainLayout}
        path='/swap-gramation/form'
      />

      <RouteWithLayout
        component={DetailSwapGramationView}
        exact
        layout={MainLayout}
        path='/swap-gramation/:id'
      />

      <RouteWithLayout
        component={OtherPurchaseOrderView}
        exact
        layout={MainLayout}
        path='/other-purchase-order'
      />

      <RouteWithLayout
        component={CreateOtherPurchaseOrderView}
        exact
        layout={MainLayout}
        path='/other-purchase-order/create/:id'
      />

      <RouteWithLayout
        component={FormChangePasswordView}
        exact
        layout={MainLayout}
        path='/change-password'
      />

      <Route
        component={ResetPasswordView}
        exact
        layout={ResetPasswordLayout}
        path='/reset-password'
      />

      <Route
        component={LandingPageResetPasswordView}
        exact
        layout={ResetPasswordLayout}
        path='/reset-password/landing'
      />

      <Route
        component={Login}
        exact
        // layout={Login}
        path='/sign-in'
      />

      <RouteWithLayout
        component={ApprovalPOView}
        exact
        layout={MainLayout}
        path='/approval-po'
      />

      <RouteWithLayout
        component={DetailPurhcaseOrderView}
        exact
        layout={MainLayout}
        path='/detail-approval-po/:id'
      />

      <RouteWithLayout
        component={ApprovalPOAOGView}
        exact
        layout={MainLayout}
        path='/approval-po-aog'
      />

      <RouteWithLayout
        component={ApprovalPOAOGDetailView}
        exact
        layout={MainLayout}
        path='/approval-po-aog/:id'
      />

      <RouteWithLayout
        component={ApprovalPOUpgradeView}
        exact
        layout={MainLayout}
        path='/approval-po-upgrade'
      />

      <RouteWithLayout
        component={ApprovalPOUpgradeDetailView}
        exact
        layout={MainLayout}
        path='/approval-po-upgrade/:id'
      />

      <RouteWithLayout
        component={CommissionUpgradeView}
        exact
        layout={MainLayout}
        path='/commission-upgrade'
      />

      <RouteWithLayout
        component={CommissionUpgradeDetailView}
        exact
        layout={MainLayout}
        path='/commission-upgrade/:id'
      />

      {/* <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      /> */}

      <Redirect to='/not-found' />
    </Switch>
  );
};

export default Routes;
