import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    Card,
    CardHeader,
    CardContent,
  } from "@material-ui/core";

class CustomCard extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <Card className={this.props.classes}>
            <CardHeader title={this.props.title} /> 
            <CardContent>
                    {this.props.children}
            </CardContent>
            </Card>            
        );
    }
}

CustomCard.propTypes = {

};

export default CustomCard;