import React, {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Loading, TablePaginationActions} from '../../../components/UI';
import * as actions from '../../../actions';
import BeginningStockData from './BeginningStockData';

import {useHistory} from 'react-router-dom';

import {
  TableFooter,
} from '@material-ui/core';
import {connect} from "react-redux";
import {isEmpty} from "../../../shared/utility";

const columns = [
  { id: 'no', label: 'No', minWidth: '5%' },
  { id: 'tanggal', label: 'Tanggal', minWidth: '18%' },
  { id: 'pic', label: 'PIC', minWidth: '18%' },
  { id: 'total_stok', label: 'Total Stok', minWidth: '18%' },
  { id: 'total', label: 'Total', minWidth: '18%' },
  { id: 'status', label: 'Status', minWidth: '18%' },
  { id: 'detail', label: 'Detail', minWidth: '5%' },

];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const BeginningStockHistories = props => {
  const classes = useStyles();
  const history = useHistory();
  const {onFetchBeginningStock,beginningStock,loading} = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchBeginningStock(1);
    }, 1000)

    return () => clearTimeout(timer)
  },[onFetchBeginningStock]);

  const handleChangePage = (event, newPage) => {
    // onFetchMembers(newPage, formSearch);
    onFetchBeginningStock(newPage)
  }

  let tableBody = '';

  if(!isEmpty(beginningStock)){
    const page = beginningStock.beginning_stock.current_page - 1;
    const rowsPerPage = 10;
    const countRows = beginningStock.beginning_stock.total
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, countRows - page * rowsPerPage)
    // console.log(page, rowsPerPage, countRows, emptyRows);

    tableBody = (
      <Fragment>
        <TableBody>
          {beginningStock.beginning_stock.data.map((beginningStockData, index) => (
            <BeginningStockData
              beginningStock={beginningStockData}
              key={beginningStockData.id}
              index={index}
              from={beginningStock.beginning_stock.from}
              detail={() => history.push(`/beginning-stock/${beginningStockData.id}`)}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}

          {/*{emptyRows > 0 && (*/}
          {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
          {/*    <TableCell colSpan={6} />*/}
          {/*  </TableRow>*/}
          {/*)}*/}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    loading ?  <Loading/> :
    <Fragment>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>)

}

const mapStateToProps = state => {
  return {
    beginningStock: state.beginningStock.beginning_stock,
    loading: state.beginningStock.loading
  };
};

const mapDispatchToProps = dispatch => {
  return{
    onFetchBeginningStock: (page) => dispatch(actions.fetchBeginningStock(page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BeginningStockHistories);