import React, {Fragment, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import * as actions from '../../../../../actions';
import {connect} from "react-redux";
import moment from "moment";
import {Loading} from "../../../../../components/UI";
import {isEmpty} from "../../../../../shared/utility";
import FormProductList from "./FormProductList/FormProductList";
import {SwapHoriz} from "@material-ui/icons";

const ExchangeGoldFrom = props => {
  const {
    classes,
    onFetchProducts,
    products,
    loading,
    startDate,
    searchCustomerClear,
    product_type,
    product_unit,
    listExchangeGoldFrom,
    getListExchangeGoldFrom,
    setCartExchangeGoldFrom,
    cartExchangeGoldFrom
  } = props;

  const newDate = moment(startDate.startDate)
  const dateToUTC = moment.utc(newDate).format('YYYY-MM-DD HH:mm:ss');

  useEffect(() => {
    onFetchProducts(searchCustomerClear[0].status, dateToUTC, product_type,product_unit)
  }, [onFetchProducts, searchCustomerClear, dateToUTC, product_type,product_unit])

  let formProductList = '';
  if(!isEmpty(products)){
    formProductList = <FormProductList
      products={products}
      classes={classes}
      listExchangeGoldFrom={listExchangeGoldFrom}
      getListExchangeGoldFrom={(data) => getListExchangeGoldFrom(data)}
      setCartExchangeGoldFrom={setCartExchangeGoldFrom}
      cartExchangeGoldFrom={cartExchangeGoldFrom}
      // getCartQty={(stateData) =>sendCartQty(stateData)}
    />;
  }

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.row}>
        <Paper className={classes.root}>
          <div className={classes.paperPadding}>
            <div className={classes.dispayFlex}>
              <div className={classes.icon}>
                <SwapHoriz/>
              </div>
              <div className={classes.tagMenu}>
                Tukar Dari
              </div>
            </div>
          </div>
          {formProductList}
        </Paper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.product.loadingProducts,
    products: state.product.products,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchProducts: (type, date, product_type,product_unit) => dispatch(actions.getProduct(type, date, product_type,product_unit))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ExchangeGoldFrom);