import React, { Fragment } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import NumberFormat from "react-number-format";

const ListOrder = (props) => {
  const { data, index } = props;
  return (
    <Fragment>
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{data.product_name} {data.weight} {data.unit}</TableCell>
        <TableCell>
          <NumberFormat
            value={data.quantity}
            displayType={"text"}
            thousandSeparator={true}
          />
        </TableCell>
        <TableCell>
          <NumberFormat
            value={data.sell_price}
            displayType={"text"}
            prefix={"Rp "}
            thousandSeparator={true}
          />
        </TableCell>
        <TableCell>
          <div
            style={{
              backgroundColor: data.stock < data.quantity ? "red" : "green",
              borderRadius: "15px",
              color: "#fff",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {`Tersedia: ${data.stock}`}
          </div>
        </TableCell>
        <TableCell>
          <NumberFormat
            value={data.sell_price * data.quantity}
            displayType={"text"}
            prefix={"Rp "}
            thousandSeparator={true}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default ListOrder;
