import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { registerServiceWorker } from './register-sw';

// Sentry.init({
//   dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 0.0,
//   environment: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}`,
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
registerServiceWorker();
