import axios from 'axios'
import { setAlert } from './alert'
import { 
    EDIT_PROFILE,
    UPDATE_PROFILE,
    GET_PROFILE,
} from './types'

import * as types from './types';

export const getProfile = () => async dispatch => {
    //REACT_APP_AUTH_BASE_URL
    //REACT_APP_BASE_URL
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/profile`
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: GET_PROFILE,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_PROFILE,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const editProfile = (formData, base64, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/profile`
    const myData = {
        name : formData.name,
        image: base64
    }
    dispatch({
        type: EDIT_PROFILE,
    })
    try {
        const res = await axios({
            url: endpoint,
            method: "PATCH",
            data: myData,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${sessionStorage.getItem('access_token')}`
            }
        });
        dispatch({
            type: UPDATE_PROFILE,
            payload: res.data
        })

        dispatch(setAlert("Profile Edited", "success"))
        history.push(`/dashboard`);
    } catch (error) {
        dispatch(setAlert("Something went wrong", "error"))
        console.log(error)
        dispatch({
            type: UPDATE_PROFILE,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const changePasswordStart = () => {
    return {
        type: types.CHANGE_PASSWORD_START
    }
}

export const changePasswordSuccess = () => {
    return {
        type: types.CHANGE_PASSWORD_SUCCESS
    }
}

export const changePasswordFail= (error) => {
    return {
        type: types.CHANGE_PASSWORD_FAIL,
        error: error
    }
}

export const changePassword= (storeData, token) => {
    return dispatch => {
        dispatch(changePasswordStart())
        axios.post(`${process.env.REACT_APP_BASE_URL}/user/profile/change_password`, storeData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => {
            dispatch(changePasswordSuccess())
            dispatch(setAlert('sukses ubah password', 'success'))
        })
        .catch(err => {
            dispatch(changePasswordFail(err))
            dispatch(setAlert('gagal ubah password', 'error'))
        })
    }
}

export const resetPasswordStart = () => {
    return {
        type: types.FORGOT_PASSWORD_START
    }
}

export const resetPasswordSuccess = () => {
    return {
        type: types.FORGOT_PASSWORD_SUCCESS
    }
}

export const resetPasswordFail= (error) => {
    return {
        type: types.FORGOT_PASSWORD_FAIL,
        error: error
    }
}

export const resetPassword= (storeData, history) => {
    return dispatch => {
        dispatch(resetPasswordStart())
        axios.post(`${process.env.REACT_APP_BASE_URL}/reset_password`, storeData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
        .then(res => {
            if(res.status === 200){
                dispatch(resetPasswordSuccess())
                dispatch(setAlert('Email telah terikim', 'success'))
                history.push('/sign-in')
            }else{
                dispatch(resetPasswordSuccess())
                dispatch(setAlert('Email tidak ditemukan', 'success'))
                history.push('/sign-in')
            }
        })
        .catch(err => {
            dispatch(resetPasswordFail(err))
            dispatch(setAlert('gagal ubah password', 'error'))
        })
    }
}