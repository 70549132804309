import React, {Fragment, useState, useEffect} from "react";
import {Grid, makeStyles} from '@material-ui/core'
import SearchCustomer from "./SearchCustomer";

import { connect } from 'react-redux'
import { getSearchCustomerAndClear } from '../../actions/customer'
import { onClearProducts } from '../../actions/product'
import moment from 'moment';
import {Loading} from "../../components/UI";
import FormProduct from "./FormProduct";
import {isEmpty} from "../../shared/utility";
import CartDetail from "./CartDetail";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tagMenu: {
    fontWeight: "bold"
  },
  btnCancel: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    fontWeight: "bold",
    '&:hover': {
      borderColor: theme.palette.error.light
    }
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  buttonWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    }
  },
  searchRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    // padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  }
}))

const CashierV2 = props => {
  const classes = useStyles();

  const {
    getSearchCustomerAndClear,
    onClearProducts,
    customer : {
      searchCustomerClear,
      loadingCustomerClear
    }
  } = props

  const [ valueSearch, setValueSearch ] = useState({
    type: 'id_agent',
    name: ''
  })

  const submitDefault = moment().format('YYYY-MM-DD HH:mm:ss')
  const minDate = moment().subtract(29, 'd').format('YYYY-MM-DD HH:mm:ss')
  const [ startDate, setStartDate ] = useState({
    submit: {
      submit: submitDefault
    },
    view: {
      view: new Date()
    }

  });

  const reset = () => {
    // setStartDate(startDate => ({
    //   ...startDate,
    //   startDate: new Date()
    // }));
    // setFormSearch(() => ({
    //   ...formSearch,
    //   start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    // }));
    setStartDate(startDate => ({
      ...startDate,
      submit: {
        submit: new Date()
      },
      view: {
        view: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }
    }));
  }

  const handleStartDate = (date) => {
    const changeDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    setStartDate(startDate => ({
      ...startDate,
      submit: {
        submit: changeDate
      },
      view: {
        view: date
      }
    }));
  };

  useEffect(() => {
    getSearchCustomerAndClear('name', '')
    onClearProducts()
  }, [ getSearchCustomerAndClear, startDate, onClearProducts]);

  const [cartQtyValue, setCartQtyValue] = useState('');

  const getCartQty = stateData => {
    setCartQtyValue(stateData);
    // console.log(stateData);
  }

  // console.log(cartQtyValue);
  let cartTotalView = '';

  if (!isEmpty(cartQtyValue)){
    cartTotalView = <CartDetail cartQty={cartQtyValue} searchCustomerClear={searchCustomerClear[0]} date={startDate.submit.submit} classes={classes}/>;
  }

  return (loadingCustomerClear || searchCustomerClear === null ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.tagMenu}>
          Penjualan
        </div>

        <Grid container spacing={2} className={classes.row}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div>
              <SearchCustomer
                startDate={startDate}
                handleStartDate={handleStartDate}
                valueSearch={valueSearch}
                searchCustomerClear={searchCustomerClear}
                setValueSearch={setValueSearch}
                minDate={minDate}
                reset={() => reset()}
              />
            </div>

            {!loadingCustomerClear && searchCustomerClear.length === 1 && (
              <div className={classes.row}>
                <FormProduct searchCustomerClear={searchCustomerClear[0]} date={startDate.submit.submit} product_type={"gold"} classes={classes} getCartQty={(stateData) => getCartQty(stateData)}/>
              </div>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            {!loadingCustomerClear && searchCustomerClear.length === 1 && (
              <Fragment>
                {cartTotalView}
              </Fragment>
            )}
          </Grid>
        </Grid>

      </div>

    </Fragment>
  );
};

const mapStateToProps = state => ({
  customer: state.customer
})

export default connect(mapStateToProps,{getSearchCustomerAndClear, onClearProducts})(CashierV2);