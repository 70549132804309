import React, { useState } from 'react'
import {makeStyles, useTheme} from '@material-ui/styles'
import {
    Grid,
    Typography,
    // Fab,
    // Badge,
    SwipeableDrawer,
	Button,
	// CardActionArea,
	CardContent,
	Card,
	// CardActions,
	CardHeader,
	IconButton,
	Divider,
	Paper
} from '@material-ui/core'
import moment from 'moment';
import CalendarIcon from '@material-ui/icons/CalendarToday'
import AddUserIcon from '@material-ui/icons/PersonAdd'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import ProductCard from './ProductCard'
// import Cart from '../Cart'
import SearchCustomer from './SearchCustomer'
import CounterSlice from '../Product/CounterSlice'
// Redux
import { connect } from 'react-redux'
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    content: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
	},
	contentProduct: {
		width: 'auto',
		height: '630px',
		margin: theme.spacing(2)
	},
	contentSearchCustomer: {
		height: '600px',
		margin: theme.spacing(2),
		overflow: 'auto'
	},
    fixedComponents:{
		// paddingTop: 100
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
	},
	searchRoot: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
			padding: 10,
	},
	divider: {
			height: 28,
			margin: 4,
	},
	dividerHorizontal: {
		marginTop: 10,
	},
	drawerBottom: {
		height: '600px'
	}

}))

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`,
	};
}

const MobileView = props => {
	const classes = useStyles()
	const { 
		searchCustomerClear, 
		loadingCustomerClear 
	} = props
	
	// Modal Search
	const [ searchModalOpen, setSearchModalOpen ] = useState(false)

	const handleSearchModalOpen = () => {
		setSearchModalOpen(true)
	}

	const handleSearchModalClose = () => {
		setSearchModalOpen(false)
	}
	// End Search
	// QTY Modal
	const [ qtyModalOpen, setQtyModalOpen ] = useState(false)
	const [ item, setItem ] = useState()

	const handleQtyModalOpen = (event) => {
		setQtyModalOpen(true)
		setItem(event)
	}

	const handleQtyModalClose = () => {
		setQtyModalOpen(false)
	}
	// End QTY

	// const [ formState ] = useState({
	// 	params: '',
	// 	kata_kunci: ''
	// })

	const submitDefault = moment().format('YYYY-MM-DD HH:mm:ss');
	const minDate = moment().subtract(2, 'd').format('YYYY-MM-DD HH:mm:ss')
	const [ startDate, setStartDate ] = useState({
        submit: {
            submit: submitDefault
        },
        view: {
            view: new Date()
        }
        
    });
    const handleStartDate = (date) => {
        const changeDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
        setStartDate(startDate => ({
            ...startDate,
                submit: {
                    submit: changeDate
            },
                view: {
                    view: date
            }
        }));
	};

	const theme = useTheme();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = (index) => {
		setValue(index);
	};

	// useEffect(() => {
	// 	getSearchCustomerAndClear(formState.params, formState.kata_kunci)
	// }, [ getSearchCustomerAndClear, formState])

    return(
    <>
        <div className={classes.content}>
            <Grid
				container
				spacing={3}
				justify="space-between"
            >
                <Grid item>  
                    <Typography variant="h4">Transaksi Penjualan</Typography>
                </Grid>
            </Grid>
			<Grid container spacing={2}>
				<Grid 
					item
					lg={4}
					md={6}
					sm={6}
					xs={12}
				>
					<Typography>Tanggal</Typography>
					<div className={classes.row}>
						<Paper component="form" className={classes.searchRoot}>
							<IconButton type="button" className={classes.iconButton} aria-label="search">
								<CalendarIcon />
							</IconButton>
							<Divider className={classes.divider} orientation="vertical" />
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DateTimePicker
									fullWidth
									disableFuture
									minDate={minDate}
									ampm={false}
									variant="outlined"
									name="start_date"
									format="dd MMMM yyyy HH:mm"
									value={startDate.view.view} 
									onChange={handleStartDate} 
								/>
							</MuiPickersUtilsProvider>
						</Paper>
					</div>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={3}
				justify="space-between"
            >
				<Grid item>  
				{!loadingCustomerClear && (
					<div>
						{searchCustomerClear.length === 0 ? (
							<Button
								variant="outlined"
								color="secondary"
								startIcon={<AddUserIcon />}
								onClick={handleSearchModalOpen}
							>
								Cari Customer
							</Button>
						):(
							<div>
								<Button
									variant="outlined"
									color="secondary"
									startIcon={<AddUserIcon />}
									onClick={handleSearchModalOpen}
								>
									Cari Customer
								</Button>
								{searchCustomerClear.map((item) => (
									<div>
										<Typography>Customer : {item.name}</Typography>
										<Typography>Tipe Anggota : {item.name_status}</Typography>
									</div>
								))}
							</div>
		
						)}
					</div>
				)}
                </Grid>
            </Grid>
			
		</div>
		<hr className={classes.dividerHorizontal} />
		<div className={classes.contentProduct}>
			{!loadingCustomerClear && (
				<>
				{searchCustomerClear.length > 0 && (
					// <PerfectScrollbar>
					<>
						<AppBar position="static" color="default">
							<Tabs
								value={value}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="fullWidth"
								aria-label="full width tabs example"
							>
								<Tab label="EOA GOLD" {...a11yProps(0)} />
								<Tab label="Merchandise" {...a11yProps(1)} />
							</Tabs>
						</AppBar>
						<SwipeableViews
							axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={value}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel value={value} index={0} dir={theme.direction}>
								<ProductCard handleQtyModalOpen={handleQtyModalOpen} date={startDate.submit.submit} product_type={"gold"}/>
							</TabPanel>
							<TabPanel value={value} index={1} dir={theme.direction}>
								<ProductCard handleQtyModalOpen={handleQtyModalOpen} date={startDate.submit.submit} product_type={"other"}/>
							</TabPanel>
						</SwipeableViews>
					</>
					// </PerfectScrollbar>
				)}
				</>
			)}
        </div>
        <div className={classes.fixedComponents}>
			
			<SwipeableDrawer
				anchor='bottom'
				open={searchModalOpen}
				onClose={handleSearchModalClose}
				onOpen={handleSearchModalOpen}
				disableSwipeToOpen
				className={classes.drawerBottom}
			>
				<Card className={classes.contentSearchCustomer}>
					<CardHeader title="Cari Customer" />
					<CardContent>
						<SearchCustomer handleSearchModalClose={handleSearchModalClose} />
					</CardContent>
					{/* <CardActions>
						<Button fullWidth variant="contained" onClick={handleSearchModalClose} color="primary" size="small">
							Terapkan
						</Button>
					</CardActions> */}
				</Card>
			</SwipeableDrawer>
			<SwipeableDrawer
				anchor='bottom'
				open={qtyModalOpen}
				onClose={handleQtyModalClose}
				onOpen={handleQtyModalOpen}
				disableSwipeToOpen
			>
				{!loadingCustomerClear && (
					<CounterSlice handleModalClose={handleQtyModalClose} date={startDate.submit.submit} product={item} searchCustomerClear={searchCustomerClear[0]} />
				)}
			</SwipeableDrawer>
        </div>
    </>
    )
}

const mapStateToProps = state => ({
	customer: state.customer,
})

export default connect(mapStateToProps)(MobileView)