import React,{Fragment} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {isEmpty} from "../../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import NumberFormat from "react-number-format";
import ExchangeGoldToData from "./ExchangeGoldToData/ExchangeGoldToData";

const columns = [
  { id: 'no', label: 'No', minWidth: '3%' },
  // { id: 'foto_produk', label: 'Foto Produk', minWidth: '16%' },
  { id: 'item_produk', label: 'Item Produk', minWidth: '16%' },
  { id: 'stock', label: 'Harga Hpp', minWidth: '16%' },
  { id: 'harga', label: 'Weight', minWidth: '16%' },
  { id: 'kuantitas', label: 'Qty', minWidth: '16%' },
  { id: 'Subtotal', label: 'Subtotal Item Gram', minWidth: '16%' },
  { id: 'SubtotalBuyBack', label: 'Subtotal Harga Hpp', minWidth: '16%' },
];

const ExchangeGoldTo = props => {
  const {
    classes,
    swapHistory
  } = props;

  let tableBody = '';

  if(!isEmpty(swapHistory)){
    tableBody = (
      <Fragment>
        <TableBody>
          {swapHistory.to.filter(({qty}) => qty > 0).map((product, index) => (
            <ExchangeGoldToData
              product={product}
              key={product.id}
              index={index}
              classes={classes}
              // register={register}
              // errors={errors}
            />
          ))}
          <TableRow>
            <TableCell colSpan={5}/>
            <TableCell>
              {!isEmpty(swapHistory.to) ? <NumberFormat style={{fontWeight: "bold"}} value={swapHistory.to.map(item => parseFloat(item.subtotal_gram)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} suffix={" gram"} decimalScale={2}/> : 0}
            </TableCell>
            <TableCell>
              {!isEmpty(swapHistory.to) ? <NumberFormat style={{fontWeight: "bold"}} value={swapHistory.to.map(item => parseInt(item.subtotal_price)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/> : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div>
        Tukar Ke
      </div>
      <div className={classes.row}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

export default ExchangeGoldTo;