import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchRoundsStart = () => {
  return {
    type: actionTypes.FETCH_ROUNDS_START
  }
}

export const fetchRoundsSuccess = (rounds) => {
  return {
    type: actionTypes.FETCH_ROUNDS_SUCCESS,
    rounds: rounds
  }
}

export const fetchRoundsFail = (error) => {
  return {
    type: actionTypes.FETCH_ROUNDS_FAIL,
    error: error
  }
}

export const fetchRounds = () => {
  return dispatch => {
    dispatch(fetchRoundsStart())
    axios.get(`user/round/all`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchRoundsSuccess(res.data.data))
      })
      .catch(err => {
        dispatch(fetchRoundsFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}