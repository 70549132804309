import React,{Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Chip, IconButton, Tooltip} from "@material-ui/core";
import DetailIcon from "@material-ui/icons/Search";
import NumberFormat from "react-number-format";
// import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";

const useStyle = makeStyles(theme => ({
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipDraft: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white
  }
}))

const BeginningStockData = props => {
  const {beginningStock,index,from} = props;
  const classes = useStyle();

  const selesai = <Chip label="Selesai" className={classes.chipSuccess} size={"medium"}/>;
  const pending = <Chip label="Pending" className={classes.chipDraft} size={"medium"}/>;

  return (
    <Fragment>
      <TableRow key={beginningStock.id}>
        <TableCell>
          {index+from}
        </TableCell>
        <TableCell>
          {beginningStock.created_at}
        </TableCell>
        <TableCell>
          {beginningStock.user.name}
        </TableCell>
        <TableCell>
          {beginningStock.qty}
        </TableCell>
        <TableCell>
          <NumberFormat value={beginningStock.total_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />
        </TableCell>
        <TableCell>
          {beginningStock.status === 'saved' ? selesai : pending}
        </TableCell>
        <TableCell>
          <Tooltip title="Detail Stock Awal">
            {/*<RouterLink to={`/report/selling/detail/${trx.id}`}>*/}
            <IconButton aria-label="detail" color="primary" onClick={props.detail}>
              <DetailIcon />
            </IconButton>
            {/*</RouterLink>*/}
          </Tooltip>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default BeginningStockData;