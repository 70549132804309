import React, { Fragment } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { isEmpty } from '../../../../../../shared/utility';
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from '../../../../../../components/UI';
import CustomerData from './CustomerData/CustomerData';

const columns = [
  { id: 'no_id', label: 'No ID', minWidth: 100 },
  { id: 'nama', label: 'Nama', minWidth: 150 },
  { id: 'kategori', label: 'Kategori', minWidth: 100 },
  { id: 'action', label: 'Aksi', minWidth: 100 },
];

const CustomersData = (props) => {
  const {
    classes,
    customers,
    handleChangePageProps,
    handleSelectChangeProps,
    setSelectedUser,
  } = props;

  const handleChangePage = (event, newPage) => {
    handleChangePageProps(newPage);
  };

  let tableBody = '';
  if (!isEmpty(customers)) {
    const page = customers.current_page - 1;
    const rowsPerPage = 5;
    const countRows = customers.total;
    // console.log(page, rowsPerPage, countRows, emptyRows);

    tableBody = (
      <Fragment>
        <TableBody>
          {customers.data.map((customer, index) => (
            <CustomerData
              customer={customer}
              key={customer.id_agent}
              index={index}
              classes={classes}
              handleSelectChange={(data) => handleSelectChangeProps(data)}
              setSelectedUser={setSelectedUser}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={8}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography>Hasil Pencarian</Typography>
        <div className={classes.row}>
          {/* {customers_v2 !== null && ( */}
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                aria-label='sticky table'
                style={{ minWidth: '340px' }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {tableBody}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Grid>
    </Fragment>
  );
};

export default CustomersData;
