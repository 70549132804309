import React, {Fragment, useState} from "react";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {CustomRouterLink, isEmpty} from "../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import CreatePurchaseOrderV2FormData from "./CreatePurchaseOrderV2FormData";
import NumberFormat from "react-number-format";

import * as actions from '../../../../actions';
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';

const columns = [
  { id: 'no', label: 'No', minWidth: 10 },
  { id: 'no_invoice', label: 'Item Produk', minWidth: 100 },
  { id: 'no_id', label: 'Harga Satuan', minWidth: 100 },
  { id: 'email', label: 'Kuota Harian Pusat', minWidth: 100 },
  { id: 'total_item', label: 'Kuota Pembelian EGS', minWidth: 100 },
  { id: 'ronde', label: 'Jumlah Order', minWidth: 100 },
  { id: 'total_bayar', label: 'Sisa Kuota', minWidth: 100 },
  { id: 'status', label: 'SubTotal', minWidth: 100 },
];

const CreatePurchaseOrderV2Form = props => {
  const {products, round, classes,onStorePO} = props;
  const history = useHistory();

  const list = []
  products.map((itemData, index) => (
    list[index] = {
      id_product: itemData.product.id,
      qty: 0,
      sell_price: itemData.product ? itemData.latest_price.sell_price : 0,
      buy_price: itemData.product ? itemData.latest_price.buy_price : 0,
      buyback_price: itemData.product ? itemData.latest_price.buyback_price : 0,
      sub_total: itemData.product ? itemData.latest_price.sell_price * 0 : 0
    }));
  //
  const [ orderQty, setOrderQty ] = useState(list);
  // //
  const handleOrderQtyChange = (e,product, index) => {
    e.persist();
    let dataCount = e.target.value === '' ? 0 : parseInt(e.target.value);
    const list = [...orderQty]
    if (dataCount === '' || dataCount < 0){
      dataCount = 0;
    }
    if (dataCount >= parseInt(product.kuota_cabang)){
      dataCount = product.kuota_cabang;
    }
    if (dataCount >= parseInt(product.kuota_sbs)){
      dataCount = product.kuota_sbs;
    }
    list[index] = {
      id_product : product.product.id,
      [e.target.name] : dataCount,
      sell_price: product.product ? product.latest_price.sell_price : 0,
      buy_price: product.product ? product.latest_price.buy_price : 0,
      buyback_price: product.product ? product.latest_price.buyback_price : 0,
      sub_total: product.product ? product.latest_price.sell_price * dataCount : 0
    }
    setOrderQty(list)
  }

  let tableBody = '';
  if(!isEmpty(products)){
    tableBody = (
      <Fragment>
        <TableBody>
          {products.map((product, index) => (
            <CreatePurchaseOrderV2FormData
              product={product}
              // key={purchaseOrder.id}
              index={index}
              classes={classes}
              handleOrderQtyChange={(e) => handleOrderQtyChange(e,product,index)}
              orderQty={orderQty}
              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}
          <TableRow>
            <TableCell colSpan={5}/>
            <TableCell>
              {!isEmpty(orderQty) ? <NumberFormat style={{fontWeight: "bold"}} value={orderQty.map(item => parseInt(item.qty)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} suffix={" pcs"}/> : 0}
            </TableCell>
            <TableCell/>
            <TableCell>
              {!isEmpty(orderQty) ? <NumberFormat style={{fontWeight: "bold"}} value={orderQty.map(item => parseInt(item.sub_total)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/> : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Fragment>
    )
  }

  let jumlah_produk = orderQty.map(item => parseInt(item.qty)).reduce((prev, next) => prev + next);
  // console.log(jumlah_produk);
  const onSubmit = data => {
    data.round_id = round.id;
    data.products = orderQty.filter(({qty}) => qty > 0);
    delete data.qty;
    onStorePO(data,history);
  }

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <div className={classes.paperPadding}>
          <Typography className={classes.tagMenu} variant={"h5"}>List Order</Typography>
        </div>
        <div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {tableBody}
            </Table>
          </TableContainer>
          <Grid container className={classes.row} justify={"flex-end"}>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <Grid container spacing={2} justify={"space-between"}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Button className={classes.btnCancel} variant="outlined" fullWidth component={CustomRouterLink} to={'/purchase-order-v2'}>
                    Batal
                  </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  {jumlah_produk > 0 ? (
                    <Button className={classes.btnSuccess} variant="contained" type="submit" fullWidth onClick={() => onSubmit({})}>
                      Lanjutkan
                    </Button>
                  ): (
                    <Button className={classes.btnSuccess} variant="contained" type="submit" fullWidth onClick={() => onSubmit({})} disabled>
                      Lanjutkan
                    </Button>
                  )}
                </Grid>
              </Grid>
              <br/>
            </Grid>
          </Grid>
        </div>
        {/*<ListPurchaseOrder purchaseOrders={purchaseOrders} classes={classes} propsHandleChangePage={(newPage) => handleChangePage(newPage)}/>*/}
      </Paper>
    </Fragment>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onStorePO: (storeData, history) => dispatch(actions.storePurchaseOrder(storeData,history))
  }
}

export default connect(null, mapDispatchToProps)(CreatePurchaseOrderV2Form);