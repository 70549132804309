import React, {Fragment, useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {Main} from '../Styles';
import {Grid, Paper} from "@material-ui/core";
import {RincianPesanan, ListOrders, FormUploadReceipt, OrderArrived} from "./Components";

import {

  Box,
  Button,
  Typography,
} from "@material-ui/core";
 
import * as actions from '../../../actions';
import {connect} from "react-redux";
import {Loading} from "../../../components/UI";
import {isEmpty} from "../../../shared/utility";
import Modal from "../../../components/UI/Modal/ScrollDialog/ScrollDialog";
import PaymentProofFileConfirmation from "./Components/PaymentProofFileConfirmation/PaymentProofFileConfirmation";
import PaymentProofFileShow from "./Components/PaymentProofFileShow/PaymentProofFileShow";
import FormOrderArrived from "./Components/FormOrderArrived";
import OrderArrivedConfirmation from "./Components/OrderArrivedConfirmation/OrderArrivedConfirmation";
import OrderArrivedShow from "./Components/OrderArrivedShow/OrderArrivedShow";
import CountDownPO from "./Components/CountDownPO";

import registrationService from "../../../services/registration.service";

const DetailPuurchaseOrder = props => {
  const {id} = useParams();
  const classes = Main();
  const {purchaseOrder, loading, onAlert,onGetPurchaseOrder,counting,onStorePurchaseOrderReceipt,onClearFilePORedux} = props;
  const [purchaseStatusProcess, setPurchaseStatusProcess] = React.useState(false);
  const [purchaseStatusCancel, setPurchaseStatusCancel] = React.useState(false);
  const handleCloseProsess = () => setPurchaseStatusProcess(false);
  const handleCloseCancel = () => setPurchaseStatusCancel(false);

  useEffect(() => {
    onGetPurchaseOrder(id)
    closedModalDialog()
    onClearFilePORedux()
  },[onGetPurchaseOrder, id,counting,onClearFilePORedux]);

  const [Form, setForm] = useState('');
  console.log('Form', Form);
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    maxWidth: 'sm',
  });
  const closedModalDialog = () => {
    setModalState({
      maxWidth: 'sm',
      title: '',
      open: false,
    });
    setForm('');
  }

  const onSubmit = data => {
    // console.log(data)
    onStorePurchaseOrderReceipt(id,data)
  }

  const onProcess = data => {
    registrationService.changestatuspo({invoice_id:purchaseOrder.id,status_po:'2'}).then(
      (response)=>{
          //alert(response);
          onAlert('Sukses approve Data Registrasi','success');
          //onAlert('Sukses mendaftarkan Data Registrasi','success')  
      }).catch(error => onAlert(`Error On Create Registration: ${JSON.stringify(error.response.data, null, "\t")}`,"error"));
  }

  const onReject = data => {
    registrationService.changestatuspo({invoice_id:purchaseOrder.id,status_po:'99'}).then(
      (response)=>{
          //alert(response);
          onAlert('Sukses reject Data Registrasi','success');
        
      }).catch(error => onAlert(`Error On Create Registration: ${JSON.stringify(error.response.data, null, "\t")}`,"error"));
  }



  const paymentProofFileConfirmation = (urlImages, data) => {
    data.receipts = urlImages;
    setModalState({
      open: true,
      title: 'Kirim Upload Bukti Bayar?',
      maxWidth: 'xs'
    });
    setForm(<PaymentProofFileConfirmation classes={classes} closedModalDialog={closedModalDialog} data={data} onSubmit={(dataStore) => onSubmit(dataStore) }/>)
  };

  const orderArrivedConfirmation = (urlImages, data) => {
    data.receipts = urlImages;
    setModalState({
      open: true,
      title: 'Kirim Upload Bukti Produk Sampai ?',
      maxWidth: 'xs'
    });
    setForm(<OrderArrivedConfirmation classes={classes} closedModalDialog={closedModalDialog} data={data} onSubmit={(dataStore) => onSubmit(dataStore) }/>)
  };

  const paymentProofFileShow = data => {
    setModalState({
      open: true,
      title: 'Foto Bukti Bayar',
      maxWidth: 'sm'
    });
    setForm(<PaymentProofFileShow classes={classes} closedModalDialog={closedModalDialog} data={data}/>)
  };

  const orderArrivedShow = data => {
    setModalState({
      open: true,
      title: 'Foto Bukti Sampai',
      maxWidth: 'sm'
    });
    setForm(<OrderArrivedShow classes={classes} closedModalDialog={closedModalDialog} data={data}/>)
  };

  let rincianPesanan = '';
  let listOrders = '';
  let formUploadReceipt = '';
  let countDown = '';
  if(!isEmpty(purchaseOrder)){
    rincianPesanan = <RincianPesanan classes={classes} purchaseOrder={purchaseOrder} />;
    listOrders = <ListOrders classes={classes} purchaseOrder={purchaseOrder} />;
    formUploadReceipt = <FormUploadReceipt  classes={classes} purchaseOrder={purchaseOrder} paymentProofFileConfirmation={(urlImages, data) => paymentProofFileConfirmation(urlImages, data)} paymentProofFileShow={(data) => paymentProofFileShow(data)}/>;
    if(purchaseOrder.status === "3"){
      countDown = <CountDownPO classes={classes} countDownTime={purchaseOrder.created_at} round={purchaseOrder.round}/>
    }
  }


  return(loading ? <Loading/> :
    <Fragment>

    <Modal 
        open={purchaseStatusProcess}
        title="Konfirmasi Purchase Order Diproses?"
        onClose={handleCloseProsess}
        >
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
          Purchase order untuk registrasi AOG/MOG baru akan diproses sesuai dengan rincian pesanan. Pastikan semua informasi purchase order tidak ada kesalahan
          </Typography>

          <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="secondary" color="primary"  sx={{ height: 40 }}
                          onClick={() => handleCloseProsess(true)}
                        >
                        BATAL
                      </Button> &nbsp;
                      <Button variant="contained" color="#4caf50"  sx={{ height: 40 }}
                          onClick={onProcess}
                        >
                        PROSES
                      </Button>
                    </Box>

      </Modal>

      <Modal 
        open={purchaseStatusCancel}
        title="Konfirmasi Purchase Order Ditolak?"
        onClose={handleCloseCancel}
        >
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Purchase order untuk registrasi AOG/MOG baru akan ditolak kareana alasan tertentu. Infromasikan kepada AOG/MOG yang mendaftarkan mengenai purchase prder yang akan di tolak.
            <br/> Yakin ingin menolak purchase order sekarang?
          </Typography>

          <Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="secondary" color="primary"  sx={{ height: 40 }}
                          onClick={() => handleCloseCancel(true)}
                        >
                        BATAL
                      </Button> &nbsp;
                      <Button variant="contained" color="#4caf50"  sx={{ height: 40 }}
                          onClick={onReject}
                        >
                        PROSES
                      </Button>
                    </Box>

      </Modal>


      <Modal
        maxWidth={modalState.maxWidth}
        open={modalState.open}
        title={modalState.title}
        onCloseModal={closedModalDialog}
        // contentModal={Form}
      >
        {Form}
      </Modal>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div className={classes.tagMenu}>
                Detail Purhcase Order
              </div>
            </Grid>
            <Grid item>
              {countDown}
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item xl={8} md={8} sm={12} xs={12}>
              {formUploadReceipt}
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              {rincianPesanan}
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Paper>
            <div className={classes.paperContent}>
              <Grid container spacing={2}>
                <Grid item xl={12} md={12} sm={12} xs={12}>
                  {listOrders}
                </Grid>
              </Grid>

              {/*<Box
                      m={1}
                    //margin
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      
                    >
                     
                      <Button variant="outlined" color="primary"  sx={{ height: 40 }}
                          onClick={() => setPurchaseStatusProcess(true)}
                        >
                        PROSSES
                      </Button> &nbsp;
                      <Button variant="outlined" color="error"  sx={{ height: 40 }}
                          onClick={() => setPurchaseStatusCancel(true)}
                        >
                        TOLAK
                      </Button>
                </Box>*/}



              <div>
                <FormOrderArrived classes={classes} purchaseOrder={purchaseOrder} orderArrivedConfirmation={(urlImages, data) => orderArrivedConfirmation(urlImages, data)}/>
                <OrderArrived classes={classes} purchaseOrder={purchaseOrder} orderArrivedShow={(data) => orderArrivedShow(data)}/>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    purchaseOrder: state.purchaseOrder.purchaseOrderV2,
    counting: state.purchaseOrder.counting,
    loading: state.purchaseOrder.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClearFilePORedux: () => dispatch(actions.clearFilePoRedux()),
    onGetPurchaseOrder: (id) => dispatch(actions.getPurchaseOrderV2(id)),
    onStorePurchaseOrderReceipt: (id, storeData) => dispatch(actions.storePurchaseOrderReceipt(id, storeData)),
    onAlert: (msg, alertType) => dispatch(actions.setAlert(msg,alertType))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(DetailPuurchaseOrder);