import React from 'react'
import {TableCell, TableRow, IconButton, Tooltip, Chip} from "@material-ui/core";
import {Search as DetailIcon} from "@material-ui/icons";

export default function StatusPurchase(props) {
  
    
  const menunggu_konfirmasi = <Chip label="Menunggu Konfirmasi" className={props.classes.chipWarning} size={"medium"}/>;
  const sampai = <Chip label="Sampai" className={props.classes.chipSuccess} size={"medium"}/>;
  const dikirim = <Chip label="Dikirim" className={props.classes.chipPrimary} size={"medium"}/>;
  const pending = <Chip label="Pending" className={props.classes.chipWarning} size={"medium"}/>;
  const diproses = <Chip label="Diproses" className={props.classes.chipSecondary} size={"medium"}/>;
  const ditolak = <Chip label="Ditolak" className={props.classes.chipError} size={"medium"}/>;
  const expired = <Chip label="Expired" size={"medium"}/>;
  const new_invoice = <Chip label="New Invoice" className={props.classes.chipWarning} size={"medium"}/>;

  return (
   <> { (props.status_po === 'Menunggu Konfirmasi') ? menunggu_konfirmasi :
          (props.status_po === 'Sampai') ? sampai :
            (props.status_po === 'Dikirim') ? dikirim :
              (props.status_po === 'Pending') ? pending :
                (props.status_po === 'Diproses') ? diproses :
                  (props.status_po === 'Ditolak') ? ditolak :
                    (props.status_po === 'Expired') ? expired :
                      (props.status_po === 'New Invoice') ? new_invoice :
                        ''}</>
  )
}
