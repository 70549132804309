import React, { Fragment } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button } from '@material-ui/core';

const CustomerData = (props) => {
  const { customer, classes, handleSelectChange, setSelectedUser } = props;
  return (
    <Fragment>
      <TableRow key={customer.id_agent}>
        <TableCell>{customer.id_agent}</TableCell>
        <TableCell>{customer.name}</TableCell>
        <TableCell>{customer.status === '2' ? 'MOG' : 'AOG'}</TableCell>
        <TableCell>
          <Button
            fullWidth
            className={classes.btn}
            variant='contained'
            onClick={(e) => {
              handleSelectChange(customer);
              setSelectedUser(true);
            }}
            size='small'
          >
            Pilih
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default CustomerData;
