import { Button, Card, CardActions, CardContent, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as actions from '../../../../actions/index';
import { connect } from 'react-redux';
import Loading from '../../../../components/UI/Loading/Loading';

const useStyles = makeStyles(theme => ({
  root : {
    padding : theme.spacing(4)
  }
}))

const schema = yup.object().shape({
  password: yup.string().required(),
});

const FormChangePassword = props => {
  const classes = useStyles();
  const { onChangePassword, loading } = props;
  const token = sessionStorage.getItem('access_token');
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema)
  });

  const handleChange = (event) => {
    // console.log(event.target.value);
    const target = event.target.name
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [target]: event.target.value
    }))
  }

  const [formState, setFormState] = useState({
    password: '',
  })

  const onSubmit = event => {
    onChangePassword(event, token);
    // console.log(event)
  }

  let button = //(loading ? <CircularProgress/> : (
    (
      <Button type="submit" variant="contained" style={{ backgroundColor: '#FF9300', color: '#FFFFFF' }} fullWidth disableElevation>
        Simpan
      </Button>
    )
  // ));

  return (loading?
    <Loading/>
    :
    <Fragment>
      <div className={classes.root}>
      <Grid
        container
        spacing={3}
        justify="space-between"
      >
        <Grid item>  
          <Typography variant="h4">Form Ubah Password</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* <Grid item lg={3} md={3} sm={12} xs={12}></Grid> */}
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card>
                  <CardContent>
                    <TextField
                      label="Password"
                      variant="outlined"
                      name="password"
                      type="password"
                      inputRef={register}
                      error={errors.password ? true : false}
                      helperText={errors.password && errors.password.message}
                      fullWidth
                      defaultValue={formState.password}
                      onChange={handleChange}
                    />
                  </CardContent>
                  <CardActions>
                    {button}
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.profile.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangePassword: (storeData, token) => dispatch(actions.changePassword(storeData, token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormChangePassword);