import React, {Fragment, useState} from "react";
import {Dropzone} from "../../../../components/UI";
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import imageCompression from "browser-image-compression";
import {isEmpty} from "../../../../shared/utility";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import {Delete as DeleteIcon} from "@material-ui/icons";
import * as actions from "../../../../actions";
import {connect} from "react-redux";

const FormOrderArrived = props => {
  const {
    classes,
    purchaseOrder,
    onUploadImage,
    urlImages,
    onDeleteImageRedux,
    orderArrivedConfirmation
  } = props;

  const [image, setImage] = useState('');
  // const [base64, setB64] = useState();

  const handleChangeBanner = event => {
    setImage(event)
    const imageFile = event[0];
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 512,
      useWebWorker: true
    }
    imageCompression(imageFile, options)
      .then(function (compressedFile) {
        let fileUpload = new File(
          [compressedFile],
          compressedFile.name,
          {
            type: compressedFile.type,
            path: compressedFile.name
          })
        return onUploadImage(fileUpload);
      })
      .catch(function (error) {
        // onAlert(error.message, 'error')
      });

  }

  let imageUploadSukes = '';

  if (!isEmpty(urlImages)) {
    // console.log(assetImageUrl);
    imageUploadSukes = (
      <Fragment>
        {urlImages.map((image, index) => (
          <Grid key={index} item lg={3} md={3} sm={12} xs={12}>
            <Card className={classes.imageList}>
              <CardActionArea>
                <CardMedia
                  className={classes.media}
                  image={image.link}
                  title="Contemplative Reptile"
                />
              </CardActionArea>
              <CardContent>
                <Typography variant="h5">
                  {image.mime_type}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonDelete}
                  StartIcon={<DeleteIcon />}
                  onClick={() => onDeleteImageRedux(index)}
                  fullWidth
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Fragment>
    )
  }

  let formOrderArrived = '';
  if(!isEmpty(purchaseOrder)){
    if(purchaseOrder.status === "2"){
      formOrderArrived = (
        <Fragment>
          <div className={classes.row}>
            <Fragment>
              {/*<div className={classes.tagMenu}>*/}
                Upload Bukti Produk Telah Sampai
              {/*</div>*/}
              <div className={classes.row}>
                <form>
                  <Dropzone multiple={false} fileType={'image/png,image/jpg,image/jpeg'} value={image} handleChangeBanner={handleChangeBanner} />
                  <Grid container spacing={2} className={classes.row}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Fragment>
                        <Grid container spacing={2}>
                          {imageUploadSukes}
                        </Grid>
                      </Fragment>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} justify={"space-between"} className={classes.row}>
                    <Grid item>
                      Note: Segera upload foto bukti produk yang telah sampai.
                      Format PNG/JPG/JPEG maksimal 2mb.
                    </Grid>
                    <Grid item>
                      {(urlImages.length > 0) ? (
                        <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} onClick={() => orderArrivedConfirmation(urlImages,{status: 'sampai'})}>
                          Sampai
                        </Button>
                      ) : (
                        <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} disabled>
                          Kirim
                        </Button>
                      )}

                    </Grid>
                  </Grid>
                </form>
              </div>
            </Fragment>
          </div>
        </Fragment>
      )
    }
  }

  return (
    <Fragment>
      {formOrderArrived}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.purchaseOrder.loadingUploadImage,
    urlImages: state.purchaseOrder.urlImage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUploadImage: (file) => dispatch(actions.storePurchaseOrderFile(file)),
    onDeleteImageRedux: (index) => dispatch(actions.deleteImagePORedux(index)),
    onStorePurchaseOrderReceipt: (id, storeData, history) => dispatch(actions.storePurchaseOrderReceipt(id, storeData, history))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormOrderArrived);