/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

import Modal from '../../components/UI/Modal/ScrollDialog/ScrollDialog';
import { RincianPesanan, ListOrders } from './Components';

import { getDetailPOAOG, changeStatusPOAOG } from '../../services/po.service';
import { useSnackbar } from '../../shared/useSnackbar';

const DetailPuurchaseOrder = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { createSnackbar } = useSnackbar();

  const [dataAPI, setDataAPI] = useState(null);
  const [purchaseStatusProcess, setPurchaseStatusProcess] = useState(false);
  const [purchaseStatusCancel, setPurchaseStatusCancel] = useState(false);
  const [purchaseStatusSend, setPurchaseStatusSend] = useState(false);

  const handleCloseProsess = () => setPurchaseStatusProcess(false);
  const handleCloseCancel = () => setPurchaseStatusCancel(false);

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      message: msg,
      dismissable: false,
      theme: theme,
      sticky: false,
    });
  };

  const onProcess = async () => {
    setPurchaseStatusProcess(false);
    try {
      await changeStatusPOAOG({ invoice_id: id, status_po: '2' });
      showSnackbar('Pesanan berhasil di proses', 'success');
      getDetailApprovalPO();
    } catch (e) {
      console.error(e.response.data.msg_str);
      showSnackbar(e.response.data.msg_str, 'error');
    }
  };

  const onReject = async () => {
    setPurchaseStatusCancel(false);
    try {
      await changeStatusPOAOG({ invoice_id: id, status_po: '99' });
      showSnackbar('Pesanan berhasil di tolak', 'success');
      getDetailApprovalPO();
    } catch (e) {
      console.error(e.response.data.msg_str);
      showSnackbar(e.response.data.msg_str, 'error');
    }
  };

  const sendPackage = async () => {
    setPurchaseStatusSend(false);
    try {
      await changeStatusPOAOG({ invoice_id: id, status_po: '3' });
      showSnackbar('Pesanan berhasil di kirim', 'success');
      getDetailApprovalPO();
    } catch (e) {
      console.error(e.response.data.msg_str);
      showSnackbar(e.response.data.msg_str, 'error');
    }
  };

  const getDetailApprovalPO = async () => {
    try {
      const data = await getDetailPOAOG({ invoice_id: id });
      setDataAPI(data);
      console.log('detail', data);
    } catch (e) {
      console.error(e.response.data.message);
      showSnackbar(e.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getDetailApprovalPO();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid container justify={'space-between'}>
            <Grid item>
              <Typography variant='h5'>
                <b>Detail Approval Purchase Order</b>
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Grid container spacing={2}>
            <Grid item xl={8} md={8} sm={12} xs={12}>
              <Paper>
                <div className={classes.paperContent}>
                  <div className={classes.tagMenu}>Foto Bukti Bayar</div>
                  <div className={classes.row}>
                    <Grid container spacing={2} className={classes.row}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Fragment>
                          {dataAPI?.invoice?.proof_payment_image ? (
                            <Grid container spacing={2}>
                              <Grid item lg={3} md={3} sm={12} xs={12}>
                                <Card className={classes.imageList}>
                                  <CardActionArea>
                                    <CardMedia
                                      className={classes.media}
                                      image={
                                        dataAPI?.invoice?.proof_payment_image
                                      }
                                      title='Contemplative Reptile'
                                    />
                                  </CardActionArea>
                                  <CardActions>
                                    <Button
                                      variant='contained'
                                      color='secondary'
                                      className={classes.buttonWarning}
                                      startIcon={<Search />}
                                      onClick={() =>
                                        window.open(
                                          dataAPI.invoice.proof_payment_image,
                                          '_blank'
                                        )
                                      }
                                      fullWidth
                                    >
                                      Lihat
                                    </Button>
                                  </CardActions>
                                </Card>
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography>Belum ada bukti transfer</Typography>
                          )}
                        </Fragment>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <RincianPesanan classes={classes} purchaseOrder={dataAPI} />
            </Grid>
          </Grid>
        </div>

        <div className={classes.row}>
          <Paper>
            <div className={classes.paperContent}>
              <Grid container spacing={2}>
                <Grid item xl={12} md={12} sm={12} xs={12}>
                  <ListOrders classes={classes} purchaseOrder={dataAPI} />
                </Grid>
              </Grid>

              {dataAPI?.invoice?.status_po === 1 && (
                <Box
                  m={1}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                >
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ height: 40 }}
                    onClick={() => setPurchaseStatusProcess(true)}
                  >
                    PROSSES
                  </Button>{' '}
                  &nbsp;
                  <Button
                    variant='outlined'
                    color='error'
                    sx={{ height: 40 }}
                    onClick={() => setPurchaseStatusCancel(true)}
                  >
                    TOLAK
                  </Button>
                </Box>
              )}
              {dataAPI?.invoice?.status_po === 2 && (
                <Box
                  m={1}
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='flex-end'
                >
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ height: 40 }}
                    onClick={() => setPurchaseStatusSend(true)}
                  >
                    KIRIM PESANAN
                  </Button>
                </Box>
              )}

              {dataAPI?.invoice?.status_po === 4 && (
                <div>
                  <Typography style={{ margin: '10px 0' }} variant='h5'>
                    Bukti barang telah sampai
                  </Typography>
                  {dataAPI?.shipment.map((item) => (
                    <Grid container spacing={2} key={item.id}>
                      <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Card className={classes.imageList}>
                          <CardActionArea>
                            <CardMedia
                              className={classes.media}
                              image={item.image}
                              title='Contemplative Reptile'
                            />
                          </CardActionArea>
                          <CardActions>
                            <Button
                              variant='contained'
                              color='secondary'
                              className={classes.buttonWarning}
                              startIcon={<Search />}
                              onClick={() => window.open(item.image, '_blank')}
                              fullWidth
                            >
                              Lihat
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              )}
            </div>
          </Paper>
        </div>
      </div>

      <Modal
        open={purchaseStatusProcess}
        title='Konfirmasi Purchase Order Diproses?'
        onClose={handleCloseProsess}
      >
        <Typography id='keep-mounted-modal-description' sx={{ mt: 2 }}>
          Purchase order untuk registrasi AOG/MOG baru akan diproses sesuai
          dengan rincian pesanan. Pastikan semua informasi purchase order tidak
          ada kesalahan
        </Typography>

        <Box
          m={1}
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: 40 }}
            onClick={() => handleCloseProsess(true)}
          >
            BATAL
          </Button>{' '}
          &nbsp;
          <Button
            variant='contained'
            color='#4caf50'
            sx={{ height: 40 }}
            onClick={onProcess}
          >
            PROSES PESANAN
          </Button>
        </Box>
      </Modal>

      <Modal
        open={purchaseStatusCancel}
        title='Konfirmasi Purchase Order Ditolak?'
        onClose={handleCloseCancel}
      >
        <Typography id='keep-mounted-modal-description' sx={{ mt: 2 }}>
          Purchase order untuk registrasi AOG/MOG baru akan ditolak kareana
          alasan tertentu. Infromasikan kepada AOG/MOG yang mendaftarkan
          mengenai purchase prder yang akan di tolak.
          <br /> Yakin ingin menolak purchase order sekarang?
        </Typography>

        <Box
          m={1}
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: 40 }}
            onClick={() => handleCloseCancel(true)}
          >
            BATAL
          </Button>{' '}
          &nbsp;
          <Button
            variant='contained'
            color='#4caf50'
            sx={{ height: 40 }}
            onClick={onReject}
          >
            TOLAK PESANAN
          </Button>
        </Box>
      </Modal>

      <Modal
        open={purchaseStatusSend}
        title='Konfirmasi Pengiriman Purchase Order?'
        onClose={() => setPurchaseStatusSend(false)}
      >
        <Typography id='keep-mounted-modal-description' sx={{ mt: 2 }}>
          Purchase order untuk registrasi AOG/MOG baru akan dikirim sesuai
          dengan rincian pesanan. Pastikan semua informasi purchase order tidak
          ada kesalahan
          <br /> Yakin ingin lanjut ke proses pengiriman purchase order ?
        </Typography>

        <Box
          m={1}
          display='flex'
          justifyContent='flex-end'
          alignItems='flex-end'
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: 40 }}
            onClick={() => setPurchaseStatusSend(false)}
          >
            BATAL
          </Button>{' '}
          &nbsp;
          <Button
            variant='contained'
            color='#4caf50'
            sx={{ height: 40 }}
            onClick={sendPackage}
          >
            KIRIM PESANAN
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default DetailPuurchaseOrder;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tagMenu: {
    fontWeight: 'bold',
  },
  btnAdd: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: 'bold',
    borderRadius: 60,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  btnCancel: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    fontWeight: 'bold',
    '&:hover': {
      borderColor: theme.palette.error.light,
    },
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  buttonWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  paperContent: {
    padding: theme.spacing(3),
  },
  paperRules: {
    padding: theme.spacing(2),
    backgroundColor: '#FFF9F0',
  },
  paperPadding: {
    marginBottom: theme.spacing(2),
  },
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  ruleContent: {
    color: theme.palette.error.main,
    margin: '5px auto',
    // fontSize: '18px'
  },
  chipWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  chipSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
  roundText: {
    marginTop: 3,
    color: theme.palette.warning.main,
    fontWeight: 'bold',
  },
  roundTimer: {
    margin: 'auto 5px',
    border: '1px solid #F99800',
    background: 'rgba(249, 152, 0, 0.16)',
    borderRadius: '20%',
  },
  rincianTable: {
    padding: '0 0',
    borderBottom: 'none',
  },
  textCenter: {
    textAlign: 'center',
  },
  rek: {
    marginTop: 5,
    color: theme.palette.black,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  zeroPadding: {
    padding: '0px 6px',
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonDelete: {
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(200, 0, 58)',
    },
    color: '#FFFFFF',
  },
}));
