import React, { Fragment } from "react";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { FileCopy as FileCopyIcon } from "@material-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Tooltip from "@material-ui/core/Tooltip";

const RincianPesanan = ({ classes, purchaseOrder }) => {
  return (
    <Fragment>
      <Paper>
        <div className={classes.paperContent}>
          <div className={classes.tagMenu}>Rincian Pesanan</div>

          <div className={classes.row}>
            <Table>
              <TableRow>
                <TableCell width={"40%"} className={classes.rincianTable}>
                  Total Item
                </TableCell>
                <TableCell width={"60%"} className={classes.rincianTable}>
                  :{" "}
                  <NumberFormat
                    value={purchaseOrder?.detail?.length || 0}
                    displayType={"text"}
                    suffix={" varian"}
                    thousandSeparator={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.rincianTable}>
                  Total Order
                </TableCell>
                <TableCell className={classes.rincianTable}>
                  :{" "}
                  <NumberFormat
                    value={purchaseOrder?.detail?.length || 0}
                    displayType={"text"}
                    suffix={" pcs"}
                    thousandSeparator={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.rincianTable}>
                  Total Berat
                </TableCell>
                <TableCell className={classes.rincianTable}>
                  :{" "}
                  {purchaseOrder?.detail.reduce(
                    (a, b) => a + parseInt(b.weight),
                    0
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.rincianTable}>
                  Total Harga
                </TableCell>
                <TableCell className={classes.rincianTable}>
                  :{" "}
                  <NumberFormat
                    value={parseInt(purchaseOrder?.invoice?.total_payment)}
                    displayType={"text"}
                    prefix={"Rp "}
                    thousandSeparator={true}
                  />
                </TableCell>
              </TableRow>
            </Table>
          </div>
          <div className={classes.row}>
            <Typography className={classes.textCenter}>
              Total yang harus ditransfer pemesan
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant={"h5"} className={classes.textCenter}>
              <NumberFormat
                value={purchaseOrder?.invoice?.total_payment}
                displayType={"text"}
                prefix={"Rp "}
                thousandSeparator={true}
              />
              <CopyToClipboard text={0}>
                <Tooltip title="copy to clipboard" placement={"right"}>
                  <IconButton>
                    <FileCopyIcon fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Typography>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

export default RincianPesanan;
