import {
    GET_TRANSACTION,
    GET_SEARCH_TRANSACTION,
    GET_DETAIL_TRANSACTION,
    DOWNLOAD_REPORT_TRANSACTION_START,
    DOWNLOAD_REPORT_TRANSACTION_SUCCESS,
    DOWNLOAD_REPORT_TRANSACTION_FAILED,
    GET_DETAIL_TRANSACTION_START,
    GET_SEARCH_TRANSACTION_START
} from '../actions/types'
import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
    transactions: null,
    transaction: null,
    downloadTransaction: null,
    loading: true,
    loadingDownload: false,
    loadingDetailTransaction: false,
    loadingSearchTransaction: false,
    error: {},
    transactionsV2: {},
    errorV2: {},
    loadingV2: false,
}

const fetchTransactionsStart = (state, action) => {
    return updateObject(state, { loadingV2: true })
}

const fetchTransactionsSuccess = (state, action) => {
    return updateObject(state, {
        transactionsV2: action.transactions,
        loadingV2: false
    })
}

const fetchTransactionsFail = (state, action) => {
    return updateObject(state, { errorV2: action.error, loadingV2: false })
}

const downloadTransactionsStart = (state, action) => {
    return updateObject(state, { errorV2: {}, loadingV2: true })
}

const downloadTransactionsSuccess = (state, action) => {
    return updateObject(state, {
        // purchaseOrdersV2: action.purchase_orders,
        loadingV2: false
    })
}

const downloadTransactionsFail = (state, action) => {
    return updateObject(state, { errorV2: action.error, loadingV2: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_TRANSACTION:
            return {
                ...state,
                transactions: payload,
                loading: false
            }
        case GET_SEARCH_TRANSACTION_START:
            return {
                ...state,
                loadingSearchTransaction: true,
            }
        case GET_SEARCH_TRANSACTION:
            return {
                ...state,
                transactions: payload,
                loadingSearchTransaction: false,
            }
        case GET_DETAIL_TRANSACTION_START:
            return {
                ...state,
                loadingDetailTransaction: true
            }
        case GET_DETAIL_TRANSACTION:
            return {
                ...state,
                transaction: payload,
                loadingDetailTransaction: false
            }
        case DOWNLOAD_REPORT_TRANSACTION_START:
            return {
                ...state,
                loadingDownload: true
            }
        case DOWNLOAD_REPORT_TRANSACTION_SUCCESS:
            return {
                ...state,
                downloadTransaction: payload,
                loadingDownload: false
            }
        case DOWNLOAD_REPORT_TRANSACTION_FAILED:
            return {
                ...state,
                downloadTransaction: payload,
                loadingDownload: false
            }
        case actionTypes.FETCH_TRANSACTIONS_START: return fetchTransactionsStart(state, action)
        case actionTypes.FETCH_TRANSACTIONS_SUCCESS: return fetchTransactionsSuccess(state, action)
        case actionTypes.FETCH_TRANSACTIONS_FAIL: return fetchTransactionsFail(state, action)
        case actionTypes.DOWNLOAD_TRANSACTIONS_START: return downloadTransactionsStart(state, action)
        case actionTypes.DOWNLOAD_TRANSACTIONS_SUCCESS: return downloadTransactionsSuccess(state, action)
        case actionTypes.DOWNLOAD_TRANSACTIONS_FAIL: return downloadTransactionsFail(state, action)
        default:
            return state
    }
}

export default reducer;