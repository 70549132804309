
import axios from 'axios'
import { setAlert } from './alert'
import fileDownload from 'js-file-download'
import { 
    GET_TRANSACTION, 
    GET_SEARCH_TRANSACTION, 
    GET_DETAIL_TRANSACTION, 
    DOWNLOAD_REPORT_TRANSACTION_START,
    DOWNLOAD_REPORT_TRANSACTION_SUCCESS, 
    DOWNLOAD_REPORT_TRANSACTION_FAILED,
    GET_DETAIL_TRANSACTION_START,
    GET_SEARCH_TRANSACTION_START
} from './types'
import * as actionTypes from "./types";
import axiosOrders from "../axios-orders";

export const getTransaction = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_TRANSACTION,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getTransactionSearch = (startDate, endDate, page) => async dispatch => {
    dispatch({
        type: GET_SEARCH_TRANSACTION_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction/search?tanggal=${startDate}/${endDate}&page=${page}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_SEARCH_TRANSACTION,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_SEARCH_TRANSACTION,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getDetailTransaction = (id_trx) => async dispatch => {
    dispatch({
        type: GET_DETAIL_TRANSACTION_START
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction/${id_trx}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_DETAIL_TRANSACTION,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_DETAIL_TRANSACTION,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const downloadReportTransaction = (id_trx) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction/${id_trx}/invoice`
    const token = sessionStorage.getItem('access_token')

    dispatch({
        type: DOWNLOAD_REPORT_TRANSACTION_START,
    })
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            },
            responseType: 'blob'
        });

        fileDownload(res.data, `INV${id_trx}.pdf`)
        dispatch({
            type: DOWNLOAD_REPORT_TRANSACTION_SUCCESS,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        dispatch({
            type: DOWNLOAD_REPORT_TRANSACTION_FAILED,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const fetchTransactionsStart = () => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_START
    }
}

export const fetchTransactionsSuccess = (transactions) => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_SUCCESS,
        transactions:transactions
    }
}

export const fetchTransactionsFail = (error) => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_FAIL,
        error: error
    }
}

export const fetchTransactions = (page, formSearch) => {
    let param = `&tanggal=${formSearch.startDate}/${formSearch.endDate}&product_type=${formSearch.product_type?formSearch.product_type:''}&customer_name=${formSearch.customer_name?formSearch.customer_name:''}`;
    return dispatch => {
        dispatch(fetchTransactionsStart())
        axiosOrders.get(`user/transaction/search?page=${page}`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchTransactionsSuccess(res.data))
          })
          .catch(err => {
              dispatch(fetchTransactionsFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const downloadTransactionsStart = () => {
    return {
        type: actionTypes.DOWNLOAD_TRANSACTIONS_START
    }
}

export const downloadTransactionsSuccess = () => {
    return {
        type: actionTypes.DOWNLOAD_TRANSACTIONS_SUCCESS
    }
}

export const downloadTransactionsFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_TRANSACTIONS_FAIL,
        error: error
    }
}

export const downloadTransactions = (formSearch) => {
    let param = `&tanggal=${formSearch.startDate}/${formSearch.endDate}&product_type=${formSearch.product_type?formSearch.product_type:''}&customer_name=${formSearch.customer_name?formSearch.customer_name:''}`;
    return dispatch => {
        dispatch(downloadTransactionsStart())
        axiosOrders.get(`user/transaction/export?`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            responseType: 'blob',
        })
          .then(res => {
              fileDownload(res.data, `download-laporan.xlsx`)
              dispatch(downloadTransactionsSuccess(res.data))
              dispatch(setAlert('Download Finished', "success"))
          })
          .catch(err => {
              dispatch(downloadTransactionsFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}