import React, {Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Avatar, TextField} from "@material-ui/core";
import NumberFormat from "react-number-format";

const FormProductData = props => {
  const {
    product,
    index,
    cartQty,
    max_weight,
    classes
  } = props;

  return (
    <Fragment>
      <TableRow key={product.product.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <div className={classes.dispayFlex}>
            <div className={classes.alignCenter}>
              <Avatar alt="Remy Sharp" src={product.product.image} className={classes.imgMargin}/>
            </div>
            <div className={classes.alignCenter}>
              {product.product.name+' '+product.product.weight+' '+product.product.unit}
            </div>
          </div>
        </TableCell>
        <TableCell>
          <NumberFormat value={product.product.stock_on_hand} displayType={'text'} thousandSeparator={true}/>
        </TableCell>
        <TableCell>
          {product.product.latest_price ? <NumberFormat value={product.product.latest_price.sell_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />: 0}
        </TableCell>
        {/*<TableCell>*/}
        {/*  {product.product.stock_on_hand}*/}
        {/*</TableCell>*/}
        <TableCell>
          {product.product.weight + ' ' + product.product.unit}
        </TableCell>
        <TableCell>
          {/*{*/}
          {/*  product.product.weight <= max_weight ?*/}
          {/*    0 :*/}
          {/*    (*/}
                <TextField
                  color={"secondary"}
                  fullWidth
                  name="qty"
                  type="number"
                  variant="outlined"
                  label={"qty"}
                  size={"small"}
                  // inputRef={register}
                  // helperText={
                  //   errors.qty && errors.qty.message
                  // }
                  // error={errors.qty && true}
                  disabled={((product.product.weight <= max_weight) || (max_weight === 0) || (product.product.stock_on_hand === 0))}
                  onChange={e => props.handleCartQtyChange(e,product,index)}
                  placeholder={0}
                  value={((product.product.weight <= max_weight) || (max_weight === 0) || (product.product.stock_on_hand === 0)) ? 0 : (cartQty[index].qty || '')}
                />
          {/*    )*/}
          {/*}*/}

        </TableCell>
        <TableCell>
          {cartQty[index] ? <NumberFormat value={cartQty[index].qty * product.product.weight} displayType={'text'} thousandSeparator={true} suffix={' '+product.product.unit} decimalScale={2}/> : 0}
        </TableCell>
        <TableCell>
          {cartQty[index] && product.product.latest_price ? <NumberFormat value={cartQty[index].qty * product.product.latest_price.sell_price} displayType={'text'} thousandSeparator={true} prefix={`Rp `}/> : 0}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default FormProductData;