import React, {Fragment, useEffect, useState} from "react";
import {Main} from './style';
import {Button, Grid} from "@material-ui/core";
import {SwapHoriz} from "@material-ui/icons";
import {FormFilter, ListSwapGramation} from "./components";
import moment from "moment";
import * as actions from '../../actions';
import {connect} from "react-redux";
import {CustomRouterLink, isEmpty} from "../../shared/utility";
import {Loading} from "../../components/UI";

const SwapGramation = props => {
  const classes = Main();
  const {
    onFetchSwapHistories,
    loading,
    swapHistories
  } = props;

  const [formSearch, setFormSearch] = useState({
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD')
  });

  useEffect(() => {
    onFetchSwapHistories(1,formSearch)
  },[onFetchSwapHistories,formSearch]);

  const handleChangePage = (newPage) => {
    onFetchSwapHistories(newPage,formSearch);
  }

  // console.log(swapHistories);



  const [ startDate, setStartDate ] = useState({
    startDate: new Date(),
  });

  const handleStartDate = (input) => {
    setStartDate(startDate => ({
      ...startDate,
      startDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      start_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [ endDate, setEndDate ] = useState({
    endDate: new Date(),
  });

  const handleEndDate = (input) => {
    setEndDate(endDate => ({
      ...endDate,
      endDate: input
    }));
    setFormSearch(() => ({
      ...formSearch,
      end_date: moment(input).format('YYYY-MM-DD')
    }));
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnd, setIsOpenEnd] = useState(false);

  return (loading ? <Loading/> :
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.tagMenu}>
            Tukar Gramasi
          </div>
        </div>

        <div className={classes.row}>
          <Grid container justify={"space-between"}>
            <Grid item xl={8} md={8} sm={12} xs={12}>
              <FormFilter
                classes={classes}
                setIsOpen={setIsOpen}
                setIsOpenEnd={setIsOpenEnd}
                startDate={startDate}
                endDate={endDate}
                isOpen={isOpen}
                isOpenEnd={isOpenEnd}
                handleStartDate={(input) => handleStartDate(input)}
                handleEndDate={(input) => handleEndDate(input)}
              />
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                size={"medium"}
                className={classes.btnAdd}
                startIcon={React.cloneElement(<SwapHoriz/>)}
                component={CustomRouterLink} to={'/swap-gramation/form'}
              >
                Tukar
              </Button>
            </Grid>
          </Grid>

        </div>

        <div className={classes.row}>
          {!isEmpty(swapHistories) ? (
            <ListSwapGramation classes={classes} swapHistories={swapHistories} propsHandleChangePage={(newPage) => handleChangePage(newPage)}/>
          ): ''}

        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return  {
    swapHistories: state.swapGramation.swapHistories,
    loading: state.swapGramation.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchSwapHistories: (page, formSearch) => dispatch(actions.fetchSwapGramations(page,formSearch))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwapGramation);