import React, { Fragment } from "react";
import { Box, Chip, Grid, IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { FileCopy as FileCopyIcon } from "@material-ui/icons";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import ListOrder from "../ListOrder/ListOrder";
import moment from "moment";

const columns = [
  { id: "no", label: "No", minWidth: 100 },
  { id: "item_produk", label: "Item Produk", minWidth: 100 },
  { id: "harga_satuan", label: "Harga Satuan", minWidth: 100 },
  { id: "jumlah_order", label: "Jumlah Order", minWidth: 100 },
  { id: "stock", label: "Stock", minWidth: 100 },
  { id: "sub_total", label: "Sub Total", minWidth: 100 },
];

const ListOrders = ({ classes, purchaseOrder }) => {
  const menunggu_konfirmasi = (
    <Chip
      label="Menunggu Konfirmasi"
      className={classes.chipWarning}
      size={"medium"}
    />
  );
  const sampai = (
    <Chip label="Sampai" className={classes.chipSuccess} size={"medium"} />
  );
  const dikirim = (
    <Chip label="Dikirim" className={classes.chipPrimary} size={"medium"} />
  );
  const pending = (
    <Chip label="Pending" className={classes.chipWarning} size={"medium"} />
  );
  const diproses = (
    <Chip label="Diproses" className={classes.chipSecondary} size={"medium"} />
  );
  const ditolak = (
    <Chip label="Ditolak" className={classes.chipError} size={"medium"} />
  );
  const expired = <Chip label="Expired" size={"medium"} />;
  const new_invoice = (
    <Chip label="New Invoice" className={classes.chipWarning} size={"medium"} />
  );

  let tableBody = (
    <Fragment>
      <TableBody>
        {purchaseOrder?.detail.map((data, index) => (
          <ListOrder
            data={data}
            key={data.id}
            index={index}
            classes={classes}
          />
        ))}
      </TableBody>
    </Fragment>
  );
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xl={6} md={6} sm={12} xs={12}>
          {/* <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>Ronde</Box>
            <Box width={"70%"}>
              : {purchaseOrder.round ? purchaseOrder.round.name : ""}
            </Box>
          </Box> */}
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>No Invoice</Box>
            <Box width={"70%"}>
              : {purchaseOrder?.invoice.invoice_id}
              <CopyToClipboard text={purchaseOrder?.invoice.invoice_id}>
                <Tooltip title="copy to clipboard" placement={"right"}>
                  <IconButton className={classes.zeroPadding} color={"primary"}>
                    <FileCopyIcon fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>Tanggal</Box>
            <Box width={"70%"}>
              :{" "}
              {moment(purchaseOrder?.invoice.created_at).format(
                "dddd, DD MMMM YYYY, HH:mm:ss"
              ) + " WIB"}
            </Box>
          </Box>
          {/* <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>PIC</Box>
            <Box width={"70%"}>: {purchaseOrder.user}</Box>
          </Box> */}
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>No Telp</Box>
            <Box width={"70%"}>
              : {purchaseOrder?.invoice.msisdn}
              <CopyToClipboard text={purchaseOrder?.invoice.msisdn}>
                <Tooltip title="copy to clipboard" placement={"right"}>
                  <IconButton className={classes.zeroPadding} color={"primary"}>
                    <FileCopyIcon fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={6} md={6} sm={12} xs={12}>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>Status</Box>
            <Box width={"70%"}>
              :
              {purchaseOrder?.invoice?.status_po === 1
                ? menunggu_konfirmasi
                : purchaseOrder?.invoice?.status_po === 4
                ? sampai
                : purchaseOrder?.invoice?.status_po === 3
                ? dikirim
                : purchaseOrder?.invoice?.status_po === 0
                ? pending
                : purchaseOrder?.invoice?.status_po === 2
                ? diproses
                : purchaseOrder?.invoice?.status_po === 99
                ? ditolak
                : purchaseOrder?.invoice?.status_po === 98
                ? expired
                : purchaseOrder?.invoice?.status_po === "New Invoice"
                ? new_invoice
                : ""}
              {/*: {purchaseOrder.status_name}*/}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="row" m={1}>
            <Box width={"30%"}>Alamat Pengiriman</Box>
            <Box width={"65%"}>
              <div style={{ textAlign: "justify" }}>
                : {`${purchaseOrder?.invoice?.order_address}, ${purchaseOrder?.invoice?.order_village}, ${purchaseOrder?.invoice?.order_district}, ${purchaseOrder?.invoice?.order_regency}, ${purchaseOrder?.invoice?.order_province} - ${purchaseOrder?.invoice?.order_post_code}`}
              </div>
            </Box>
            <Box width={"5%"}>
              <CopyToClipboard text={`${purchaseOrder?.invoice?.order_address}, ${purchaseOrder?.invoice?.order_village}, ${purchaseOrder?.invoice?.order_district}, ${purchaseOrder?.invoice?.order_regency}, ${purchaseOrder?.invoice?.order_province} - ${purchaseOrder?.invoice?.order_post_code}`}>
                <Tooltip title="copy to clipboard" placement={"top"}>
                  <IconButton className={classes.zeroPadding} color={"primary"}>
                    <FileCopyIcon fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <div className={classes.row}>
        <div className={classes.tagMenu}>List Order</div>
      </div>

      <div className={classes.row}>
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ minWidth: "340px" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

export default ListOrders;
