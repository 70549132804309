import React,{Fragment} from "react";
import {Box, Button, Typography} from "@material-ui/core";

const PaymentProofFileConfirmation = props => {
  const {classes, closedModalDialog,onSubmit,data} = props;

  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
        <Box>
          <Typography variant={"h5"}>
            Pastikan foto bukti bayar sudah benar dan sesuai.<br/>
            Upload bukti pembayaran untuk memudahkan admin pusat dalam pengecekan.
          </Typography>
        </Box>
      </Box>
      <br/>
      <Box display={"flex"} justifyContent="flex-end">
        <Box m={1}>
          <Button
            variant={"outlined"}
            size={"small"}
            className={classes.btnCancel}
            onClick={closedModalDialog}
          >
            Batal
          </Button>
        </Box>
        <Box m={1}>
          <Button
            variant={"contained"}
            size={"small"}
            className={classes.btnSuccess}
            onClick={() => onSubmit(data)}
          >
            Kirim
          </Button>
        </Box>
      </Box>
    </Fragment>
  )
}

export default PaymentProofFileConfirmation;