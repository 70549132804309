import * as actionTypes from '../actions/types'
import { updateObject } from '../shared/utility'

const initialState = {
  rounds: {},
  changing: 0,
  loading: false,
  error: {},
}

const fetchRoundsStart = (state, action) => {
  return updateObject(state, { loading: true })
}

const fetchRoundsSuccess = (state, action) => {
  return updateObject(state, {
    rounds: action.rounds,
    loading: false
  })
}

const fetchRoundsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROUNDS_START:
      return fetchRoundsStart(state, action)
    case actionTypes.FETCH_ROUNDS_SUCCESS:
      return fetchRoundsSuccess(state, action)
    case actionTypes.FETCH_ROUNDS_FAIL:
      return fetchRoundsFail(state, action)
    default: return state
  }
}

export default reducer