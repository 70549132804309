import React, {Fragment, useEffect} from "react";

// Redux
import { connect } from 'react-redux'
import { getProduct } from '../../../actions/product'
// import {useHistory} from "react-router-dom";
import moment from "moment";
import {Loading} from "../../../components/UI";
import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import {isEmpty} from "../../../shared/utility";
import FormProductList from "./FormProductList";

const FormProduct = props => {
  const { getProduct, product: { products, loadingProducts }, customer : { searchCustomerClear }, date, product_type, classes } = props;

  const newDate = moment(date)
  const dateToUTC = moment.utc(newDate).format('YYYY-MM-DD HH:mm:ss');

  useEffect(() => {
    getProduct(searchCustomerClear[0].status, dateToUTC, product_type)
  }, [getProduct, searchCustomerClear, dateToUTC, product_type])

  let formProductList = '';
  if(!isEmpty(products)){
    formProductList = <FormProductList products={products} classes={classes} getCartQty={(stateData) =>sendCartQty(stateData)}/>;
  }

  const sendCartQty = stateData => {
    props.getCartQty(stateData)
  }

  return (loadingProducts ? <Loading/> :
    <Fragment>

      <div className={classes.row}>
        <Paper className={classes.root}>
          <div className={classes.paperPadding}>
            <Typography className={classes.tagMenu} variant={"h5"}>Daftar Produk</Typography>
          </div>
          {formProductList}
        </Paper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  product: state.product,
  customer: state.customer
})

export default connect(mapStateToProps,{getProduct})(FormProduct);