/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Button,
  Card,
  CardMedia,
  CardActionArea,
  CardActions,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { FileCopy as FileCopyIcon } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import commissionService from '../../services/commission.service';
import ChipCommission from '../../components/ChipCommission';
import Dropzone from './components/Dropzone';
import { useSnackbar } from '../../shared/useSnackbar';

const CommissionDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { createSnackbar } = useSnackbar();

  const showSnackbar = (msg, theme) => {
    createSnackbar({
      message: msg,
      dismissable: false,
      theme: theme,
      sticky: false,
    });
  };

  const [dataAPI, setDataAPI] = useState(null);
  const [image, setImage] = useState([]);

  const uploadProofTransfer = async () => {
    try {
      const formData = new FormData();
      formData.append('file', image[0]);
      formData.append('id', id);
      const responseUpload = await commissionService.uploadProof(formData);
      await commissionService.approve({
        id_aogmog_commission: id,
        id_user: dataAPI.aogmog_commission.id_aogmog_users,
        dest_account_number: dataAPI.preferal.nomor_rekening,
        dest_account_name: dataAPI.preferal.pemilik_rekening,
        dest_bank_name: dataAPI.preferal.nama_bank,
        image: responseUpload.url,
      });
      getDetailCommission();
      showSnackbar('Berhasil upload bukti transfer', 'success');
    } catch (e) {
      console.error(e.response.data.message);
      showSnackbar(e.response.data.message, 'error');
    }
  };

  const getDetailCommission = async () => {
    try {
      const data = await commissionService.detail(id);
      setDataAPI(data);
    } catch (e) {
      console.error(e.response.data.message);
      showSnackbar(e.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getDetailCommission();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Paper className={classes.paperContent}>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>
            Detail Komisi
          </Typography>
          <div className={classes.row}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>No Transaksi</Typography>
                    <Typography>Jenis Transaksi</Typography>
                    <Typography>Nama</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{`: ${dataAPI?.aogmog_commission.invoice_id}`}</Typography>
                    <Typography>{`: ${dataAPI?.aogmog_commission.payment_type}`}</Typography>
                    <Typography>{`: ${dataAPI?.aogmog_commission.name_aogmog}`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography>Tanggal Transaksi</Typography>
                    <Typography>Status</Typography>
                    <Typography>Tanggal Klaim</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography>{`: ${moment(
                      dataAPI?.aogmog_commission.created_at
                    ).format('DD MMMM YYYY HH:mm')}`}</Typography>
                    <Typography>
                      :{' '}
                      <ChipCommission
                        status={dataAPI?.aogmog_commission.status}
                      />
                    </Typography>
                    <Typography>{`: ${
                      dataAPI?.aogmog_commission.claimed_at || '-'
                    }`}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
      <Grid container className={classes.row} spacing={2}>
        <Grid item xs={4}>
          <Paper>
            <div className={classes.paperContent}>
              <Typography variant='h4' className={classes.tagMenu}>
                Rincian Pembayaran
              </Typography>
              <div className={classes.row}>
                <Typography className={classes.textCenter}>
                  Selesaikan pembayaran anda <br />
                  Transfer sesuai nominal dibawah
                </Typography>
              </div>
              <div className={classes.row}>
                <Typography variant={'h5'} className={classes.rek}>
                  <NumberFormat
                    value={dataAPI?.aogmog_commission.payment}
                    displayType={'text'}
                    prefix={'Rp '}
                    thousandSeparator={true}
                  />
                  <CopyToClipboard text={dataAPI?.aogmog_commission.payment}>
                    <Tooltip title='copy to clipboard' placement={'right'}>
                      <IconButton>
                        <FileCopyIcon fontSize={'small'} />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </Typography>
              </div>
              <div className={classes.row}>
                <Typography className={classes.textCenter}>
                  Ke Nomor Rekening
                </Typography>
              </div>
              <div className={classes.row}>
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  justifyContent={'center'}
                >
                  <Box>
                    <Typography variant={'h5'} className={classes.rek}>
                      {dataAPI?.preferal.nomor_rekening}
                    </Typography>
                  </Box>
                  <Box>
                    <CopyToClipboard text={dataAPI?.preferal.nomor_rekening}>
                      <Tooltip title='copy to clipboard'>
                        <IconButton>
                          <FileCopyIcon fontSize={'small'} />
                        </IconButton>
                      </Tooltip>
                    </CopyToClipboard>
                  </Box>
                </Box>
                <Box display='flex' justifyContent={'center'}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {dataAPI?.preferal.nama_bank}
                  </Typography>
                </Box>
                <Box display='flex' justifyContent={'center'}>
                  <Typography>
                    an. {dataAPI?.preferal.pemilik_rekening}
                  </Typography>
                </Box>
              </div>
            </div>
          </Paper>
        </Grid>
        {dataAPI?.aogmog_commission.status >= 1 && (
          <Grid item xs={8}>
            <Paper className={classes.paperContent}>
              <Typography variant='h4' className={classes.tagMenu}>
                Bukti Transfer
              </Typography>
              <div className={classes.paperContent}>
                {dataAPI?.aogmog_commission.status === 1 ? (
                  <Fragment>
                    <div className={classes.row}>
                      <form>
                        <div className={classes.row}>Upload Foto</div>
                        <Dropzone
                          multiple={false}
                          fileType={'image/png,image/jpg,image/jpeg'}
                          files={image}
                          setFiles={setImage}
                        />
                        <Box style={{ marginTop: '10px' }}>
                          <Box>Note:</Box>
                          <Box flexGrow={1} style={{ paddingLeft: 3 }}>
                            Segera upload Foto/Dokumen bukti bayar jika sudah
                            melakukan transfer pembayaran. Format
                            PNG/JPG/JPEG/PDF maksimal 2mb.
                          </Box>
                          <Box marginTop={3}>
                            <Button
                              fullWidth
                              color='primary'
                              variant={'contained'}
                              size={'medium'}
                              onClick={uploadProofTransfer}
                              disabled={image.length === 0}
                            >
                              Kirim
                            </Button>
                          </Box>
                        </Box>
                      </form>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className={classes.row}>
                      <Grid container spacing={2} className={classes.row}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Fragment>
                            {dataAPI?.aogmog_commission_manual ? (
                              <Grid container spacing={2}>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                  <Card className={classes.imageList}>
                                    <CardActionArea>
                                      <CardMedia
                                        className={classes.media}
                                        image={
                                          dataAPI?.aogmog_commission_manual
                                            .image
                                        }
                                        title='Contemplative Reptile'
                                      />
                                    </CardActionArea>
                                    <CardActions>
                                      <Button
                                        variant='contained'
                                        color='secondary'
                                        className={classes.buttonWarning}
                                        startIcon={<Search />}
                                        onClick={() =>
                                          window.open(
                                            dataAPI?.aogmog_commission_manual
                                              .image,
                                            '_blank'
                                          )
                                        }
                                        fullWidth
                                      >
                                        Lihat
                                      </Button>
                                    </CardActions>
                                  </Card>
                                </Grid>
                              </Grid>
                            ) : (
                              <Typography>Belum ada bukti transfer</Typography>
                            )}
                          </Fragment>
                        </Grid>
                      </Grid>
                    </div>
                  </Fragment>
                )}
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CommissionDetail;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paperContent: {
    padding: theme.spacing(3),
  },
  chipWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  chipSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
  textCenter: {
    textAlign: 'center',
  },
  rek: {
    color: theme.palette.black,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  rincianTable: {
    padding: '0 0',
    borderBottom: 'none',
  },
  tagMenu: {
    fontWeight: 'bold',
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
}));
