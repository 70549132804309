import { 
    GET_CART_BUYBACK, 
    ADD_TO_CART_BUYBACK,
    DELETE_CART_ITEM_BUYBACK,
    DELETE_CART_ALL_ITEM_BUYBACK, 
    ADD_TO_CART_BUYBACK_START,
    GET_CART_BUYBACK_START,
    DELETE_CART_ALL_ITEM_BUYBACK_START,
    DELETE_CART_ITEM_BUYBACK_START
} from '../actions/types'

const initialState = {
    cart: null,
    carts: null,
    delCart: null,
    delAllCart: null,
    loading: false,
    loadingAddCart: false,
    loadingDeleteItemCart: false,
    loadingDeleteAllItemCart: false,
    error: {},
    counting : 0
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_CART_BUYBACK_START:
            return {
                ...state,
                loading: true,
            }
        case GET_CART_BUYBACK:
            return {
                ...state,
                carts: payload,
                loading: false,
            }
        case ADD_TO_CART_BUYBACK_START:
            return {
                ...state,
                loadingAddCart: true,
            }
        case ADD_TO_CART_BUYBACK:
            return {
                ...state,
                cart: payload,
                loadingAddCart: false,
                counting : initialState.counting += 1
            }
        case DELETE_CART_ITEM_BUYBACK_START:
            return {
                ...state,
                loadingDeleteItemCart: true,
            }
        case DELETE_CART_ITEM_BUYBACK:
            return {
                ...state,
                delCart: payload,
                loadingDeleteItemCart: false,
                counting : initialState.counting -= 1
            }
        case DELETE_CART_ALL_ITEM_BUYBACK_START:
            return {
                ...state,
                loadingDeleteAllItemCart: true,
            }
        case DELETE_CART_ALL_ITEM_BUYBACK:
            return {
                ...state,
                delAllCart: payload,
                loadingDeleteAllItemCart: false,
                counting : initialState.counting = 0
            }
        default:
            return state
    }
}

export default reducer;