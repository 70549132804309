import { getApi,getBlobApi,postApi,putApi} from '../shared/api';



  const getlist = (params) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/registration/aogmog/po/list`;
   
   return getApi(endpoint, params)
     .then((response) => {    
        return response;
     });
  };

  const getdetail = (params) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/registration/aogmog/po/detail`;
   
   return getApi(endpoint, params)
     .then((response) => {    
        return response;
     });
  };

  const changestatuspo = (params) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/registration/aogmog/statuspo`;
   
   return putApi(endpoint, params
     )
     .then((response) => {
       
       return response;
     });
 };

 export const downloadListPOAOGFormat1 = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/report/ondemand/branch-aogmog-registration-upgrade`;

  return getBlobApi(endpoint, params).then((response) => {
    return response;
  });
}
 


//decalre
const registrationService = { 
    getlist,
    getdetail,
    changestatuspo,
  };
  export default registrationService;