import { getApi,postApi,putApi} from '../shared/api';

const bankMaster = (params) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/bank/master`;
   
   return getApi(endpoint, params)
     .then((response) => {    
        return response;
     });
  };


//declare
const bankService = { 
    bankMaster,
  };
  export default bankService;