import React,{Fragment} from "react";
import {TableCell, TableRow} from "@material-ui/core";
import NumberFormat from "react-number-format";

const ListOrder = props => {
  const {data, index} = props;
  return (
    <Fragment>
      <TableRow key={index}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {data.name}
        </TableCell>
        <TableCell>
          <NumberFormat value={data.sell_price} displayType={'text'} prefix={'Rp '} thousandSeparator={true}/>
        </TableCell>
        <TableCell>
          <NumberFormat value={data.qty} displayType={'text'} thousandSeparator={true}/>
        </TableCell>
        <TableCell>
          <NumberFormat value={data.sub_total} displayType={'text'} prefix={'Rp '} thousandSeparator={true}/>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default ListOrder;