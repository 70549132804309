import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Typography,
    Grid,
    TextField,
    Button,
    AppBar,
    Toolbar,
    Backdrop,
    CircularProgress
} from '@material-ui/core'
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
// redux
import { connect } from 'react-redux'
import { resetPassword } from '../../actions/profile'

const useStyles = makeStyles((theme) => ({
	root: {
	  height: '100vh'
    },
    rootAppbar: {
        flexGrow: 1,
    },
    backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
    appBarBackground: {
        backgroundColor: '#FFFFFF'
    },
	image: {
	  backgroundColor: '#011747',
	},
	paper: {
	  margin: theme.spacing(8, 4),
	  display: 'flex',
	  flexDirection: 'column',
	  alignItems: 'center'
	},
	avatar: {
	  marginTop: theme.spacing(8),
	},
	form: {
	  width: '100%', // Fix IE 11 issue.
	  marginTop: theme.spacing(1),
	  paddingTop: 30
	},
	textField: {
	  [`& fieldset`]: {
		borderRadius: 100,
	  },
	},
	submit: {
	  margin: theme.spacing(3, 0, 2),
	  backgroundColor: '#FF9300',
	  '&:hover': {
		backgroundColor: '#ef8b03'
	  },
	  color: '#FFFFFF',
	  borderRadius: 100
	},
	contentName: {
	  // padding: '200px 20px 0px',
	  padding: theme.spacing(25, 6)
	},
	center: {
	  color: '#FFFFFF',
	  fontSize: 50,
	  paddingBottom: 15
	},
	center2: {
	  color: '#FFFFFF',
	  fontSize: 60
    },
    title: {
        flexGrow: 1,
    },
    textBody: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },
}))

const ResetPassword = props =>  {
    const classes = useStyles()
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    
    const {
        resetPassword,
        profile: {
            loadingResetPassword
        }
    } = props
	
	const onSubmit = event => {
        const data = {
            email : event.email,
            env: 'pos'
        }
        resetPassword(data, history)
    }
    
    return loadingResetPassword ? 
	<Backdrop className={classes.backdrop} open>
		<CircularProgress color="inherit" />
    </Backdrop> 
    :
    <Fragment>
        <>
        <div className={classes.rootAppbar}>
            <AppBar position="fixed" className={classes.appBarBackground}>
                <Toolbar>
                    <div className={classes.title}>
                        <img src={`${process.env.PUBLIC_URL}/images/logo/logo_eoa.png`} alt="logo-gold" width="5%" height="auto" />
                    </div>
                    <Link to='/sign-in'>
                        <Typography variant="h4">
                            Login
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
        </div>
        <div className={classes.root}>
            <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} elevation={6} square>
                    
                    <div className={classes.paper}>
                    <div container>
                        <div item lg={12} sm={12} md={12} xs={12}>
                        <center>
                        <img src={`${process.env.PUBLIC_URL}/images/logo/logo_eoa.png`} alt="logo-gold" width="50%" height="auto" />

                        </center>
                        
                        </div>
                    </div>
                    {/* <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    <Typography component="h1" variant="h5"className={classes.textBody}>
                        Untuk dapat mengubah password, silahkan masukan Email Anda. <br/>
                        Segera cek email Anda setelah klik tombol KIRIM.
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            inputRef={register}
                            error={!!errors.email}
                            helperText={errors.email && errors.email.message}
                            className={classes.textField}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Kirim
                        </Button>
                    </form>
                    </div>
                </Grid>
            </Grid>
        </div>
        </>
    </Fragment>
}

const mapStateToProps = state => ({
    profile: state.profile
})

export default connect(mapStateToProps, { resetPassword })(ResetPassword)