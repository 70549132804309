import React,{Fragment} from "react";
import {Box} from "@material-ui/core";

const PaymentProofFileShow = props => {
  const {classes, data} = props;

  return (
    <Fragment>
      <Box display={"flex"} justifyContent={"center"} className={classes.boxMarginRow}>
        <Box>
          <img alt="payment-proof" src={data.link} width={'100%'} height={'auto'}/>
        </Box>
      </Box>
      <br/>
    </Fragment>
  )
}

export default PaymentProofFileShow;