import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  swapHistories: {},
  swapHistory: {},
  loading: false,
  error: null,
  counting: 0
}

const fetchSwapGramationsStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const fetchSwapGramationsSuccess = (state, action) => {
  return updateObject(state, {
    swapHistories: action.swapHistories,
    loading: false
  })
}

const fetchSwapGramationsFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const storeSwapGramationStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const storeSwapGramationSuccess = (state, action) => {
  return updateObject(state, {
    counting: state.counting + 1,
    loading: false,
  })
}

const storeSwapGramationFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const getSwapGramationStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const getSwapGramationSuccess = (state, action) => {
  return updateObject(state, {
    swapHistory: action.swapHistory,
    loading: false
  })
}

const getSwapGramationFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SWAP_GRAMATIONS_START: return fetchSwapGramationsStart(state, action)
    case actionTypes.FETCH_SWAP_GRAMATIONS_SUCCESS: return fetchSwapGramationsSuccess(state, action)
    case actionTypes.FETCH_SWAP_GRAMATIONS_FAIL: return fetchSwapGramationsFail(state, action)
    case actionTypes.GET_SWAP_GRAMATION_START: return getSwapGramationStart(state, action)
    case actionTypes.GET_SWAP_GRAMATION_SUCCESS: return getSwapGramationSuccess(state, action)
    case actionTypes.GET_SWAP_GRAMATION_FAIL: return getSwapGramationFail(state, action)
    case actionTypes.STORE_SWAP_GRAMATION_START: return storeSwapGramationStart(state, action)
    case actionTypes.STORE_SWAP_GRAMATION_SUCCESS: return storeSwapGramationSuccess(state, action)
    case actionTypes.STORE_SWAP_GRAMATION_FAIL: return storeSwapGramationFail(state, action)

    default: return state
  }
}

export default reducer;