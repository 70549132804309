// Alert
export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"

// Login
export const ADD_LOGIN_START = "ADD_LOGIN_START"
export const ADD_LOGIN = "ADD_LOGIN"
export const LOGIN_ERROR = "LOGIN_ERROR"

// Profile
export const GET_PROFILE = "GET_PROFILE"
export const EDIT_PROFILE = "EDIT_PROFILE"
export const UPDATE_PROFILE = "UPDATE_PROFILE"

export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

// Customer
export const ADD_CUSTOMER = "ADD_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAILED = "ADD_CUSTOMER_FAILED"
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR"

export const EDIT_CUSTOMER = "EDIT_CUSTOMER"
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS"
export const EDIT_CUSTOMER_FAILED = "EDIT_CUSTOMER_FAILED"

export const GET_CUSTOMER = "GET_CUSTOMER"
export const GET_DETAIL_CUSTOMER = "GET_DETAIL_CUSTOMER"
export const GET_SEARCH_CUSTOMER = "GET_SEARCH_CUSTOMER"

export const GET_SEARCH_CUSTOMER_BUYBACK_START = "GET_SEARCH_CUSTOMER_BUYBACK_START"
export const GET_SEARCH_CUSTOMER_BUYBACK = "GET_SEARCH_CUSTOMER_BUYBACK"

export const GET_CUSTOMER_V2_START = "GET_CUSTOMER_V2_START"
export const GET_CUSTOMER_V2 = "GET_CUSTOMER_V2"

export const GET_SEARCH_CUSTOMER_CLEAR_START = "GET_SEARCH_CUSTOMER_CLEAR_START"
export const GET_SEARCH_CUSTOMER_CLEAR = "GET_SEARCH_CUSTOMER_CLEAR"


// Dashboard
export const GET_PRODUCT_DASHBOARD = "GET_PRODUCT_DASHBOARD"
export const GET_PRODUCT_BUYBACK_DASHBOARD = "GET_PRODUCT_BUYBACK_DASHBOARD"
export const GET_CARD_STATS = "GET_CARD_STATS"
export const GET_NET_INCOME = "GET_NET_INCOME"
export const GET_GRAFIK_COST = "GET_GRAFIK_COST"
export const GET_GOLD_PRICE = "GET_GOLD_PRICE"
export const GET_TRANSACTION_SALES = "GET_TRANSACTION_SALES"

export const GET_GRAFIK_STOCK = "GET_GRAFIK_STOCK"
export const GET_GRAFIK_STOCK2 = "GET_GRAFIK_STOCK2"
export const GET_GRAFIK_STOCK3 = "GET_GRAFIK_STOCK3"
export const GET_GRAFIK_STOCK4 = "GET_GRAFIK_STOCK4"
export const GET_GRAFIK_STOCK5 = "GET_GRAFIK_STOCK5"
export const GET_GRAFIK_STOCK6 = "GET_GRAFIK_STOCK6"

export const GET_GRAFIK_HPP = "GET_GRAFIK_HPP"

export const FETCH_GRAFIK_STOCKS_START = "FETCH_GRAFIK_STOCKS_START"
export const FETCH_GRAFIK_STOCKS_SUCCESS = "FETCH_GRAFIK_STOCKS_SUCCESS"
export const FETCH_GRAFIK_STOCKS_FAIL = "FETCH_GRAFIK_STOCKS_FAIL"

// Purchase Order
export const GET_PURCHASE_ORDER = "GET_PURCHASE_ORDER"
export const GET_PURCHASE_ORDER_END = "GET_PURCHASE_ORDER_END"

export const GET_PURCHASE_ORDER_DETAIL_START = "GET_PURCHASE_ORDER_DETAIL_START"
export const GET_PURCHASE_ORDER_DETAIL = "GET_PURCHASE_ORDER_DETAIL"

export const ADD_PURCHASE_ORDER_START = "ADD_PURCHASE_ORDER_START"
export const ADD_PURCHASE_ORDER_SUCCESS = "ADD_PURCHASE_ORDER_SUCCESS"
export const ADD_PURCHASE_ORDER_FAIL = "ADD_PURCHASE_ORDER_FAIL"

export const ADD_PURCHASE_ORDER_DETAIL = "ADD_PURCHASE_ORDER_DETAIL"

export const DELETE_PURCHASE_ORDER_DETAIL = "DELETE_PURCHASE_ORDER_DETAIL"

export const GET_PRODUCT_PO = "GET_PRODUCT_PO"

export const UPDATE_PURCHASE_ORDER_DONE = "UPDATE_PURCHASE_ORDER_DONE"

export const FETCH_PURCHASE_ORDERS_START = "FETCH_PURCHASE_ORDERS_START"
export const FETCH_PURCHASE_ORDERS_SUCCESS = "FETCH_PURCHASE_ORDERS_SUCCESS"
export const FETCH_PURCHASE_ORDERS_FAIL = "FETCH_PURCHASE_ORDERS_FAIL"

export const FETCH_PURCHASE_ORDER_PRODUCTS_START = "FETCH_PURCHASE_ORDER_PRODUCTS_START"
export const FETCH_PURCHASE_ORDER_PRODUCTS_SUCCESS = "FETCH_PURCHASE_ORDER_PRODUCTS_SUCCESS"
export const FETCH_PURCHASE_ORDER_PRODUCTS_FAIL = "FETCH_PURCHASE_ORDER_PRODUCTS_FAIL"

export const STORE_PURCHASE_ORDER_START = "STORE_PURCHASE_ORDER_START"
export const STORE_PURCHASE_ORDER_SUCCESS = "STORE_PURCHASE_ORDER_SUCCESS"
export const STORE_PURCHASE_ORDER_FAIL = "STORE_PURCHASE_ORDER_FAIL"

export const GET_PURCHASE_ORDER_START = "GET_PURCHASE_ORDER_START"
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS"
export const GET_PURCHASE_ORDER_FAIL = "GET_PURCHASE_ORDER_FAIL"

export const STORE_PURCHASE_ORDER_FILE_START = "STORE_PURCHASE_ORDER_FILE_START"
export const STORE_PURCHASE_ORDER_FILE_SUCCESS = "STORE_PURCHASE_ORDER_FILE_SUCCESS"
export const STORE_PURCHASE_ORDER_FILE_FAIL = "STORE_PURCHASE_ORDER_FILE_FAIL"

export const UPLOAD_DOCUMENT_PURCHASE_ORDER_START = "UPLOAD_DOCUMENT_PURCHASE_ORDER_START"
export const UPLOAD_DOCUMENT_PURCHASE_ORDER_SUCCESS = "UPLOAD_DOCUMENT_PURCHASE_ORDER_SUCCESS"
export const UPLOAD_DOCUMENT_PURCHASE_ORDER_FAIL = "UPLOAD_DOCUMENT_PURCHASE_ORDER_FAIL"

export const STORE_PURCHASE_ORDER_RECEIPT_START = "STORE_PURCHASE_ORDER_RECEIPT_START"
export const STORE_PURCHASE_ORDER_RECEIPT_SUCCESS = "STORE_PURCHASE_ORDER_RECEIPT_SUCCESS"
export const STORE_PURCHASE_ORDER_RECEIPT_FAIL = "STORE_PURCHASE_ORDER_RECEIPT_FAIL"

export const DELETE_IMAGE_PO_REDUX = "DELETE_IMAGE_PO_REDUX"
export const DELETE_DOCUMENT_PO_REDUX = "DELETE_DOCUMENT_PO_REDUX"

export const CLEAR_FILE_PO_REDUX = "CLEAR_FILE_PO_REDUX"

export const DOWNLOAD_PURCHASE_ORDERS_START = "DOWNLOAD_PURCHASE_ORDERS_START"
export const DOWNLOAD_PURCHASE_ORDERS_SUCCESS = "DOWNLOAD_PURCHASE_ORDERS_SUCCESS"
export const DOWNLOAD_PURCHASE_ORDERS_FAIL = "DOWNLOAD_PURCHASE_ORDERS_FAIL"
export const DOWNLOAD_PURCHASE_ORDERS_OLD_START = "DOWNLOAD_PURCHASE_ORDERS_OLD_START"
export const DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS = "DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS"
export const DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL = "DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL"

// Cart
export const GET_CART_START = "GET_CART_START"
export const GET_CART = "GET_CART"

export const ADD_TO_CART_START = "ADD_TO_CART_START"
export const ADD_TO_CART = "ADD_TO_CART"

export const DELETE_CART_ITEM_START = "DELETE_CART_ITEM_START"
export const DELETE_CART_ITEM = "DELETE_CART_ITEM"

export const DELETE_CART_ALL_ITEM_START = "DELETE_CART_ALL_ITEM_START"
export const DELETE_CART_ALL_ITEM = "DELETE_CART_ALL_ITEM"

// Payment
export const PURCHASE_ITEM_PAY_START = "PURCHASE_ITEM_PAY_START"
export const PURCHASE_ITEM_PAY = "PURCHASE_ITEM_PAY"
export const PURCHASE_ITEM_PAY_ERROR = "PURCHASE_ITEM_PAY_ERROR"

// Transaction
export const GET_TRANSACTION = "GET_TRANSACTION"
export const GET_SEARCH_TRANSACTION_START = "GET_SEARCH_TRANSACTION_START"
export const GET_SEARCH_TRANSACTION = "GET_SEARCH_TRANSACTION"

export const GET_DETAIL_TRANSACTION_START = "GET_DETAIL_TRANSACTION_START"
export const GET_DETAIL_TRANSACTION = "GET_DETAIL_TRANSACTION"

export const DOWNLOAD_REPORT_TRANSACTION_START = "DOWNLOAD_REPORT_TRANSACTION_START"
export const DOWNLOAD_REPORT_TRANSACTION_SUCCESS = "DOWNLOAD_REPORT_TRANSACTION_SUCCESS"
export const DOWNLOAD_REPORT_TRANSACTION_FAILED = "DOWNLOAD_REPORT_TRANSACTION_FAILED"

export const FETCH_TRANSACTIONS_START = "FETCH_TRANSACTIONS_START"
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS"
export const FETCH_TRANSACTIONS_FAIL = "FETCH_TRANSACTIONS_FAIL"

export const DOWNLOAD_TRANSACTIONS_START = "DOWNLOAD_TRANSACTIONS_START"
export const DOWNLOAD_TRANSACTIONS_SUCCESS = "DOWNLOAD_TRANSACTIONS_SUCCESS"
export const DOWNLOAD_TRANSACTIONS_FAIL = "DOWNLOAD_TRANSACTIONS_FAIL"

// First Balancing
export const GET_FIRST_BALANCE = "GET_FIRST_BALANCE"
export const ADD_FIRST_BALANCE = "ADD_FIRST_BALANCE"
export const ADD_FIRST_BALANCE_ERROR = "ADD_FIRST_BALANCE_ERROR"

// Product
export const GET_PRODUCT_START = "GET_PRODUCT_START"
export const GET_PRODUCT = "GET_PRODUCT"

export const CLEAR_PRODUCT = "CLEAR_PRODUCT"

// Stock Opname
export const GET_STOCK_OPNAME = "GET_STOCK_OPNAME"
export const BALANCING_STOCK_OPNAME = "BALANCING_STOCK_OPNAME"

// Transaksi Buyback
// Cart
export const GET_CART_BUYBACK_START = "GET_CART_BUYBACK_START"
export const GET_CART_BUYBACK = "GET_CART_BUYBACK"

export const ADD_TO_CART_BUYBACK_START = "ADD_TO_CART_BUYBACK_START"
export const ADD_TO_CART_BUYBACK = "ADD_TO_CART_BUYBACK"

export const DELETE_CART_ITEM_BUYBACK_START = "DELETE_CART_ITEM_BUYBACK_START"
export const DELETE_CART_ITEM_BUYBACK = "DELETE_CART_ITEM_BUYBACK"

export const DELETE_CART_ALL_ITEM_BUYBACK_START = "DELETE_CART_ALL_ITEM_BUYBACK_START"
export const DELETE_CART_ALL_ITEM_BUYBACK = "DELETE_CART_ALL_ITEM_BUYBACK"

export const FIRST_ADD_TO_CART_START = "FIRST_ADD_TO_CART_START"
export const FIRST_ADD_TO_CART_SUCCESS = "FIRST_ADD_TO_CART_SUCCESS"
export const FIRST_ADD_TO_CART_FAIL = "FIRST_ADD_TO_CART_FAIL"

// Payment
export const PURCHASE_ITEM_PAY_BUYBACK = "PURCHASE_ITEM_PAY_BUYBACK"
export const PURCHASE_ITEM_PAY_ERROR_BUYBACK = "PURCHASE_ITEM_PAY_ERROR_BUYBACK"

export const GET_TRANSACTION_BUYBACK = "GET_TRANSACTION_BUYBACK"

export const GET_SEARCH_TRANSACTION_BUYBACK_START = "GET_SEARCH_TRANSACTION_BUYBACK_START"
export const GET_SEARCH_TRANSACTION_BUYBACK = "GET_SEARCH_TRANSACTION_BUYBACK"

export const GET_DETAIL_TRANSACTION_BUYBACK_START = "GET_DETAIL_TRANSACTION_BUYBACK_START"
export const GET_DETAIL_TRANSACTION_BUYBACK = "GET_DETAIL_TRANSACTION_BUYBACK"

export const DOWNLOAD_REPORT_TRANSACTION_BUYBACK_START = "DOWNLOAD_REPORT_TRANSACTION_BUYBACK_START"
export const DOWNLOAD_REPORT_TRANSACTION_BUYBACK_SUCCESS = "DOWNLOAD_REPORT_TRANSACTION_BUYBACK_SUCCESS"
export const DOWNLOAD_REPORT_TRANSACTION_BUYBACK_FAILED = "DOWNLOAD_REPORT_TRANSACTION_BUYBACK_FAILED"

export const FETCH_TRANSACTIONS_BUYBACK_START = "FETCH_TRANSACTIONS_BUYBACK_START"
export const FETCH_TRANSACTIONS_BUYBACK_SUCCESS = "FETCH_TRANSACTIONS_BUYBACK_SUCCESS"
export const FETCH_TRANSACTIONS_BUYBACK_FAIL = "FETCH_TRANSACTIONS_BUYBACK_FAIL"

export const DOWNLOAD_TRANSACTIONS_BUYBACK_START = "DOWNLOAD_TRANSACTIONS_BUYBACK_START"
export const DOWNLOAD_TRANSACTIONS_BUYBACK_SUCCESS = "DOWNLOAD_TRANSACTIONS_BUYBACK_SUCCESS"
export const DOWNLOAD_TRANSACTIONS_BUYBACK_FAIL = "DOWNLOAD_TRANSACTIONS_BUYBACK_FAIL"

// Stock History
export const GET_STOCK_HISTORY = "GET_STOCK_HISTORY"
export const GET_STOCK_HISTORY_DETAIL = "GET_STOCK_HISTORY_DETAIL"
export const FETCH_STOCK_HISTORIES_START = "FETCH_STOCK_HISTORIES_START"
export const FETCH_STOCK_HISTORIES_SUCCESS = "FETCH_STOCK_HISTORIES_SUCCESS"
export const FETCH_STOCK_HISTORIES_FAIL = "FETCH_STOCK_HISTORIES_FAIL"
export const DOWNLOAD_STOCK_HISTORIES_START = "DOWNLOAD_STOCK_HISTORIES_START"
export const DOWNLOAD_STOCK_HISTORIES_SUCCESS = "DOWNLOAD_STOCK_HISTORIES_SUCCESS"
export const DOWNLOAD_STOCK_HISTORIES_FAIL = "DOWNLOAD_STOCK_HISTORIES_FAIL"

// Other Purchase Order
export const GET_OTHER_PURCHASE_ORDER = "GET_OTHER_PURCHASE_ORDER"
export const ADD_OTHER_PURCHASE_ORDER = "ADD_OTHER_PURCHASE_ORDER"

export const GET_OTHER_PURCHASE_ORDER_DETAIL = "GET_OTHER_PURCHASE_ORDER_DETAIL"
export const ADD_OTHER_PURCHASE_ORDER_DETAIL = "ADD_OTHER_PURCHASE_ORDER_DETAIL"
export const DELETE_OTHER_PURCHASE_ORDER_DETAIL = "DELETE_OTHER_PURCHASE_ORDER_DETAIL"
export const UPDATE_OTHER_PURCHASE_ORDER_DETAIL = "UPDATE_OTHER_PURCHASE_ORDER_DETAIL"

// Forgot Password
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"

//Member
export const FETCH_MEMBERS_START = "FETCH_MEMBERS_START";
export const FETCH_MEMBERS_SUCCESS = "FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_FAIL = "FETCH_MEMBERS_FAIL";

export const STORE_CART_REDUX = "STORE_CART_REDUX";
export const UPDATE_CART_REDUX = "UPDATE_CART_REDUX";
export const DELETE_CART_REDUX = "DELETE_CART_REDUX";

export const FETCH_BEGINNING_STOCK_START = "FETCH_BEGINNING_STOCK_START";
export const FETCH_BEGINNING_STOCK_SUCCESS = "FETCH_BEGINNING_STOCK_SUCCESS";
export const FETCH_BEGINNING_STOCK_FAIL = "FETCH_BEGINNING_STOCK_FAIL";
export const STORE_BEGINNING_STOCK_START = "STORE_BEGINNING_STOCK_START";
export const STORE_BEGINNING_STOCK_SUCCESS = "STORE_BEGINNING_STOCK_SUCCESS";
export const STORE_BEGINNING_STOCK_FAIL = "STORE_BEGINNING_STOCK_FAIL";
export const GET_BEGINNING_STOCK_START = "GET_BEGINNING_STOCK_START";
export const GET_BEGINNING_STOCK_SUCCESS = "GET_BEGINNING_STOCK_SUCCESS";
export const GET_BEGINNING_STOCK_FAIL = "GET_BEGINNING_STOCK_FAIL";
export const UPDATE_BEGINNING_STOCK_START = "UPDATE_BEGINNING_STOCK_START";
export const UPDATE_BEGINNING_STOCK_SUCCESS = "UPDATE_BEGINNING_STOCK_SUCCESS";
export const UPDATE_BEGINNING_STOCK_FAIL = "UPDATE_BEGINNING_STOCK_FAIL";

//payment
export const STORE_PAYMENT_CART_START = "STORE_PAYMENT_CART_START";
export const STORE_PAYMENT_CART_SUCCESS = "STORE_PAYMENT_CART_SUCCESS";
export const STORE_PAYMENT_CART_FAIL = "STORE_PAYMENT_CART_FAIL";

//swap gramation
export const FETCH_SWAP_GRAMATIONS_START = "FETCH_SWAP_GRAMATIONS_START";
export const FETCH_SWAP_GRAMATIONS_SUCCESS = "FETCH_SWAP_GRAMATIONS_SUCCESS";
export const FETCH_SWAP_GRAMATIONS_FAIL = "FETCH_SWAP_GRAMATIONS_FAIL";
export const STORE_SWAP_GRAMATION_START = "STORE_SWAP_GRAMATION_START";
export const STORE_SWAP_GRAMATION_SUCCESS = "STORE_SWAP_GRAMATION_SUCCESS";
export const STORE_SWAP_GRAMATION_FAIL = "STORE_SWAP_GRAMATION_FAIL";
export const GET_SWAP_GRAMATION_START = "GET_SWAP_GRAMATION_START";
export const GET_SWAP_GRAMATION_SUCCESS = "GET_SWAP_GRAMATION_SUCCESS";
export const GET_SWAP_GRAMATION_FAIL = "GET_SWAP_GRAMATION_FAIL";

//round
export const FETCH_ROUNDS_START = "FETCH_ROUNDS_START";
export const FETCH_ROUNDS_SUCCESS = "FETCH_ROUNDS_SUCCESS";
export const FETCH_ROUNDS_FAIL = "FETCH_ROUNDS_FAIL";

// filter purchase order
export const UPDATE_FILTER_PURCHASE_ORDER = "UPDATE_FILTER_PURCHASE_ORDER";