import {
    EDIT_PROFILE, GET_PROFILE, UPDATE_PROFILE
} from '../actions/types'
import { updateObject } from '../shared/utility';
import * as types from '../actions/types';

const initialState = {
    profiles : null,
    profile : null,
    resetPassword: null,
    loadingResetPassword: false,
    loadingGetProfile: true,
    loading: false,
    error: {}
}

const changePasswordStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const changePasswordSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  })
}

const changePasswordFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  })
}

// Reset Password
const resetPasswordStart = (state, action) => {
  return updateObject(state, {
    loadingResetPassword: true
  })
}

const resetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    loadingResetPassword: false
  })
}

const resetPasswordFail = (state, action) => {
  return updateObject(state, {
    loadingResetPassword: false,
    error: action.error
  })
}

const reducer = function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: payload,
        loadingGetProfile: false
      }
    case EDIT_PROFILE:
      return {
        ...state,
        loading: true
      }
    case UPDATE_PROFILE:
      return {
        ...state,
        profiles: payload,
        loading: false
      }
    case types.CHANGE_PASSWORD_START: return changePasswordStart(state, action)
    case types.CHANGE_PASSWORD_SUCCESS: return changePasswordSuccess(state, action)
    case types.CHANGE_PASSWORD_FAIL: return changePasswordFail(state, action)
    case types.FORGOT_PASSWORD_START: return resetPasswordStart(state, action)
    case types.FORGOT_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action)
    case types.FORGOT_PASSWORD_FAIL: return resetPasswordFail(state, action)
    default:
      return state
  }
}

export default reducer;