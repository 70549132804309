import {
    GET_STOCK_HISTORY, GET_STOCK_HISTORY_DETAIL
} from '../actions/types'
import {updateObject} from "../shared/utility";
import * as actionTypes from "../actions/types";

const initialState = {
    stockHistory : null,
    detailStockHistory: null,
    stockHistories: {},
    loadingV2: {},
    errorV2: {},
    loading: true,
    loadingDetailStock: true,
    error: {},
    counting : 0
}

const fetchStockHistoriesStart = (state, action) => {
    return updateObject(state, { error: {}, loadingV2: true })
}

const fetchStockHistoriesSuccess = (state, action) => {
    return updateObject(state, {
        stockHistories: action.stockHistories,
        loadingV2: false
    })
}

const fetchStockHistoriesFail = (state, action) => {
    return updateObject(state, { error: action.error, loadingV2: false })
}

const downloadStockHistoriesStart = (state, action) => {
    return updateObject(state, { errorV2: {}, loadingV2: true })
}

const downloadStockHistoriesSuccess = (state, action) => {
    return updateObject(state, {
        // purchaseOrdersV2: action.purchase_orders,
        loadingV2: false
    })
}

const downloadStockHistoriesFail = (state, action) => {
    return updateObject(state, { errorV2: action.error, loadingV2: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_STOCK_HISTORY:
            return {
                ...state,
                stockHistory: payload,
                loading: false
            }
        case GET_STOCK_HISTORY_DETAIL:
            return {
                ...state,
                detailStockHistory: payload,
                loadingDetailStock: false
            }
        case actionTypes.FETCH_STOCK_HISTORIES_START: return fetchStockHistoriesStart(state, action)
        case actionTypes.FETCH_STOCK_HISTORIES_SUCCESS: return fetchStockHistoriesSuccess(state, action)
        case actionTypes.FETCH_STOCK_HISTORIES_FAIL: return fetchStockHistoriesFail(state, action)
        case actionTypes.DOWNLOAD_STOCK_HISTORIES_START: return downloadStockHistoriesStart(state, action)
        case actionTypes.DOWNLOAD_STOCK_HISTORIES_SUCCESS: return downloadStockHistoriesSuccess(state, action)
        case actionTypes.DOWNLOAD_STOCK_HISTORIES_FAIL: return downloadStockHistoriesFail(state, action)
        default:
            return state
    }
}

export default reducer;