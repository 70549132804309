import React, {Fragment, useState} from "react";
import {
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Select,
  Typography,
  InputLabel,
  IconButton, InputBase
} from '@material-ui/core';
import ListMember from "./ListMember";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  row: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  searchRoot: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  }
}));

const Member = props => {
  const classes = useStyles();

  const [formSearch, setFormSearch] = useState({
    status: '',
    search_value: '',
    search_by: 'name'
  });

  const handleSearch = event => {
    const target = event.target.name;
    event.persist();
    setFormSearch((formSearch) => ({
      ...formSearch,
      [target]: event.target.value
    }));
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.row}>
          <Grid
            container
            spacing={2}
          >
            <Grid item>
              <Typography variant="h5"><b>Member</b></Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.row}>
          <Paper className={classes.root}>
            <div className={classes.row}>
              <Grid container spacing={2} justify={"space-between"}>
                <Grid item xl={3} md={3} sm={12} xs={12}>
                  <FormControl className={classes.formControl} fullWidth>
                    {/*<InputLabel id="demo-simple-select-label">Status Member</InputLabel>*/}
                    <InputLabel shrink htmlFor="age-native-label-placeholder">Status Member</InputLabel>
                    <Select
                      fullWidth
                      native
                      defaultValue={formSearch.status}
                      onChange={handleSearch}
                      label="Status Member"
                      inputProps={{
                        name: 'status',
                        id: 'age-native-label-placeholder'
                      }}
                      name="status"
                      // onChange={handleChange}
                    >
                      <option value={''}>Semua</option>
                      <option value={'AOG'}>AOG</option>
                      <option value={'MOG'}>MOG</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <Grid container spacing={2} justify={"space-between"}>
                    <Grid item xl={6} md={6} sm={12} xs={12}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-label">Cari Berdasrkan</InputLabel>
                        <Select
                          fullWidth
                          native
                          defaultValue={formSearch.search_by}
                          onChange={handleSearch}
                          label="Cari Berdasarkan"
                          inputProps={{
                            name: 'search_by',
                            id: 'outlined-age-native-simple'
                          }}
                          name="search_by"
                        >
                          <option value={'name'}>Nama</option>
                          <option value={'id_member'}>ID</option>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xl={6} md={6} sm={6} xs={6}>
                      <div className={classes.row}>
                        <Paper component="form" className={classes.searchRoot}>
                          <InputBase
                            className={classes.input}
                            name="search_value"
                            placeholder="Cari Member"
                            inputProps={{ 'aria-label': 'Cari Member' }}
                            onChange={handleSearch}
                            value={formSearch.search_value}
                          />
                          {/*<Divider className={classes.divider} orientation="vertical" />*/}
                          <IconButton type="button" className={classes.iconButton} aria-label="search" disabled>
                            <SearchIcon />
                          </IconButton>

                        </Paper>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>

        <div className={classes.row}>
          <Paper className={classes.root}>
            <div className={classes.row}>
              <Grid container spacing={2}>
                <Grid item xl={12} md={12} sm={12} xs={12}>

                  <Typography variant={"h6"}>
                    <b>Daftar Member</b>
                  </Typography>

                </Grid>
              </Grid>
              <br/>
              <Grid container spacing={2}>
                <Grid item xl={12} md={12} sm={12} xs={12}>
                  <ListMember formSearch={formSearch}
                    // rowsPerPage={rowsPerPage}
                    // handleChangePage={handleChangePage}
                    // handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // page={page}
                    // transactions={transactions}
                    // loadingSearchTransaction={loadingSearchTransaction}
                  />

                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </div>
    </Fragment>
  );
};

export default (Member);