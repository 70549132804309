import React,{Fragment,useState,useEffect} from "react";
import {TableCell, TableRow, IconButton, Tooltip, Chip} from "@material-ui/core";
import {Search as DetailIcon} from "@material-ui/icons";
import moment from "moment";
import NumberFormat from "react-number-format";
import {formatRupiah} from  '../../shared/utility' 
import StatusPurchase from '../StatusPurchase/StatusPurchase'
// const ITEM_HEIGHT = 48;

const DataPurchaseOrder = props => {
  const {purchaseOrder, index,classes} = props;
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  //
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  //
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  
  var dtProfile = JSON.parse(sessionStorage.getItem('data'));
  const [dataProfile, setDataProfile] = useState( dtProfile );
  const [dataCabang, setDataCabang] = useState( dtProfile.branch);

  const menunggu_konfirmasi = <Chip label="Menunggu Konfirmasi" className={classes.chipWarning} size={"medium"}/>;
  const sampai = <Chip label="Sampai" className={classes.chipSuccess} size={"medium"}/>;
  const dikirim = <Chip label="Dikirim" className={classes.chipPrimary} size={"medium"}/>;
  const pending = <Chip label="Pending" className={classes.chipWarning} size={"medium"}/>;
  const diproses = <Chip label="Diproses" className={classes.chipSecondary} size={"medium"}/>;
  const ditolak = <Chip label="Ditolak" className={classes.chipError} size={"medium"}/>;
  const expired = <Chip label="Expired" size={"medium"}/>;
  const new_invoice = <Chip label="New Invoice" className={classes.chipWarning} size={"medium"}/>;

  useEffect(() => {
 
  },[]);


  return (
    <Fragment>
      <TableRow key={purchaseOrder.id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          {purchaseOrder.invoice_id}
        </TableCell>
        <TableCell>
          {moment(purchaseOrder.created_at).format('dddd, DD MMMM YYYY')}
        </TableCell>
       
        <TableCell>
          { (dataCabang?dataCabang.name:'-')}
        </TableCell>
        <TableCell>
          {purchaseOrder.register_type && purchaseOrder.register_type}
        </TableCell>
        <TableCell>
          {formatRupiah(purchaseOrder.payment)}
        </TableCell>
       
        <TableCell>
          <StatusPurchase 
            classes={classes}
            status_po = {purchaseOrder.status_str}
          />
          {purchaseOrder.status_po_str}
          {/*{purchaseOrder.status_name}*/}
        </TableCell>
        <TableCell>
          <Tooltip title="Detail Purchase Order">
            {/*<RouterLink to={`/report/selling/detail/${trx.id}`}>*/}
            <IconButton aria-label="detail" onClick={() => props.detail(purchaseOrder.invoice_id)}>
              <DetailIcon />
            </IconButton>
            {/*</RouterLink>*/}
          </Tooltip>
          {/*<Tooltip title="More Actions">*/}
          {/*  /!*<RouterLink to={`/report/selling/detail/${trx.id}`}>*!/*/}
          {/*  <IconButton*/}
          {/*    aria-label="more"*/}
          {/*    aria-controls="long-menu"*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={handleClick}*/}
          {/*  >*/}
          {/*    <MoreVert />*/}
          {/*  </IconButton>*/}
          {/*  /!*</RouterLink>*!/*/}
          {/*</Tooltip>*/}

          {/*<Menu*/}
          {/*  id="long-menu"*/}
          {/*  anchorEl={anchorEl}*/}
          {/*  keepMounted*/}
          {/*  open={open}*/}
          {/*  onClose={handleClose}*/}
          {/*  PaperProps={{*/}
          {/*    style: {*/}
          {/*      maxHeight: ITEM_HEIGHT * 4.5,*/}
          {/*      width: '20ch',*/}
          {/*    },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <MenuItem onClick={handleClose}>*/}
          {/*    Edit*/}
          {/*  </MenuItem>*/}
          {/*  <MenuItem onClick={handleClose}>*/}
          {/*    Delete*/}
          {/*  </MenuItem>*/}
          {/*  /!*{options.map((option) => (*!/*/}
          {/*  /!*  <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>*!/*/}
          {/*  /!*    {option}*!/*/}
          {/*  /!*  </MenuItem>*!/*/}
          {/*  /!*))}*!/*/}
          {/*</Menu>*/}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default DataPurchaseOrder;