import { getApi, getBlobApi, putApi } from '../shared/api';

export const getListPOAOG = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/aog/po/list`;

  return getApi(endpoint, params).then((response) => {
    return response;
  });
};

export const getDetailPOAOG = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/aog/po/detail`;

  return getApi(endpoint, params).then((response) => {
    return response;
  });
};

export const changeStatusPOAOG = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/aog/po/statuspo`;

  return putApi(endpoint, params).then((response) => {
    return response;
  });
};

export const downloadApprovalPoAOG = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/report/ondemand/branch-sales`;

  return getBlobApi(endpoint, params).then((response) => {
    return response;
  });
};
