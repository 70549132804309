import React,{Fragment} from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Paper, Grid, Button
} from "@material-ui/core";
import {isEmpty} from "../../shared/utility";
import {TablePaginationActions} from "../UI";
// import PurchaseOrderData from "./PurchaseOrderData";

import {useHistory} from 'react-router-dom';
import DataPurchaseOrder from "../DataPurchaseOrder/DataPurchaseOrder";
import Typography from "@material-ui/core/Typography";

const columns = [
  { id: 'no', label: 'No', minWidth: 40 },
  { id: 'no_invoice', label: 'No Invoice', minWidth: 100 },
  { id: 'no_id', label: 'Tanggal', minWidth: 100 },
  
  { id: 'cabang', label: 'EGS Pembina', minWidth: 100 },
  { id: 'type', label: 'Jenis Pendaftaran', minWidth: 100 },
 // { id: 'total_item', label: 'Total Item', minWidth: 100 },
  // { id: 'total_rp_invoice', label: 'Total Rp. Invoice', minWidth: 100 },
  { id: 'total_rp_produk', label: 'Total Rp. Produk', minWidth: 100 },
  { id: 'status', label: 'Status', minWidth: 100 },
  { id: 'action', label: 'Action', minWidth: 100 },
];

const ListPurchaseOrder = props => {
  const {
    purchaseOrders,
    classes,
    propsHandleChangePage,
    downloadClick,
    downloadOldClick
  } = props;

  const history = useHistory();

  let tableBody = '';

  const handleChangePage = (event, newPage) => {
    propsHandleChangePage(newPage)
  }

  if(!isEmpty(purchaseOrders)){
    const page = purchaseOrders.current_page - 1;
    const rowsPerPage = 10;
    const countRows = purchaseOrders.total
    // console.log(page, rowsPerPage, countRows, emptyRows);
    //
    tableBody = (
      <Fragment>
        <TableBody>
          {purchaseOrders.data.map((purchaseOrder, index) => (
            <DataPurchaseOrder
              purchaseOrder={purchaseOrder}
              key={purchaseOrder.id}
              index={index}
              classes={classes}
              detail={(id) => history.push(`/detail-approval-po/${id}`)}

              // edited={() => props.edit(kurir)}
              // deleted={() => props.hapus(kurir)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              colSpan={11}
              count={countRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Paper className={classes.paperContent}>
        <div className={classes.paperPadding}>
          <Grid container justify={"space-between"}>
            <Grid item>
              <div className={classes.tagMenu}>
                <Typography className={classes.tagMenu} variant={"h6"}>History Purchase Order</Typography>
              </div>
            </Grid>
            <Grid item>
              <Grid container spacing={2} justify={"space-around"}>
                <Grid item>
                  <Button variant={"contained"} size={"medium"} className={classes.btnSuccess} onClick={() => downloadClick()}>
                    Download Format 1
                  </Button>
                </Grid>
                <Grid item>
                  <Button disabled variant={"contained"} size={"medium"} className={classes.buttonWarning} onClick={() => downloadOldClick()}>
                    Download Format 2
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </div>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    // align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                     <TableSortLabel
                        active={props.sortBy === column.id}
                        direction={props.sortOrder}
                        onClick={props.sortByClick}
                      >
                        {column.label}
                      </TableSortLabel>                    
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </Paper>
    </Fragment>
  );
};

export default ListPurchaseOrder