import { getApi, postApi } from '../shared/api';

const list = async (params) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/commission/search`;

  return getApi(endpoint, params).then((response) => {
    return response;
  });
};

const detail = async (id) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/commission/detail/${id}`;

  return getApi(endpoint, {}).then((response) => {
    return response;
  });
};

const uploadProof = async (data) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/commission/upload_bukti_pembayaran`;

  return postApi(endpoint, data).then((response) => {
    return response;
  });
};

const approve = async (data) => {
  const endpoint = `${process.env.REACT_APP_BASE_URL}/user/commission/approve`;

  return postApi(endpoint, data).then((response) => {
    return response;
  });
};

const upgradeService = {
  list,
  detail,
  uploadProof,
  approve,
};
export default upgradeService;
