import {
    PURCHASE_ITEM_PAY,
    PURCHASE_ITEM_PAY_ERROR,
    PURCHASE_ITEM_PAY_BUYBACK,
    PURCHASE_ITEM_PAY_START,
  STORE_PAYMENT_CART_START,
  STORE_PAYMENT_CART_SUCCESS,
  STORE_PAYMENT_CART_FAIL
} from '../actions/types'
import {updateObject} from "../shared/utility";

const initialState = {
    payment: {},
    paymentBuyback: {},
    loading: false,
    error: {},
    counting : 0
}

const storePaymentCartStart = (state, action) => {
    return updateObject(state, { error: {}, loading: true })
}

const storePaymentCartSuccess = (state, action) => {
    return updateObject(state, {
        loading: false
    })
}

const storePaymentCartFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false })
}

const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case PURCHASE_ITEM_PAY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case PURCHASE_ITEM_PAY_START:
            return {
                ...state,
                loading: true
            }
        case PURCHASE_ITEM_PAY:
            return {
                ...state,
                payment: payload,
                loading: false
            }
        case PURCHASE_ITEM_PAY_BUYBACK:
            return {
                ...state,
                paymentBuyback: payload,
                loading: false
            }
        case STORE_PAYMENT_CART_START: return storePaymentCartStart(state, action)
        case STORE_PAYMENT_CART_SUCCESS: return storePaymentCartSuccess(state, action)
        case STORE_PAYMENT_CART_FAIL: return storePaymentCartFail(state, action)
        default:
            return state
    }
}

export default reducer;