import {makeStyles} from "@material-ui/core";

const Main = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  customerRow: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  tagMenu: {
    fontWeight: "bold"
  },
  btnAdd: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: "bold",
    borderRadius: 60,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    }
  },
  btnBack: {
    backgroundColor: '#252c43',
    color: theme.palette.white,
    textTransform: 'None',
    fontWeight: "bold",
    borderRadius: 60,
    '&:hover': {
      backgroundColor: '#252C43',
    }
  },
  btnCancel: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    textTransform: "none",
    fontWeight: "bold",
    '&:hover': {
      borderColor: theme.palette.error.light
    }
  },
  btnSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.success.light
    }
  },
  buttonWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    }
  },
  btnError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
    textTransform: "none",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  paperContent: {
    padding: theme.spacing(4)
  },
  paperRules: {
    padding: theme.spacing(2),
    backgroundColor: '#FFF9F0'
  },
  paperPadding: {
    marginBottom: theme.spacing(2)
  },
  searchRoot: {
    padding: '10px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    // marginTop: theme.spacing(2)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  ruleContent: {
    color: theme.palette.error.main,
    margin: '5px auto'
    // fontSize: '18px'
  },
  chipWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
  },
  chipError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },
  chipSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  chipPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  chipSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
  roundText: {
    marginTop: 3,
    color: theme.palette.warning.main,
    fontWeight: "bold"
  },
  roundTimer: {
    margin:'auto 5px',
    border: '1px solid #F99800',
    background: 'rgba(249, 152, 0, 0.16)',
    borderRadius: '20%'
  },
  rincianTable: {
    padding: '8px 0px',
    borderBottom: 'none'
  },
  textCenter: {
    textAlign: "center"
  },
  rek: {
    marginTop: 5,
    color: theme.palette.black,
    fontWeight: "bold"
  },
  zeroPadding: {
    padding: '0px 6px'
  },
  imageList: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  buttonDelete: {
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(200, 0, 58)'
    },
    textTransform: "none",
    color: '#FFFFFF'
  },
  icon: {
    color: theme.palette.black,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  dispayFlex: {
    display: "flex"
  },
  alignCenter: {
    alignItems: 'center',
    display: 'flex'
  },
  imgMargin: {
    marginRight: theme.spacing(1)
  },
  nameField: {
    color: '#94A2B3'
  }
}));

export default Main;