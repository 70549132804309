import * as actionTypes from '../actions/types';
import { updateObject } from '../shared/utility'

const initialState = {
  beginning_stock: null,
  beginning_stock_detail: null,
  status: 'draft',
  statusCreate: false,
  error: {},
  loading: false,
}

const fetchBeginningStockStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const fetchBeginningStockSuccess = (state, action) => {
  return updateObject(state, {
    beginning_stock: action.beginning_stock,
    statusCreate: action.statusCreate,
    loading: false
  })
}

const fetchBeginningStockFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const storeBeginningStockStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const storeBeginningStockSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  })
}

const storeBeginningStockFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const getBeginningStockStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const getBeginningStockSuccess = (state, action) => {
  return updateObject(state, {
    beginning_stock_detail: action.beginning_stock_detail,
    status: action.status,
    loading: false
  })
}

const getBeginningStockFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const updateBeginningStockStart = (state, action) => {
  return updateObject(state, { error: {}, loading: true })
}

const updateBeginningStockSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  })
}

const updateBeginningStockFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BEGINNING_STOCK_START: return fetchBeginningStockStart(state, action)
    case actionTypes.FETCH_BEGINNING_STOCK_SUCCESS: return fetchBeginningStockSuccess(state, action)
    case actionTypes.FETCH_BEGINNING_STOCK_FAIL: return fetchBeginningStockFail(state, action)
    case actionTypes.STORE_BEGINNING_STOCK_START: return storeBeginningStockStart(state, action)
    case actionTypes.STORE_BEGINNING_STOCK_SUCCESS: return storeBeginningStockSuccess(state, action)
    case actionTypes.STORE_BEGINNING_STOCK_FAIL: return storeBeginningStockFail(state, action)
    case actionTypes.GET_BEGINNING_STOCK_START: return getBeginningStockStart(state, action)
    case actionTypes.GET_BEGINNING_STOCK_SUCCESS: return getBeginningStockSuccess(state, action)
    case actionTypes.GET_BEGINNING_STOCK_FAIL: return getBeginningStockFail(state, action)
    case actionTypes.UPDATE_BEGINNING_STOCK_START: return updateBeginningStockStart(state, action)
    case actionTypes.UPDATE_BEGINNING_STOCK_SUCCESS: return updateBeginningStockSuccess(state, action)
    case actionTypes.UPDATE_BEGINNING_STOCK_FAIL: return updateBeginningStockFail(state, action)

    default: return state
  }
}

export default reducer;