import React, {Fragment} from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
// import {Pie} from "react-chartjs-2";
import {isEmpty} from "../../../../../../shared/utility";
import {Pie} from "react-chartjs-2";

const StockProduct = props => {
  const {
    grafikStock
  } = props;

  let data = {}

  if(!isEmpty(grafikStock)){
    data = {
      labels: [ `On Hand : ${grafikStock.stock_on_hand} `, `On Process : ${grafikStock.stock_on_process_v2}` ],
      datasets: [
        {
          // label : 'Grafik Stock',
          data: [ grafikStock.stock_on_hand, grafikStock.stock_on_process_v2,  ],
          backgroundColor: [
            '#36A2EB',
            '#FF6384',
          ],
          hoverBackgroundColor: [
            '#36A2EB',
            '#FF6384',
          ]
        }
      ]
    };

  }else{
    data = {
      labels: ["loading"],
      datasets: [
        {
          label : 'Grafik Stock',
          data: ["loading"],
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
          ]
        }
      ]
    };
  }

  return (
    <Fragment>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="subtitle1">
                {!isEmpty(grafikStock) && grafikStock.name_product}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                Total : {grafikStock.stock_on_hand + grafikStock.stock_on_process_v2}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Pie
            data={data}
            // options={options}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
};


export default (StockProduct);