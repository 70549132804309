import React, {Fragment, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {isEmpty} from "../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import FormProductData from "../FormProductData/FormProductData";
import NumberFormat from "react-number-format";

const columns = [
  { id: 'no', label: 'No', minWidth: '3%' },
  { id: 'foto_produk', label: 'Foto Produk', minWidth: '16%' },
  { id: 'item_produk', label: 'Item Produk', minWidth: '16%' },
  { id: 'stock', label: 'Stock', minWidth: '16%' },
  { id: 'harga', label: 'Harga', minWidth: '16%' },
  { id: 'kuantitas', label: 'Kuantitas', minWidth: '16%' },
  { id: 'Subtotal', label: 'Subtotal', minWidth: '16%' },
];

const FormProductList = props => {
  const {products, classes} = props;

  const list = [];
  products.map((itemData, index) => (
    list[index] = {
      id_product: itemData.product.id,
      qty: 0,
      sell_price: itemData.product.latest_price ? itemData.product.latest_price.sell_price : 0,
      buy_price: itemData.product.latest_price ? itemData.product.latest_price.buy_price : 0,
      buyback_price: itemData.product.latest_price ? itemData.product.latest_price.buyback_price : 0,
      sub_total: itemData.product.latest_price ? itemData.product.latest_price.sell_price * 0 : 0
    }));

  const [ cartQty, setCartQty ] = useState(list);

  const handleCartQtyChange = (e,product, index) => {
    e.persist();
    let dataCount = e.target.value === '' ? 0 : parseInt(e.target.value);
    const list = [...cartQty]
    if (dataCount === '' || dataCount < 0){
      dataCount = 0;
    }
    if (dataCount >= parseInt(product.product.stock_on_hand)){
      dataCount = product.product.stock_on_hand;
    }
    list[index] = {
      id_product : product.product.id,
      [e.target.name] : dataCount,
      sell_price: product.product.latest_price ? product.product.latest_price.sell_price : 0,
      buy_price: product.product.latest_price ? product.product.latest_price.buy_price : 0,
      buyback_price: product.product.latest_price ? product.product.latest_price.buyback_price : 0,
      sub_total: product.product.latest_price ? product.product.latest_price.sell_price * dataCount : 0
    }
    setCartQty(list)
  }

  let tableBody = '';

  if(!isEmpty(products)){
    tableBody = (
      <Fragment>
        <TableBody>
          {products.map((product, index) => (
            <FormProductData
              product={product}
              key={product.id}
              index={index}
              // register={register}
              handleCartQtyChange={(e) => handleCartQtyChange(e,product,index)}
              cartQty={cartQty}
              // errors={errors}
            />
          ))}
          <TableRow>
            <TableCell colSpan={5}/>
            <TableCell>
              {!isEmpty(cartQty) ? <NumberFormat style={{fontWeight: "bold"}} value={cartQty.map(item => parseInt(item.qty)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} suffix={" pcs"}/> : 0}
            </TableCell>
            <TableCell>
              {!isEmpty(cartQty) ? <NumberFormat style={{fontWeight: "bold"}} value={cartQty.map(item => parseInt(item.sub_total)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/> : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Fragment>
    )
  }

  const sendCartQty = data => {
    props.getCartQty(data)
  }

  return (
    <Fragment>
      {sendCartQty(cartQty)}
      <div className={classes.row}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

export default FormProductList;