import * as actionTypes from './types';
import axios from '../axios-orders';
import { setAlert } from './alert'

export const fetchSwapGramationsStart = () => {
  return {
    type: actionTypes.FETCH_SWAP_GRAMATIONS_START
  }
}

export const fetchSwapGramationsSuccess = (swapHistories) => {
  return {
    type: actionTypes.FETCH_SWAP_GRAMATIONS_SUCCESS,
    swapHistories: swapHistories,
  }
}

export const fetchSwapGramationsFail = (error) => {
  return {
    type: actionTypes.FETCH_SWAP_GRAMATIONS_FAIL,
    error: error
  }
}

export const fetchSwapGramations = (page,formSearch) => {
  let param = `&start=${formSearch.start_date}&end=${formSearch.end_date}`;
  return dispatch => {
    dispatch(fetchSwapGramationsStart())
    axios.get(`user/gram_exchange?page=${page}&page_size=10${param}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(fetchSwapGramationsSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchSwapGramationsFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const storeSwapGramationStart = () => {
  return {
    type: actionTypes.STORE_SWAP_GRAMATION_START
  }
}

export const storeSwapGramationSuccess = () => {
  return {
    type: actionTypes.STORE_SWAP_GRAMATION_SUCCESS,
  }
}

export const storeSwapGramationFail = (error) => {
  return {
    type: actionTypes.STORE_SWAP_GRAMATION_FAIL,
    error: error
  }
}

export const storeSwapGramation = (storeData,history) => {
  return dispatch => {
    dispatch(storeSwapGramationStart())
    axios.post(`user/gram_exchange`, storeData,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(storeSwapGramationSuccess())
        dispatch(setAlert(res.data.message, "success"))
        history.push(`/swap-gramation`)
      })
      .catch(err => {
        dispatch(storeSwapGramationFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}

export const getSwapGramationStart = () => {
  return {
    type: actionTypes.GET_SWAP_GRAMATION_START
  }
}

export const getSwapGramationSuccess = (swapHistory) => {
  return {
    type: actionTypes.GET_SWAP_GRAMATION_SUCCESS,
    swapHistory: swapHistory
  }
}

export const getSwapGramationFail = (error) => {
  return {
    type: actionTypes.GET_SWAP_GRAMATION_FAIL,
    error: error
  }
}

export const getSwapGramation = (id) => {
  return dispatch => {
    dispatch(getSwapGramationStart())
    axios.get(`user/gram_exchange/${id}`,{
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      }
    })
      .then(res => {
        dispatch(getSwapGramationSuccess(res.data.data))
      })
      .catch(err => {
        dispatch(getSwapGramationFail(err.response.data.message))
        dispatch(setAlert(err.response.data.message, "error"))
      })
  }
}