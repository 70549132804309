import fileDownload from 'js-file-download'
import axios from 'axios'
import { setAlert } from './alert'
import { 
    GET_TRANSACTION_BUYBACK, 
    GET_SEARCH_TRANSACTION_BUYBACK, 
    GET_DETAIL_TRANSACTION_BUYBACK,
    DOWNLOAD_REPORT_TRANSACTION_BUYBACK_START,
    DOWNLOAD_REPORT_TRANSACTION_BUYBACK_SUCCESS, 
    DOWNLOAD_REPORT_TRANSACTION_BUYBACK_FAILED,
    GET_DETAIL_TRANSACTION_BUYBACK_START,
    GET_SEARCH_TRANSACTION_BUYBACK_START
} from './types'
import * as actionTypes from "./types";
import axiosOrders from "../axios-orders";

export const getTransactionBuyback = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction_buyback`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_TRANSACTION_BUYBACK,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getTransactionSearchBuyback = (startDate, endDate) => async dispatch => {
    dispatch({
        type: GET_SEARCH_TRANSACTION_BUYBACK_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction_buyback/search?tanggal=${startDate}/${endDate}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_SEARCH_TRANSACTION_BUYBACK,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_SEARCH_TRANSACTION_BUYBACK,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getDetailTransactionBuyback = (id_trx) => async dispatch => {
    dispatch({
        type: GET_DETAIL_TRANSACTION_BUYBACK_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction_buyback/${id_trx}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_DETAIL_TRANSACTION_BUYBACK,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const downloadReportTransactionBuyback = (id_trx) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/transaction_buyback/${id_trx}/invoice`
    const token = sessionStorage.getItem('access_token')

    dispatch({
        type: DOWNLOAD_REPORT_TRANSACTION_BUYBACK_START,
    })
    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            },
            responseType: 'blob'
        });

        fileDownload(res.data, `INV${id_trx}.pdf`)
        dispatch({
            type: DOWNLOAD_REPORT_TRANSACTION_BUYBACK_SUCCESS,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        dispatch({
            type: DOWNLOAD_REPORT_TRANSACTION_BUYBACK_FAILED,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const fetchTransactionsBuybackStart = () => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_BUYBACK_START
    }
}

export const fetchTransactionsBuybackSuccess = (transactions) => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_BUYBACK_SUCCESS,
        transactions:transactions
    }
}

export const fetchTransactionsBuybackFail = (error) => {
    return {
        type: actionTypes.FETCH_TRANSACTIONS_BUYBACK_FAIL,
        error: error
    }
}

export const fetchTransactionsBuyback = (page, formSearch) => {
    let param = `&tanggal=${formSearch.startDate}/${formSearch.endDate}&product_type=${formSearch.product_type?formSearch.product_type:''}&customer_name=${formSearch.customer_name?formSearch.customer_name:''}`;
    return dispatch => {
        dispatch(fetchTransactionsBuybackStart())
        axiosOrders.get(`user/transaction_buyback/search?page=${page}`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchTransactionsBuybackSuccess(res.data))
          })
          .catch(err => {
              dispatch(fetchTransactionsBuybackFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const downloadTransactionsBuybackStart = () => {
    return {
        type: actionTypes.DOWNLOAD_TRANSACTIONS_BUYBACK_START
    }
}

export const downloadTransactionsBuybackSuccess = () => {
    return {
        type: actionTypes.DOWNLOAD_TRANSACTIONS_BUYBACK_SUCCESS
    }
}

export const downloadTransactionsBuybackFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_TRANSACTIONS_BUYBACK_FAIL,
        error: error
    }
}

export const downloadTransactionsBuyback = (formSearch) => {
    let param = `&tanggal=${formSearch.startDate}/${formSearch.endDate}&product_type=${formSearch.product_type?formSearch.product_type:''}&customer_name=${formSearch.customer_name?formSearch.customer_name:''}`;
    return dispatch => {
        dispatch(downloadTransactionsBuybackStart())
        axiosOrders.get(`user/transaction_buyback/export?`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            responseType: 'blob',
        })
          .then(res => {
              fileDownload(res.data, `download-laporan.xlsx`)
              dispatch(downloadTransactionsBuybackSuccess(res.data))
              dispatch(setAlert('Download Finished', "success"))
          })
          .catch(err => {
              dispatch(downloadTransactionsBuybackFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}