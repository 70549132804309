import React, {Fragment} from "react";
import Countdown from 'react-countdown';
import {Box, Grid} from "@material-ui/core";
import {Redirect} from 'react-router';
import * as actions from '../../../../../actions';
import {connect} from "react-redux";
import moment from "moment";
import {isEmpty} from "../../../../../shared/utility";

const CountDownPO = props => {
  const {classes,onAlert,countDownTime,round} = props;
  // console.log(moment(countDownTime).format());
  console.log(round);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Fragment>
          {onAlert('Batas Pembayaran Telah Berakhir','error')}
          <Redirect to={'/purchase-order-v2'}/>
        </Fragment>
      );
    } else {
      // Render a countdown
      return (
        <Fragment>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent={"flex-start"}>
            <Box style={{marginRight:15}}>
              Hitung mundur batas pembayaran
            </Box>

            <Box p={1} className={classes.roundTimer}>
              {hours.toLocaleString('id', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </Box>
            :
            <Box p={1} className={classes.roundTimer}>
              {minutes.toLocaleString('id', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </Box>
            :
            <Box p={1} className={classes.roundTimer}>
              {seconds.toLocaleString('id', {
                minimumIntegerDigits: 2,
                useGrouping: false
              })}
            </Box>
          </Box>
        </Fragment>
      );
    }
  };

  let day = moment(countDownTime).add(1,'h').local().toDate();
  
  if(!isEmpty(round)){
    let roundClose = moment(round.close).toDate();

    if(day > roundClose){
      day = roundClose;
    }
  }

  return (
    <Fragment>
      {/*<Paper className={classes.paperContent}>*/}
        <Grid container spacing={2} justify={"space-between"}>
          {/*<Grid item>*/}
          {/*  <Typography variant={"h5"} >*/}
          {/*    {round.name}*/}
          {/*  </Typography>*/}
          {/*  <Typography variant={"h6"} className={classes.roundText}>*/}
          {/*    Pukul {round.open} s.d {round.close} WIB*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <Grid item>
            <Countdown
              date={day}
              renderer={renderer}
            />
          </Grid>
        </Grid>
      {/*</Paper>*/}

    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onAlert: (msg, alertType) => dispatch(actions.setAlert(msg,alertType))
  }
}

export default connect(null, mapDispatchToProps)(CountDownPO);