import React, { useState, useEffect } from 'react'
import 'date-fns'
import { makeStyles } from '@material-ui/styles'
import {
    Typography,
    Grid,
    Paper, FormControl, InputLabel, Select, InputBase, IconButton, Card, CardContent, Button,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';

// Components
import ListTransaction from './ListTransaction'

// Redux
import { connect } from 'react-redux'

import * as actions from "../../actions";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {CalendarToday, Search as SearchIcon} from "@material-ui/icons";
import {isEmpty} from "../../shared/utility";
import NumberFormat from "react-number-format";
import {Loading} from "../../components/UI";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    row: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    btnAddCustomer: {
        backgroundColor: '#0277BD',
        '&:hover' : {
            backgroundColor: '#2092D6'
        },
        color: '#FFFFFF',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    searchRoot: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        // marginTop: theme.spacing(2)
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
    },
    paperContent: {
        padding: theme.spacing(4)
    },
    paperRules: {
        padding: theme.spacing(2),
        backgroundColor: '#FFF9F0'
    },
    paperPadding: {
        marginBottom: theme.spacing(2)
    },
    buttonWarning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.white,
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: theme.palette.warning.light,
        },
        textTransform: 'none'
    }
}))

const TransactionBuyback = props => {
    const classes = useStyles()
    const {
        onFetchTransactionsBuyback,
        onDownloadTransactionsBuyback,
        transactions,
        loading
    } = props

    const [ startDate, setStartDate ] = useState({
        startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    });
    const handleStartDate = (input) => {
        setStartDate(startDate => ({
            ...startDate,
            startDate: input
        }));
        setFormSearch(() => ({
            ...formSearch,
            startDate: moment(input).format('YYYY-MM-DD')
        }));
    }

    const [ endDate, setEndDate ] = useState({
        endDate: new Date(),
    });

    const handleEndDate = (input) => {
        setEndDate(endDate => ({
            ...endDate,
            endDate: input
        }));
        setFormSearch(() => ({
            ...formSearch,
            endDate: moment(input).format('YYYY-MM-DD')
        }));
    };

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);

    const [formSearch, setFormSearch] = useState({
        product_type: '',
        startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        customer_name: '',
    });

    useEffect(() => {
        const timer = setTimeout(() => {
            onFetchTransactionsBuyback(1, formSearch)
        }, 1000)

        return () => clearTimeout(timer)
    }, [onFetchTransactionsBuyback, formSearch])
    //
    const handleChangePage = (page) => {
        onFetchTransactionsBuyback(page, formSearch)
    }

    const handleSearch = event => {
        const target = event.target.name;
        event.persist();
        setFormSearch((formSearch) => ({
            ...formSearch,
            [target]: event.target.value
        }));
    };

    const download = () => {
        onDownloadTransactionsBuyback(formSearch);
    }

    return(loading ? <Loading/> :
        <div className={classes.root}>
            <div className={classes.row}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item>  
                        <Typography variant="h4">Laporan Buyback Emas</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.row}>
                <Paper style={{padding: '10px'}}>
                    <Grid
                      container
                      spacing={2}
                    >
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TextField
                                  onClick={() => setIsOpen(true)}
                                  value={moment(startDate.startDate).format('dddd DD MMMM YYYY')}
                                  fullWidth
                                  className={classes.margin}
                                  label="Tanggal Awal"
                                  InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" disablePointerEvents>
                                            <CalendarToday />
                                        </InputAdornment>
                                      ),
                                  }}
                                />
                                <DatePicker
                                  fullWidth
                                  open={isOpen}
                                  onOpen={() => setIsOpen(true)}
                                  onClose={() => setIsOpen(false)}
                                  disableFuture
                                  // minDate={minDate}
                                  variant="outlined"
                                  name="start_date"
                                  format="dd MMMM yyyy HH:mm"
                                  value={startDate.startDate}
                                  onChange={e => handleStartDate(e, 'startDate')}
                                  TextFieldComponent={() => null}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TextField
                                  onClick={() => setIsOpenEnd(true)}
                                  value={moment(endDate.endDate).format('dddd DD MMMM YYYY')}
                                  fullWidth
                                  className={classes.margin}
                                  label="Tanggal Akhir"
                                  InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" disablePointerEvents>
                                            <CalendarToday />
                                        </InputAdornment>
                                      ),
                                  }}
                                />
                                <DatePicker
                                  fullWidth
                                  open={isOpenEnd}
                                  onOpen={() => setIsOpenEnd(true)}
                                  onClose={() => setIsOpenEnd(false)}
                                  disableFuture
                                  // minDate={minDate}
                                  variant="outlined"
                                  name="end_date"
                                  format="dd MMMM yyyy HH:mm"
                                  value={endDate.endDate}
                                  onChange={e => handleEndDate(e, 'endDate')}
                                  TextFieldComponent={() => null}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xl={3} md={3} sm={12} xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel shrink htmlFor="age-native-label-placeholder">Tipe Produk</InputLabel>

                                <Select
                                  fullWidth
                                  native
                                  defaultValue={formSearch.product_type}
                                  onChange={handleSearch}
                                  label="Tipe Produk"
                                  inputProps={{
                                      name: 'product_type',
                                      id: 'age-native-label-placeholder'
                                  }}
                                  name="product_type"
                                  // onChange={handleChange}
                                >
                                    <option value={''}>Semua</option>
                                    <option value={'gold'}>Gold</option>
                                    <option value={'other'}>Other</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </div>

            <div className={classes.row}>
                <Grid container justify={"flex-end"} spacing={2}>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <Paper component="form" className={classes.searchRoot}>
                            <InputBase
                              className={classes.input}
                              placeholder="Cari Nama Customer"
                              inputProps={{ 'aria-label': 'Cari Nama Customer' }}
                              defaultValue={formSearch.customer_name}
                              name={'customer_name'}
                              onChange={handleSearch}
                            />
                            <IconButton className={classes.iconButton} aria-label="search" disabled>
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.row}>
                <Grid
                  container
                  spacing={2}
                >
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                    >
                        <Card>
                            <CardContent>
                                <div>
                                    Jumlah Invoice
                                </div>
                                <div>
                                    {!isEmpty(transactions.data) && <NumberFormat value={transactions.data.total} displayType={'text'} thousandSeparator={true} />}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                    >
                        <Card>
                            <CardContent>
                                <div>
                                    Total Item
                                </div>
                                <div>
                                    {!isEmpty(transactions.total) && <NumberFormat value={transactions.total.total_item} displayType={'text'} thousandSeparator={true} />}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                    >
                        <Card>
                            <CardContent>
                                <div>
                                    Total Buyback
                                </div>
                                <div>
                                    {!isEmpty(transactions.total) && <NumberFormat value={(transactions.total.total_price!==null)?transactions.total.total_price:0} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />}
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.row}>
                <div className={classes.row}>
                    <Paper className={classes.paperContent}>

                        <div className={classes.paperPadding}>
                            <Grid container justify={"space-between"}>
                                <Grid item>
                                    <Typography className={classes.tagMenu} variant={"h5"}>History Buyback Emas</Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                      variant={"contained"}
                                      size={"medium"}
                                      className={classes.buttonWarning}
                                      onClick={() => download()}
                                    >
                                        Download
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        {!isEmpty(transactions) && (
                            <ListTransaction
                                transactions={transactions}
                                handleChangePageProps={(page) => handleChangePage(page)}
                            />
                        )}
                    </Paper>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        transactions: state.transactionBuyback.transactionsV2,
        loading: state.transactionBuyback.loadingV2,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchTransactionsBuyback: (page, formSearch) => dispatch(actions.fetchTransactionsBuyback(page, formSearch)),
        onDownloadTransactionsBuyback: (formSearch) => dispatch(actions.downloadTransactionsBuyback(formSearch)),
    }
}

export default connect(mapStateToProps,  mapDispatchToProps)(TransactionBuyback)