import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { 
  Grid, 
  Typography,
	Paper,
	InputBase,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TablePagination,
	IconButton,
	Divider,
	TextField,
	MenuItem,
	Button
} from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Skeleton from '@material-ui/lab/Skeleton'
import SearchIcon from '@material-ui/icons/Search'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
// Redux
import { connect } from 'react-redux'
import { getSearchCustomerAndClear, getCustomerCashier } from '../../../actions/customer'
import { useEffect } from 'react'

import {useForm} from "react-hook-form";
import moment from "moment";
import 'moment/locale/id'
import {Edit} from "@material-ui/icons";

moment.locale('in');

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	bgColor: {
		backgroundColor: '#BCE0FD',
		height: '312px',
		position: 'absolute',
		// zIndex: 0
	},
	cardMobile: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(2),
		width: '100%'
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
		},
	row: {
		height: 'auto',
		// display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(1),
		width: '100%'
	},
  	catSearch: {
		borderRadius: '4px',
		alignItems: 'center',
		padding: theme.spacing(1),
		display: 'flex',
		flexBasis: 420,
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		width: '100%'
  	},
	catSelectSearch: {
		width: '100%',
		minWidth: 150
	},
	searchRoot: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 'auto',
		// marginTop: theme.spacing(2)
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
			padding: 10,
	},
	divider: {
			height: 28,
			margin: 4,
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(4),
		right: theme.spacing(2),
	},
	btn: {
		backgroundColor: '#FF9300',
		color: '#FFFFFF',
		'&:hover': {
		  backgroundColor: '#FFA938',
		  opacity: 1,
		},
	},
}));

const useStyles1 = makeStyles((theme) => ({
	root: {
	  flexShrink: 0,
	  marginLeft: theme.spacing(2.5),
	},
	
}));

const columns = [
	{ id: 'no_id', label: 'No ID', minWidth: 100 },
	{ id: 'nama', label: 'Nama', minWidth: 150 },
	{ id: 'kategori', label: 'Kategori', minWidth: 100 },	
	{ id: 'action', label: 'Aksi', minWidth: 100 },	
  ];

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;
  
	const handleFirstPageButtonClick = (event) => {
	  onChangePage(event, 0);
	};
  
	const handleBackButtonClick = (event) => {
	  onChangePage(event, page - 1);
	};
  
	const handleNextButtonClick = (event) => {
	  onChangePage(event, page + 1);
	};
  
	const handleLastPageButtonClick = (event) => {
	  onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};
  
	return (
	  <div className={classes.root}>
		<IconButton
		  onClick={handleFirstPageButtonClick}
		  disabled={page === 0}
		  aria-label="first page"
		>
		  {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
		</IconButton>
		<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
		  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
		</IconButton>
		<IconButton
		  onClick={handleNextButtonClick}
		  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
		  aria-label="next page"
		>
		  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
		</IconButton>
		<IconButton
		  onClick={handleLastPageButtonClick}
		  disabled={page >= Math.ceil(count / rowsPerPage) - 1}
		  aria-label="last page"
		>
		  {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
		</IconButton>
	  </div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

const SearchCustomer = (props) => {
	const { 
		getSearchCustomerAndClear, 
		getCustomerCashier, 
		searchCustomerClear, 
		customer : { customers_v2, loadingCustomerV2 },
		startDate,
		handleStartDate,
		valueSearch,
		setValueSearch,
		minDate
	 } = props
	const classes = useStyles();
	const { register, handleSubmit } = useForm();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [ keyword, setKeyword ] = useState({
		values: {
			keyword: '',
			type: 'name'
		}
	})

	const [isOpen, setIsOpen] = useState(false);
	
	const handleChangeSearch = event => {
		event.persist();

		setKeyword(keyword => ({
			...keyword,
			values: {
			  ...keyword.values,
			  [event.target.name]: event.target.value
			}
		}))
		setPage(0)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0)
	};

	// Dialog
	const [open, setOpen] = useState(false);
  	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	// End Dialog

	
	const onSubmit = data => {
		// e.preventDefault()
        setValueSearch({
			type: keyword.values.type,
			name: keyword.values.keyword
		})
	}

	useEffect(() => {
		getCustomerCashier(valueSearch.type, valueSearch.name, rowsPerPage, page+1)
	}, [ getCustomerCashier, valueSearch, page, rowsPerPage])

	const handleSelectChange = event => {
		getSearchCustomerAndClear('id', event.id)
		setOpen(false)
	};

  	return (
		<div className={classes.root}>
			<div className={classes.bgColor}></div>
			<div className={classes.row}>
				<Grid container spacing={2}>
					<Grid item lg={12} md={12} xs={12} sm={12}>
						<Grid container spacing={2}>
									<Grid item lg={7} md={7} xs={12} sm={12}>
										<Grid container spacing={2}>
											<Grid item lg={12} md={12} xs={12} sm={12}>
												<Typography>Tanggal & Waktu Transaksi</Typography>
												<div className={classes.row}>
													<Grid container spacing={2}>
														<Grid lg={11} md={11} sm={11} xs={11}>
																<Typography variant={"h2"} style={{fontWeight: "bold", padding:"5px 0px 0px 8px"}}>
																	{moment(startDate.view.view).format('dddd DD MMMM YYYY, HH:mm:ss')}
																</Typography>
														</Grid>
														<Grid lg={1} md={1} sm={1} xs={1}>
															<MuiPickersUtilsProvider utils={DateFnsUtils}>
																{/*<Button > Open picker </Button>*/}
																<IconButton type="button" className={classes.iconButton} aria-label="search" onClick={() => setIsOpen(true)}>
																	<Edit style={{color: theme.palette.success.main}}/>
																</IconButton>
																<DateTimePicker
																	fullWidth
																	open={isOpen}
																	onOpen={() => setIsOpen(true)}
																	onClose={() => setIsOpen(false)}
																	disableFuture
																	minDate={minDate}
																	ampm={false}
																	variant="outlined"
																	name="start_date"
																	format="dd MMMM yyyy HH:mm"
																	value={startDate.view.view}
																	onChange={handleStartDate}
																	TextFieldComponent={() => null}
																/>
															</MuiPickersUtilsProvider>
														</Grid>
													</Grid>
													{/*<Paper component="form" className={classes.searchRoot}>*/}

														{/*<Divider className={classes.divider} orientation="vertical" />*/}

													{/*</Paper>*/}
												</div>
											</Grid>
											<Grid item lg={12} md={12} xs={12} sm={12}>
												{/*<Typography>Cari Customer</Typography>*/}
												<div className={classes.row}>
													<Paper component="form" className={classes.searchRoot}>
														<IconButton type="button" className={classes.iconButton} aria-label="search">
															<SearchIcon />
														</IconButton>
														<Divider className={classes.divider} orientation="vertical" />
														<InputBase
															className={classes.input}
															name="nama"
															value={keyword.values.keyword || ''}
															onClick={handleClickOpen}
															placeholder="Cari Customer"
															inputProps={{ 'aria-label': 'Cari Customer' }}
														/>

													</Paper>
												</div>
											</Grid>
										</Grid>
									</Grid>
									{searchCustomerClear !== null && (
										<>
											{searchCustomerClear.length > 0 && (
												<>
													{searchCustomerClear.map((item) => (
														<>
															<Grid item lg={5} md={5} xs={12} sm={12}>
																<div className={classes.root}>
																	<Typography style={{marginBottom: '8px'}}> Data Customer</Typography>
																	<Grid container spacing={2} style={{marginBottom: '8px'}}>
																		<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
																			<Grid container justify={"space-between"}>
																				<Grid item>
																					<Typography>Nama</Typography>
																				</Grid>
																				<Grid item>
																					<Typography>:</Typography>
																				</Grid>
																			</Grid>
																		</Grid>
																		<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
																			{item.name}
																		</Grid>
																	</Grid>
																	<Grid container spacing={2} style={{marginBottom: '8px'}}>
																		<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
																			<Grid container justify={"space-between"}>
																				<Grid item>
																					<Typography>Tipe Customer</Typography>
																				</Grid>
																				<Grid item>
																					<Typography>:</Typography>
																				</Grid>
																			</Grid>
																		</Grid>
																		<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
																			{item.name_status}
																		</Grid>
																	</Grid>
																	<Grid container spacing={2} style={{marginBottom: '8px'}}>
																		<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
																			<Grid container justify={"space-between"}>
																				<Grid item>
																					<Typography>No ID</Typography>
																				</Grid>
																				<Grid item>
																					<Typography>:</Typography>
																				</Grid>
																			</Grid>
																		</Grid>
																		<Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
																			{item.id_agent}
																		</Grid>
																	</Grid>
																</div>
															</Grid>
														</>
														)
													)}
												</>
											)}
										</>
									)}





						</Grid>
					</Grid>
				</Grid>

			</div>

			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">{"Pencarian Customer"}</DialogTitle>
				<DialogContent>
				<DialogContentText>
					<Grid
						container
						spacing={2}
					>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<Typography>Cari Customer</Typography>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className={classes.row}>
									<Paper component="form" className={classes.searchRoot}>
										<IconButton type="submit" className={classes.iconButton} aria-label="search">
											<SearchIcon />
										</IconButton>
										<Divider className={classes.divider} orientation="vertical" />
										<InputBase
											autoFocus
											className={classes.input}
											name="keyword"
											value={keyword.values.keyword || ''}
											onChange={handleChangeSearch}
											inputRef={register}
										/>
										<Divider className={classes.divider} orientation="vertical" />
										<TextField 
											select
											className={classes.input}
											variant="outlined"
											name="type"
											value={keyword.values.type || ''}
											label="Tipe Pencarian"
											inputRef={register}
											onChange={handleChangeSearch}
										>
											<MenuItem key="name" value="name">Nama</MenuItem>
											<MenuItem key="id_agent" value="id_agent">ID Agent</MenuItem>
										</TextField>
									</Paper>
								</div>
							</form>
						</Grid>
						<Grid
							item
							lg={12}
							md={12}
							sm={12}
							xs={12}
						>
							<Typography>Hasil Pencarian</Typography>
							<div className={classes.row}>
								{/* {customers_v2 !== null && ( */}
									<Paper className={classes.root}>
										<TableContainer className={classes.container}>
											<Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
											<TableHead>
												<TableRow>
												{columns.map((column) => (
													<TableCell
													key={column.id}
													align={column.align}
													style={{ minWidth: column.minWidth }}
													>
													{column.label}
													</TableCell>
												))}
												</TableRow>
											</TableHead>
											<TableBody>
												{!loadingCustomerV2 && customers_v2 !== null ? (
													<>
														{customers_v2.data
															// .filter(e => e.is_active === "1")
															.map((customer) => (
															<TableRow key={customer.id} hover>
																<TableCell>
																	{customer.id_agent}
																</TableCell>
																<TableCell>
																	{customer.name}
																</TableCell>
																<TableCell>
																	{customer.name_status}
																</TableCell>
																<TableCell>
																	<Button
																		fullWidth
																		className={classes.btn}
																		variant="contained"
																		onClick={e => handleSelectChange(customer)}
																		size="small"
																		>
																		Pilih
																	</Button>
																</TableCell>
															</TableRow>
														))}
													</>
												):(
													<>
													<TableRow>
														<TableCell colSpan={4}>
															<Skeleton></Skeleton>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell colSpan={4}>
															<Skeleton></Skeleton>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell colSpan={4}>
															<Skeleton></Skeleton>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell colSpan={4}>
															<Skeleton></Skeleton>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell colSpan={4}>
															<Skeleton></Skeleton>
														</TableCell>
													</TableRow>
													</>
												)}
											</TableBody>
											</Table>
										</TableContainer>
										{!loadingCustomerV2 && customers_v2 !== null ? (
											<TablePagination
												component="div"
												rowsPerPageOptions={[5, 10, 15]}
												rowsPerPage={rowsPerPage}
												onChangeRowsPerPage={handleChangeRowsPerPage}
												count={customers_v2.total}
												page={page}
												onChangePage={handleChangePage}
											/>
										):(
											<Skeleton></Skeleton>
										)}
									</Paper>
								{/* )} */}
							</div>
						</Grid>
					</Grid>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				</DialogActions>
			</Dialog>
		</div>
  	);
};

SearchCustomer.propTypes = {
    getSearchCustomerAndClear: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  customer: state.customer
})

export default connect(mapStateToProps, { getSearchCustomerAndClear, getCustomerCashier })(SearchCustomer);
