import React, {Fragment, useEffect, useState} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {isEmpty} from "../../../../../../shared/utility";
import TableBody from "@material-ui/core/TableBody";
import FormProductData from "./FormProductData/FormProductData";
import NumberFormat from "react-number-format";

const columns = [
  { id: 'no', label: 'No', minWidth: '3%' },
  // { id: 'foto_produk', label: 'Foto Produk', minWidth: '16%' },
  { id: 'item_produk', label: 'Item Produk', minWidth: '16%' },
  { id: 'stock', label: 'Stock', minWidth: '16%' },
  { id: 'harga_HPP', label: 'Harga Hpp', minWidth: '16%' },
  { id: 'harga', label: 'Weight', minWidth: '16%' },
  { id: 'kuantitas', label: 'Qty', minWidth: '16%' },
  { id: 'Subtotal', label: 'Subtotal Item Gram', minWidth: '16%' },
  { id: 'SubtotalBuyBack', label: 'Subtotal Harga Hpp', minWidth: '16%' },
];

const FormProductList = props => {
  const {
    products,
    classes,
    cartTo,
    max_weight,
    setCartExchangeGoldTo
    // listExchangeGoldFrom,
    // setCartExchangeGoldFrom,
    // getListExchangeGoldFrom,
    // cartExchangeGoldFrom
  } = props;

  // console.log(productTo);
  const list = [];
  products.map((itemData, index) => (
    list[index] = {
      id_product: itemData.product.id,
      qty: 0,
      weight: itemData.product ? itemData.product.weight : 0,
      hpp_price: itemData.product.latest_price ? itemData.product.latest_price.sell_price : 0,
      buy_price: itemData.product.latest_price ? itemData.product.latest_price.buy_price : 0,
      buyback_price: itemData.product.latest_price_buyback ? itemData.product.latest_price_buyback.buyback_price : 0,
      sub_total_gram: itemData.product ? itemData.product.weight * 0 : 0,
      subtotal_price: itemData.product.latest_price ? itemData.product.latest_price.sell_price * 0 : 0
    }));

  const [ cartQty, setCartQty] = useState(list);
  setCartExchangeGoldTo(cartQty);

  useEffect(() => {
    const setCartZero = () => {
      let list = [...cartQty];
      cartQty.forEach(function(product) {
        if (product.weight <= max_weight){
          product.qty= 0;
          product.sub_total_gram= 0;
          product.subtotal_price= 0;
        };
        if (max_weight === 0){
          product.qty= 0;
          product.sub_total_gram= 0;
          product.subtotal_price= 0;
        };
      })
      setCartQty(list);
      setCartExchangeGoldTo(list);
    }
    setCartZero();
  },[max_weight]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCartQtyChange = (e,product, index) => {
    e.persist();
    let dataCount = e.target.value === '' ? 0 : parseInt(e.target.value);
    // const list = [...cartQty]
    if (dataCount === '' || dataCount < 0){
      dataCount = 0;
    }
    if (dataCount >= parseInt(product.product.stock_on_hand)){
      dataCount = product.product.stock_on_hand;
    }
    let list = [...cartQty]
    list[index] = {
      id_product : product.product.id,
      [e.target.name] : dataCount,
      weight: product.product ? product.product.weight : 0,
      hpp_price: product.product.latest_price ? product.product.latest_price.sell_price : 0,
      buy_price: product.product.latest_price ? product.product.latest_price.buy_price : 0,
      buyback_price: product.product.latest_price_buyback ? product.product.latest_price_buyback.buyback_price : 0,
      sub_total_gram: product.product ? product.product.weight * dataCount : 0,
      subtotal_price: product.product.latest_price ? product.product.latest_price.sell_price * dataCount : 0
    }
    setCartQty(list)
    setCartExchangeGoldTo(list)
  }

  let tableBody = '';

  if((cartTo)){
    tableBody = (
      <Fragment>
        <TableBody>
          {products.map((product, index) => (
            <FormProductData
              product={product}
              key={product.id}
              index={index}
              classes={classes}
              // register={register}
              handleCartQtyChange={(e) => handleCartQtyChange(e,product,index)}
              // cartExchangeGoldFrom={cartExchangeGoldFrom}
              cartQty={cartQty}
              max_weight={max_weight}
              setCartQty={setCartQty}
              setCartExchangeGoldTo={setCartExchangeGoldTo}
              // errors={errors}
            />
          ))}
          <TableRow>
            <TableCell colSpan={6}/>
            <TableCell>
              {!isEmpty(cartQty) ? <NumberFormat style={{fontWeight: "bold"}} value={cartQty.map(item => parseFloat(item.sub_total_gram)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} suffix={" gram"} decimalScale={2}/> : 0}
            </TableCell>
            <TableCell>
              {!isEmpty(cartQty) ? <NumberFormat style={{fontWeight: "bold"}} value={cartQty.map(item => parseInt(item.subtotal_price)).reduce((prev, next) => prev + next)} displayType={'text'} thousandSeparator={true} prefix={'Rp '}/> : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className={classes.row}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {tableBody}
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  );
};

export default FormProductList;