import React, {Fragment} from "react";
import Paper from "@material-ui/core/Paper";
import FormProductList from "./FormProductList/FormProductList";
import * as actions from "../../../../../actions";
import {connect} from "react-redux";
import {isEmpty} from "../../../../../shared/utility";
import {SwapHoriz} from "@material-ui/icons";

const ExchangeGoldTo = props => {
  const {
    classes,
    cartTo,
    products,
    max_weight,
    cartExchangeGoldTo,
    setCartExchangeGoldTo,
    listExchangeGoldTo,
  } = props;


  // useEffect(() => {
  //   onFetchProducts(searchCustomerClear[0].status, dateToUTC, product_type)
  // }, [onFetchProducts, searchCustomerClear, dateToUTC, product_type])

  let formProductList = ''
  if((!isEmpty(products))){
    formProductList = <FormProductList
      classes={classes}
      cartTo={cartTo}
      products={products}
      max_weight={max_weight}
      cartExchangeGoldTo={cartExchangeGoldTo}
      setCartExchangeGoldTo={setCartExchangeGoldTo}
      listExchangeGoldTo={listExchangeGoldTo}
    />
  }

  // if(!isEmpty(products)){
  //   formProductList = <FormProductList
  //     products={products}
  //     classes={classes}
  //     listExchangeGoldFrom={listExchangeGoldFrom}
  //     getListExchangeGoldFrom={(data) => getListExchangeGoldFrom(data)}
  //     setCartExchangeGoldFrom={setCartExchangeGoldFrom}
  //     cartExchangeGoldFrom={cartExchangeGoldFrom}
  //     // getCartQty={(stateData) =>sendCartQty(stateData)}
  //   />;
  // }

  return (
    <Fragment>
      <div className={classes.row}>
        <Paper className={classes.root}>
          <div className={classes.paperPadding}>
            {/*<Typography className={classes.tagMenu} variant={"h5"}>Tukar Ke</Typography>*/}
            <div className={classes.dispayFlex}>
              <div className={classes.icon}>
                <SwapHoriz/>
              </div>
              <div className={classes.tagMenu}>
                Tukar Ke
              </div>
            </div>
          </div>
          {formProductList}
        </Paper>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.product.loadingProducts,
    products: state.product.products,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchProducts: (type, date, product_type) => dispatch(actions.getProduct(type, date, product_type))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ExchangeGoldTo);