import React, {Fragment,useEffect} from 'react'
// import { Pie } from 'react-chartjs-2';
import {
    Card,
    CardContent,
    // Typography,
    Grid,
    CardHeader,
} from '@material-ui/core'

// Redux
// import { connect } from 'react-redux'
// import { getGrafikStock } from '../../../../actions/dashboard'
// import Skeleton from '@material-ui/lab/Skeleton';
import * as actions from '.././../../../actions';

import {
    StockProduct
} from './components'
import {connect} from "react-redux";
import {isEmpty} from "../../../../shared/utility";
import {Loading} from "../../../../components/UI";

const GrafikNetIncome = (props) => {
    const {
        onFetchGrafikStock,
      grafikStocks,
      loading
    } = props

    useEffect(() => {
        onFetchGrafikStock()
    },[onFetchGrafikStock]);


    return(loading ? <Loading/> :
        <Card>
            <CardHeader title="Grafik Stok" />
            <CardContent>
                <Grid
                    container
                    spacing={2}
                >
                    {!isEmpty(grafikStocks) ? (
                      grafikStocks.map(stock => (
                        <Fragment>
                            <Grid
                              item
                              lg={4}
                              md={4}
                              sm={6}
                              xs={12}
                            >
                                <StockProduct grafikStock={stock}/>
                            </Grid>
                        </Fragment>
                      ))
                    ) : (
                      ''
                    )}
                {/*    <Grid */}
                {/*        item*/}
                {/*        lg={4}*/}
                {/*        md={4}*/}
                {/*        sm={6}*/}
                {/*        xs={12}*/}
                {/*    >*/}
                {/*        <StockProduct1 />*/}
                {/*    </Grid>*/}
                {/*    <Grid */}
                {/*        item*/}
                {/*        lg={4}*/}
                {/*        md={4}*/}
                {/*        sm={6}*/}
                {/*        xs={12}*/}
                {/*    >*/}
                {/*        <StockProduct2 />*/}
                {/*    </Grid>*/}
                {/*    <Grid */}
                {/*        item*/}
                {/*        lg={4}*/}
                {/*        md={4}*/}
                {/*        sm={6}*/}
                {/*        xs={12}*/}
                {/*    >*/}
                {/*        <StockProduct3 />*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/*<Grid*/}
                {/*    container*/}
                {/*    spacing={2}*/}
                {/*>*/}
                {/*    <Grid */}
                {/*        item*/}
                {/*        lg={4}*/}
                {/*        md={4}*/}
                {/*        sm={6}*/}
                {/*        xs={12}*/}
                {/*    >*/}
                {/*        <StockProduct4 />*/}
                {/*    </Grid>*/}
                {/*    <Grid */}
                {/*        item*/}
                {/*        lg={4}*/}
                {/*        md={4}*/}
                {/*        sm={6}*/}
                {/*        xs={12}*/}
                {/*    >*/}
                {/*        <StockProduct5 />*/}
                {/*    </Grid>*/}
                {/*    <Grid */}
                {/*        item*/}
                {/*        lg={4}*/}
                {/*        md={4}*/}
                {/*        sm={6}*/}
                {/*        xs={12}*/}
                {/*    >*/}
                {/*        <StockProduct6 />*/}
                {/*    </Grid>*/}
                </Grid>
            </CardContent>
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        grafikStocks: state.dashboard.grafikStockV2,
        loading: state.dashboard.loadingGrafikStockV2
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchGrafikStock: () => dispatch(actions.fetchGrafikStocks())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GrafikNetIncome)