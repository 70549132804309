import React,{Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Avatar, TextField} from "@material-ui/core";
import NumberFormat from "react-number-format";

const BeginningStockFormData = props => {
  const {item,index,register,stock,errors,status} = props;
  // const disabled = (status === 'draft' ? 'disabled' : '');

  return (
    <Fragment>
      <TableRow key={item.product_id}>
        <TableCell>
          {index+1}
        </TableCell>
        <TableCell>
          <Avatar alt="Remy Sharp" src={item.product_image} />

        </TableCell>
        <TableCell>
          {item.product_name+' '+item.product_weight+' '+item.product_unit}
        </TableCell>
        <TableCell>
          {item.hpp ? <NumberFormat value={item.hpp} displayType={'text'} thousandSeparator={true} prefix={`Rp `} />: 0}
        </TableCell>
        <TableCell>
          {status === 'draft' ? (
            <TextField
              fullWidth
              name="qty"
              type="number"
              variant="outlined"
              size={"small"}
              inputRef={register}
              helperText={
                errors.qty && errors.qty.message
              }
              error={errors.qty && true}
              onChange={e => props.handleStockRealChange(e,item,index)}
              defaultValue={item.qty}
            />
          ):
          item.qty
          }

        </TableCell>
        <TableCell>
          {stock[index] ? <NumberFormat value={stock[index].qty * item.hpp} displayType={'text'} thousandSeparator={true} prefix={`Rp `} /> : 0}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default BeginningStockFormData;