import axios from 'axios'
import axiosOrders from '../axios-orders';
import { setAlert } from './alert'
import {
    GET_PURCHASE_ORDER,
    ADD_PURCHASE_ORDER_START,
    ADD_PURCHASE_ORDER_SUCCESS,
    GET_PURCHASE_ORDER_DETAIL,
    ADD_PURCHASE_ORDER_DETAIL,
    DELETE_PURCHASE_ORDER_DETAIL,
    UPDATE_PURCHASE_ORDER_DONE,
    GET_PURCHASE_ORDER_END,
    GET_PURCHASE_ORDER_DETAIL_START,
} from './types'
import * as actionTypes from "./types";
import fileDownload from "js-file-download";
import moment from 'moment';

export const getPurchaseOrder = (keyword, status, type, startDate, endDate) => async dispatch => {
    dispatch({
        type: GET_PURCHASE_ORDER,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order/filter?kata_kunci=${keyword}&status=${status}&type=${type}&start_date=${startDate}&end_date=${endDate}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PURCHASE_ORDER_END,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        dispatch({
            type: GET_PURCHASE_ORDER_END,
            payload: error
        })
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const addPurchaseOrder = (history) => async dispatch => {
    dispatch({
        type: ADD_PURCHASE_ORDER_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_PURCHASE_ORDER_SUCCESS,
            payload: res.data
        })

        dispatch(setAlert("New Invoice Added", "success"))
        history.push(`/purchase-order/create/${res.data.id}`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getProductPO = () => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PURCHASE_ORDER,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const getPurchaseOrderDetail = (id) => async dispatch => {
    dispatch({
        type: GET_PURCHASE_ORDER_DETAIL_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order/${id}/purchase_order_detail`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "GET",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: GET_PURCHASE_ORDER_DETAIL,
            payload: res.data
        })

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const addPurchaseOrderDetail = (formData, id, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order/${id}/purchase_order_detail`
    const token = sessionStorage.getItem('access_token')

    const myData = new FormData()
    myData.set('id_product', formData.id_product)
    myData.set('qty', formData.qty)

    try {
        const res = await axios({
            url: endpoint,
            method: "POST",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_PURCHASE_ORDER_DETAIL,
            payload: res.data
        })

        dispatch(setAlert("New Invoice Added", "success"))
        history.push(`/purchase-order/create/${id}`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const deletePurchaseOrderDetail = (id_po, id_po_detail, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order/${id_po}/purchase_order_detail/${id_po_detail}`
    const token = sessionStorage.getItem('access_token')

    try {
        const res = await axios({
            url: endpoint,
            method: "DELETE",
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: DELETE_PURCHASE_ORDER_DETAIL,
            payload: res.data
        })

        dispatch(setAlert("Item Deleted", "success"))
        history.push(`/purchase-order/create/${id_po}`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const updatePurchaseOrderStatus = (id, history) => async dispatch => {
    dispatch({
        type: ADD_PURCHASE_ORDER_START,
    })
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order/${id}`
    const token = sessionStorage.getItem('access_token')

    const myData = {
        status: '3'
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "PATCH",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: ADD_PURCHASE_ORDER_SUCCESS,
            payload: res.data
        })

        dispatch(setAlert("Invoice send", "success"))
        history.push(`/purchase-order`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const updatePurchaseOrderStatusDone = (id, history) => async dispatch => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}/user/purchase_order/${id}`
    const token = sessionStorage.getItem('access_token')

    const myData = {
        status: '1'
    }

    try {
        const res = await axios({
            url: endpoint,
            method: "PATCH",
            data: myData,
            loading: true,
            headers: { 
              'Content-Type': 'application/json', 
              'Accept' : 'application/json', 
              'Authorization' : `bearer ${token}`
            }
        });

        dispatch({
            type: UPDATE_PURCHASE_ORDER_DONE,
            payload: res.data
        })

        dispatch(setAlert("Invoice send", "success"))
        history.push(`/purchase-order`);

    } catch (error) {
        dispatch(setAlert("Something Went Wrong", "error"))
        console.log(error)
        // dispatch({
        //     payload: { msg: error.response.statusText, status: error.response.status },
        //     type: STAGE_ERROR
        // })
    }
}

export const fetchPurchaseOrdersStart = () => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDERS_START
    }
}

export const fetchPurchaseOrdersSuccess = (purchase_orders) => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDERS_SUCCESS,
        purchase_orders: purchase_orders
    }
}

export const fetchPurchaseOrdersFail = (error) => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDERS_FAIL,
        error: error
    }
}

export const fetchPurchaseOrders = (page, formSearch) => {
    let param = `&start_date=${formSearch.start_date}&end_date=${formSearch.end_date}&status=${formSearch.status}&search=${formSearch.search}&round=${formSearch.round_id}`;
    return dispatch => {
        dispatch(fetchPurchaseOrdersStart())
        axiosOrders.get(`user/purchase_order/fetch?page=${page}&page_size=10`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchPurchaseOrdersSuccess(res.data))
          })
          .catch(err => {
              dispatch(fetchPurchaseOrdersFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const fetchPurchaseOrderProductsStart = () => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDER_PRODUCTS_START
    }
}

export const fetchPurchaseOrderProductsSuccess = (products,round) => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDER_PRODUCTS_SUCCESS,
        products: products,
        round: round
    }
}

export const fetchPurchaseOrderProductsFail = (error) => {
    return {
        type: actionTypes.FETCH_PURCHASE_ORDER_PRODUCTS_FAIL,
        error: error
    }
}

export const fetchPurchaseOrderProducts = () => {
    return dispatch => {
        dispatch(fetchPurchaseOrderProductsStart())
        axiosOrders.get(`user/purchase_order/product`,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(fetchPurchaseOrderProductsSuccess(res.data.data, res.data.round))
          })
          .catch(err => {
              dispatch(fetchPurchaseOrderProductsFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const storePurchaseOrderStart = () => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_START
    }
}

export const storePurchaseOrderSuccess = () => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_SUCCESS,
    }
}

export const storePurchaseOrderFail = (error) => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_FAIL,
        error: error
    }
}

export const storePurchaseOrder = (storeData,history) => {
    return dispatch => {
        dispatch(storePurchaseOrderStart())
        axiosOrders.post(`user/purchase_order/store`, storeData,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(storePurchaseOrderSuccess())
              dispatch(setAlert(res.data.message, "success"))
              history.push('/purchase-order-v2')
          })
          .catch(err => {
              dispatch(storePurchaseOrderFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const getPurchaseOrderStart = () => {
    return {
        type: actionTypes.GET_PURCHASE_ORDER_START
    }
}

export const getPurchaseOrderSuccess = (purchase_order) => {
    return {
        type: actionTypes.GET_PURCHASE_ORDER_SUCCESS,
        purchase_order: purchase_order
    }
}

export const getPurchaseOrderFail = (error) => {
    return {
        type: actionTypes.GET_PURCHASE_ORDER_FAIL,
        error: error
    }
}

export const getPurchaseOrderV2 = (id) => {
    return dispatch => {
        dispatch(getPurchaseOrderStart())
        axiosOrders.get(`user/purchase_order/fetch/${id}`,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(getPurchaseOrderSuccess(res.data.data))
          })
          .catch(err => {
              dispatch(getPurchaseOrderFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const storePurchaseOrderFileStart = () => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_FILE_START
    }
}

export const storePurchaseOrderFileSuccess = (url, extension, name) => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_FILE_SUCCESS,
        url: url,
        extension: extension,
        caption: name
    }
}

export const storePurchaseOrderFileFail = (error) => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_FILE_FAIL,
        error: error
    }
}

export const storePurchaseOrderFile = (storeData) => {
    console.log(storeData);
    return dispatch => {
        dispatch(storePurchaseOrderFileStart())
        const bodyFormData = new FormData()
        bodyFormData.set('file', storeData)

        axiosOrders({
            method: 'post',
            url: 'user/purchase_order/upload',
            data: bodyFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(response => {
              dispatch(setAlert(response.data.message, 'success'))
              dispatch(storePurchaseOrderFileSuccess(response.data.url ,  storeData.type, storeData.name))
          })
          .catch(error => {
              dispatch(storePurchaseOrderFileFail(error.response.data.message))
              dispatch(setAlert(error.response.data.message, 'error'))
          })
    }
}

export const uploadDocumentPurchaseOrderStart = () => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT_PURCHASE_ORDER_START
    }
}

export const uploadDocumentPurchaseOrderSuccess = (url, extension, name) => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT_PURCHASE_ORDER_SUCCESS,
        url: url,
        extension: extension,
        caption: name
    }
}

export const uploadDocumentPurchaseOrderFail = (error) => {
    return {
        type: actionTypes.UPLOAD_DOCUMENT_PURCHASE_ORDER_FAIL,
        error: error
    }
}

export const uploadDocumentPurchaseOrder = (storeData) => {
    return dispatch => {
        dispatch(uploadDocumentPurchaseOrderStart())
        const bodyFormData = new FormData()
        bodyFormData.set('file', storeData)

        axiosOrders({
            method: 'post',
            url: 'user/purchase_order/upload',
            data: bodyFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(response => {
              dispatch(setAlert(response.data.message, 'success'))
              dispatch(uploadDocumentPurchaseOrderSuccess(response.data.url , storeData.type, storeData.name))
          })
          .catch(error => {
              dispatch(uploadDocumentPurchaseOrderFail(error.response.data.message))
              dispatch(setAlert(error.response.data.message, 'error'))
          })
    }
}

export const storePurchaseOrderReceiptStart = () => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_RECEIPT_START
    }
}

export const storePurchaseOrderReceiptSuccess = () => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_RECEIPT_SUCCESS,
    }
}

export const storePurchaseOrderReceiptFail = (error) => {
    return {
        type: actionTypes.STORE_PURCHASE_ORDER_RECEIPT_FAIL,
        error: error
    }
}

export const storePurchaseOrderReceipt = (id,storeData) => {
    return dispatch => {
        dispatch(storePurchaseOrderReceiptStart())
        axiosOrders.post(`user/purchase_order/store/${id}/receipt`, storeData,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
            }
        })
          .then(res => {
              dispatch(storePurchaseOrderReceiptSuccess())
              dispatch(setAlert(res.data.message, "success"))
              // history.push(`/purchase-order-v2/${id}`)
          })
          .catch(err => {
              dispatch(storePurchaseOrderReceiptFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const deleteImagePORedux = (index) => {
    return {
        type: actionTypes.DELETE_IMAGE_PO_REDUX,
        index: index
        // index: index
    }
}

export const deleteDocumentPORedux = (index) => {
    return {
        type: actionTypes.DELETE_DOCUMENT_PO_REDUX,
        index: index
        // index: index
    }
}

export const clearFilePoRedux = () => {
    return {
        type: actionTypes.CLEAR_FILE_PO_REDUX,
    }
}

export const downloadPurchaseOrdersStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_START
    }
}

export const downloadPurchaseOrdersSuccess = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_SUCCESS
    }
}

export const downloadPurchaseOrdersFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_FAIL,
        error: error
    }
}

export const downloadPurchaseOrders = (formSearch) => {
    let param = `start=${formSearch.start_date}&end=${formSearch.end_date}&status=${formSearch.status}&search=${formSearch.search}&round_id=${formSearch.round_id}`;
    return dispatch => {
        dispatch(downloadPurchaseOrdersStart())
        axiosOrders.get(`user/purchase_order/export?`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            responseType: 'blob',
        })
          .then(res => {
              fileDownload(res.data, `download-po.xlsx`)
              dispatch(downloadPurchaseOrdersSuccess(res.data))
              dispatch(setAlert('Download Finished', "success"))
          })
          .catch(err => {
              dispatch(downloadPurchaseOrdersFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const downloadPurchaseOrdersOldStart = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_START
    }
}

export const downloadPurchaseOrdersOldSuccess = () => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_SUCCESS
    }
}

export const downloadPurchaseOrdersOldFail = (error) => {
    return {
        type: actionTypes.DOWNLOAD_PURCHASE_ORDERS_OLD_FAIL,
        error: error
    }
}

export const downloadPurchaseOrdersOld = (formSearch) => {
    let param = `start=${formSearch.start_date}&end=${formSearch.end_date}&status=${formSearch.status}&search=${formSearch.search}&round_id=${formSearch.round_id}`;
    return dispatch => {
        dispatch(downloadPurchaseOrdersOldStart())
        axiosOrders.get(`user/purchase_order/export/old?`+param,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
            responseType: 'blob',
        })
          .then(res => {
              fileDownload(res.data, `download-po.xlsx`)
              dispatch(downloadPurchaseOrdersOldSuccess(res.data))
              dispatch(setAlert('Download Finished', "success"))
          })
          .catch(err => {
              dispatch(downloadPurchaseOrdersOldFail(err.response.data.message))
              dispatch(setAlert(err.response.data.message, "error"))
          })
    }
}

export const storeFilterPurchaseOrder = (name, value) => {
    let newValue = value

    if (name === 'start_date' || name === 'end_date') {
        newValue = moment(value).format('YYYY-MM-DD')
    }

    return {
        type: actionTypes.UPDATE_FILTER_PURCHASE_ORDER,
        value: newValue,
        name: name
    }
}